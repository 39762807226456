import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../../configuration";
import { CreateMeetingNoteDto, FetchError, GetMeetingNotesDto, MeetingNoteDto, UserDto } from "../../../types";
import { useAccessToken } from "../../../use-access-token";
import { queryKeys } from "../../queryKeys";

type Variables = {
  meetingNoteGuid: string;
  meetingGuid: string;
  createdByUser: UserDto;
  text: string;
};

const useCreateMeetingNoteRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createMeetingNoteRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: CreateMeetingNoteDto = {
      meetingNoteGuid: variables.meetingNoteGuid,
      text: variables.text,
    };

    const response = await fetch(`${configuration.apiRootUrl}/meetings/${variables.meetingGuid}/notes`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createMeetingNoteRequest };
};

const useCreateMeetingNote = () => {
  const { createMeetingNoteRequest } = useCreateMeetingNoteRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createMeetingNoteRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.meetings.getMeetingNotes(variables.meetingGuid));
      const cachedData = queryClient.getQueryData<GetMeetingNotesDto>(
        queryKeys.meetings.getMeetingNotes(variables.meetingGuid)
      );

      if (cachedData) {
        const newMeetingNote: MeetingNoteDto = {
          guid: variables.meetingNoteGuid,
          createdDateTimeUtc: DateTime.utc().toISO(),
          createdByUser: variables.createdByUser,
          modifiedDateTimeUtc: DateTime.utc().toISO(),
          text: variables.text,
        };

        const optimisticallyUpdatedData: GetMeetingNotesDto = {
          ...cachedData,
          meetingNotes: [newMeetingNote, ...cachedData.meetingNotes],
        };

        queryClient.setQueryData(queryKeys.meetings.getMeetingNotes(variables.meetingGuid), optimisticallyUpdatedData);
      }

      return {
        cachedData,
      };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.meetings.getMeetingNotes(variables.meetingGuid), context?.cachedData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.meetings.getMeetingNotes(variables.meetingGuid));
    },
  });
};

export { useCreateMeetingNote };
