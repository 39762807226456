import { Box, SxProps, useTheme } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  PointStyle,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ILineGraphDataset } from "../models";

ChartJS.register(LinearScale, CategoryScale, PointElement, LineElement, Title, Tooltip, Legend);

interface IProps {
  datasets: ILineGraphDataset[];
  xAxisLabels?: string[];
  sx?: SxProps;
  minYValue?: number;
  maxYValue?: number;
  stacked?: boolean;
  drawPoints?: boolean;
  showXAxis?: boolean;
  showYAxis?: boolean;
}

const LineGraph = (props: IProps) => {
  const theme = useTheme();

  let yValues = props.datasets.flatMap((d) => d.data.filter((p) => p.y !== null).map((p) => p.y!));

  const maxYValue = Math.max(...yValues);
  const minYValue = Math.min(...yValues);
  const calculatedMaxYValue = maxYValue + (maxYValue - minYValue) * 0.1;
  const calculatedMinYValue = minYValue - (maxYValue - minYValue) * 0.1;

  const tooltipLine = {
    id: "tooltipLine",
    beforeTooltipDraw: (chart: any) => {
      if (chart.tooltip?._active?.length) {
        let x = chart.tooltip._active[0].element.x;
        let ctx = chart.ctx;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, chart.chartArea.top);
        ctx.lineTo(x, chart.chartArea.bottom);
        ctx.strokeStyle = theme.palette.grey[300];
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  function getOptions(): ChartOptions<"line"> {
    return {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "nearest",
        axis: "x",
        intersect: false,
      },
      elements: {
        point: {
          borderWidth: 0,
          backgroundColor: "rgba(0,0,0,0)",
        },
      },
      plugins: {
        legend: {
          display: false,
          position: "top" as const,
        },
        tooltip: {
          displayColors: true,
          backgroundColor: theme.palette.common.white,
          titleColor: theme.palette.text.primary,
          bodyColor: theme.palette.text.primary,
          borderWidth: 1,
          borderColor: theme.palette.grey[200],
          boxWidth: 8,
          usePointStyle: true,
          cornerRadius: 8,
          padding: 20, //theme.spacing(1) would be better https://github.com/mui/material-ui/issues/29086
          titleFont: {
            size: theme.typography.fontSize,
            family: theme.typography.fontFamily,
          },
          bodyFont: {
            size: theme.typography.fontSize,
            family: theme.typography.fontFamily,
          },
          callbacks: {
            title: (chart: any) => {
              let title = chart[0].label;
              return title;
            },
            labelPointStyle: () => {
              return {
                pointStyle: "circle" as PointStyle,
                rotation: 0,
              };
            },
            label: (chart: any) => {
              let label = ` ${chart.dataset.label}`;

              if (chart.dataset.label) {
                label += ": ";
              }
              if (chart.parsed.y !== null) {
                label += chart.parsed.y.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                });
              }
              return label;
            },
          },
        },
      },
      scales: {
        xAxis: {
          display: props.showXAxis ? true : false,
        },
        yAxis: {
          display: props.showYAxis ? true : false,
          stacked: props.stacked,
          min: props.showYAxis ? props.minYValue ?? undefined : calculatedMinYValue,
          max: props.showYAxis ? props.maxYValue ?? undefined : calculatedMaxYValue,
        },
      },
    };
  }

  function getData() {
    return {
      datasets: (props.datasets || []).flatMap((d) => [
        {
          label: d.title,
          data: d.data,
          borderColor: d.color,
          tension: 0.3,
          pointRadius: props.drawPoints ? 3 : 0,
          pointBackgroundColor: d.color,
          borderDash: d.dotted ? [3, 3] : [0],
        },
      ]),
    };
  }

  return (
    <Box sx={{ ...props.sx, overflow: "hidden" }}>
      <Line options={getOptions()} plugins={[tooltipLine]} data={getData()} />
    </Box>
  );
};

export { LineGraph };
