import { Skeleton, Stack } from "@mui/material";

const OneOnOneMeetingListItemSkeleton = () => {
  return (
    <Stack spacing={1} sx={{ m: 1 }}>
      <Stack spacing={0.5}>
        <Skeleton variant="rectangular" height={24} width={70} />
        <Skeleton variant="rectangular" height={28} width={350} />
        <Skeleton variant="rectangular" height={48} width={300} />
      </Stack>
    </Stack>
  );
};

export { OneOnOneMeetingListItemSkeleton };
