import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-pro";
import { IActionItem, ISection } from "../../models";
import { ActionItemDto, useDeleteActionItem, useEditActionItem, useEditActionItemSortOrder } from "../../http";
import { Typography } from "../Typography";
import {
  AlarmOnOutlined,
  DragIndicatorRounded,
  GroupOutlined,
  LinkOutlined,
  MoreVertRounded,
  OpenInBrowserRounded,
  StickyNote2Outlined,
  Tonality,
} from "@mui/icons-material";
import { Box, Button, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { DEFAULT_ACTION_ITEM_STATUS, Links, SupportingItemType } from "../../constants";
import { StatusPicker } from "../StatusPicker";
import { AvatarGroup } from "../AvatarGroup";
import { ContextProperty } from "../contexts";
import { DateTime } from "luxon";
import { TextViewer } from "../textEditor";
import { useNavigate } from "react-router";
import { useDrawer } from "../DrawerProvider";
import { ActionItemDrawerContent } from "./ActionItemDrawerContent";

import { useEffect, useMemo, useState } from "react";
import { lexorank, useDestructiveConfirm } from "../../utilities";
import { useSession, useSettings } from "../../hooks";
import { theme } from "../../assets/theme/theme";

type ActionItemGridProps = {
  actionItems: ActionItemDto[] | IActionItem[];
  isPastView?: boolean;

  teamGuid?: string;
  planGuid?: string;
  section?: ISection;
  enableRowReordering?: boolean;
};

type ActionItemGridItem = ActionItemDto | IActionItem;

const DEFAULT_ACTION_ITEM_COLUMN_VISIBLITY = {
  description: true,
  status: true,
  assignee: true,
  context: true,
  notes: true,
  dueDate: true,
  actions: true,
};

export const ActionItemGrid = ({
  actionItems,
  isPastView = false,
  teamGuid,
  planGuid,
  section,
  enableRowReordering = true,
}: ActionItemGridProps) => {
  const gridId = `${teamGuid ? `${teamGuid}/` : ""}${planGuid ? `${planGuid}/` : ""}${
    section?.guid ? `${section.guid}/` : ""
  }`;
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const [items, setItems] = useState(
    actionItems.map((actionItem: ActionItemGridItem) => ({
      ...actionItem,
      id: actionItem.guid,
      __reorder__: actionItem.description,
    }))
  );

  const confirmDelete = useDestructiveConfirm();

  const { showDrawer } = useDrawer();
  const user = useSession();
  const navigate = useNavigate();

  const { mutate: deleteActionItem } = useDeleteActionItem();
  const { mutate: editActionItem } = useEditActionItem();
  const { mutate: editActionItemSortOrder } = useEditActionItemSortOrder();

  const [selectedActionItemGuid, setSelectedActionItemGuid] = useState<string>();

  const { gridSettings, updateGridSettings } = useSettings();
  const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>(
    DEFAULT_ACTION_ITEM_COLUMN_VISIBLITY
  );

  const handleActionItemClicked = (guid: string) => {
    showDrawer({
      title: (
        <Button
          variant="tertiary"
          endIcon={<OpenInBrowserRounded />}
          onClick={() => navigate(Links.ActionItemDetail(guid))}
        >
          Open Page
        </Button>
      ),
      content: <ActionItemDrawerContent actionItemGuid={guid} />,
    });
  };

  const handleDeleteClicked = async () => {
    try {
      await confirmDelete({
        title: "Delete item",
        content: "Are you sure you want to delete this item?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }
    if (selectedActionItemGuid) {
      deleteActionItem({
        guid: selectedActionItemGuid,
        planGuid,
        modifiedByUserId: user.userId,
      });
    }
  };

  const onRowOrderChange = (params: GridRowOrderChangeParams) => {
    const newSortOrder = lexorank.getRank(
      items.map((x) => ({
        sortOrder: x.sortOrder,
      })),
      params.oldIndex,
      params.targetIndex
    );

    editActionItemSortOrder({
      actionItemGuid: params.row.guid,
      sectionGuid: section?.guid || null,
      sortOrder: newSortOrder,
    });

    const rowsClone = [...items];
    const row = rowsClone.splice(params.oldIndex, 1)[0];
    rowsClone.splice(params.targetIndex, 0, row);

    setItems(rowsClone);
  };

  const processRowUpdate = (newRow: ActionItemGridItem) => {
    editActionItem({ guid: newRow.guid, description: newRow.description, modifiedByUserId: user.userId });

    const rowsClone = [...items];
    rowsClone.find((x) => x.guid === newRow.guid)!.description = newRow.description;

    return newRow;
  };

  const onColumnVisibilityChange = (model: GridColumnVisibilityModel) => {
    updateGridSettings(gridId, { columnVisibility: model });
  };

  const columns: GridColDef<ActionItemGridItem>[] = useMemo(
    () => [
      {
        field: "description",
        headerName: "Name",
        flex: 2,
        editable: true,
        hideable: false,
        sortable: false,
        pinnable: false,
        renderHeader: () => (
          <Stack alignItems={"center"} justifyItems={"center"} direction="row" sx={{ gap: 0.2 }}>
            <Typography variant="caption">NAME</Typography>
          </Stack>
        ),
        renderCell: (params) => (
          <Box sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {params.value ? params.value : <span style={{ color: "#aaa" }}>Enter item...</span>}
            </div>
            <Button
              variant="tertiary"
              endIcon={<OpenInBrowserRounded />}
              className="actionButton"
              onClick={() => handleActionItemClicked(params.row.guid)}
              sx={{
                position: "absolute",
                right: 0,
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "#e2edee" },
              }}
            >
              Open
            </Button>
          </Box>
        ),
      },
      {
        field: "currentStatus",
        headerName: "Status",
        flex: 1,
        editable: true,
        sortable: false,
        pinnable: false,
        renderHeader: () => (
          <Stack alignItems={"center"} direction="row" sx={{ gap: 0.2 }}>
            <Tonality color={"disabled"} />
            <Typography variant="caption">STATUS</Typography>
          </Stack>
        ),
        renderCell: (params: GridRenderCellParams<ActionItemGridItem>) => (
          <StatusPicker
            actionItemGuid={params.row.guid}
            status={params.row.currentStatus ?? DEFAULT_ACTION_ITEM_STATUS}
            teamSlug={""}
            variant="select"
            disabled={!params.isEditable}
          />
        ),
      },
      {
        field: "owners",
        headerName: "Ownership",
        flex: 1,
        sortable: false,
        pinnable: false,
        renderHeader: (params: GridColumnHeaderParams<ActionItemGridItem>) => (
          <Stack alignItems={"center"} direction="row" sx={{ gap: 0.2 }}>
            <GroupOutlined color={"disabled"} />
            <Typography variant="caption">OWNERSHIP</Typography>
          </Stack>
        ),
        renderCell: (params: GridRenderCellParams<ActionItemGridItem>) => (
          <AvatarGroup
            max={5}
            sx={{
              "& .MuiAvatar-root": { width: 20, height: 20, fontSize: 12 },
            }}
            users={params.row.owners}
          />
        ),
      },
      {
        field: "context",
        headerName: "Context",
        flex: 1,
        sortable: false,
        pinnable: false,
        renderHeader: () => (
          <Stack alignItems={"center"} direction="row" sx={{ gap: 0.2 }}>
            <LinkOutlined color={"disabled"} />
            <Typography variant="caption">CONTEXT</Typography>
          </Stack>
        ),
        renderCell: (params: GridRenderCellParams<ActionItemGridItem>) => {
          if (!params.row.context) return "";

          return (
            <ContextProperty
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              itemGuid={params.row.guid}
              itemType={SupportingItemType.ActionItem}
              context={params.row.context}
              isPastView={isPastView}
              condensed
            />
          );
        },
      },
      {
        field: "notes",
        headerName: "Notes",
        flex: 1,
        sortable: false,
        pinnable: false,
        renderHeader: () => (
          <Stack alignItems={"center"} direction="row" sx={{ gap: 0.2 }}>
            <StickyNote2Outlined color={"disabled"} />
            <Typography variant="caption">NOTES</Typography>
          </Stack>
        ),
        renderCell: (params) => <TextViewer value={params.row.note} />,
      },
      {
        field: "dueDate",
        headerName: "Due Date",
        flex: 1,
        sortable: false,
        pinnable: false,
        renderHeader: () => (
          <Stack alignItems={"center"} direction="row" sx={{ gap: 0.2 }}>
            <AlarmOnOutlined color={"disabled"} />
            <Typography variant="caption">DUE DATE</Typography>
          </Stack>
        ),
        valueFormatter: ({ value }) =>
          value ? DateTime.fromISO(value, { zone: "utc" }).toLocal().toLocaleString(DateTime.DATE_MED) : "",
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 50,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderHeader: () => <></>,
        renderCell: (params: GridRenderCellParams<ActionItemGridItem>) => (
          <Box alignItems="center" justifyContent="center">
            <IconButton
              className="editMenuIcon"
              size="small"
              onClick={(e) => {
                console.log(e);
                setMenuAnchor(e.currentTarget);
                setSelectedActionItemGuid(params.row.guid);
                e.stopPropagation();
              }}
            >
              <MoreVertRounded />
            </IconButton>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setItems(actionItems.map((ai) => ({ ...ai, id: ai.guid, __reorder__: ai.description })));
  }, [actionItems]);

  useEffect(() => {
    if (gridSettings?.[gridId]) {
      setColumnVisibility(gridSettings[gridId].columnVisibility);
    }
  }, [gridId, gridSettings]);

  return (
    <>
      {items.length > 0 && (
        <>
          <DataGridPro<ActionItemGridItem>
            columns={columns}
            rows={items}
            sx={{
              px: 1,
              border: 0,
              fontWeight: "normal",

              ".MuiDataGrid-row": {
                ".actionButton": {
                  opacity: 0,
                  marginLeft: "10px",
                },
                "&:hover, &.Mui-hovered": {
                  ".actionButton": {
                    opacity: "unset",
                  },
                },
              },
            }}
            columnHeaderHeight={35}
            hideFooter
            hideFooterRowCount
            hideFooterPagination
            hideFooterSelectedRowCount
            rowReordering={enableRowReordering}
            onRowOrderChange={onRowOrderChange}
            processRowUpdate={processRowUpdate}
            columnVisibilityModel={columnVisibility}
            disableRowSelectionOnClick={true}
            initialState={{
              columns: {
                columnVisibilityModel: DEFAULT_ACTION_ITEM_COLUMN_VISIBLITY,
              },
            }}
            slots={{
              rowReorderIcon: DragIndicatorRounded,
            }}
            isCellEditable={(params: GridCellParams<ActionItemGridItem>) =>
              !isPastView && (params?.row.userCanEdit ?? false)
            }
            onColumnVisibilityModelChange={onColumnVisibilityChange}
          />
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={(e: React.PointerEvent) => {
              setMenuAnchor(null);
              e.stopPropagation();
            }}
          >
            <MenuItem onClick={handleDeleteClicked} sx={{ color: theme.palette.error.main }}>
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};
