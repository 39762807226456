import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { ResizeableDrawer } from ".";

interface IProps {
  headerRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
}

interface IDrawerOptions {
  title: string | React.ReactNode;
  content: React.ReactNode;
}

interface IDrawerContext {
  showDrawer: (options: IDrawerOptions) => void;
  closeDrawer: () => void;
  isOpen: boolean;
}

const DrawerContext = React.createContext<IDrawerContext | undefined>(undefined);

const DrawerProvider = (props: IProps) => {
  const [title, setTitle] = useState<string | ReactNode | undefined>(undefined);
  const [content, setContent] = useState<ReactNode | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    setContent(undefined);
  }, [location]);

  function showDrawer(options: IDrawerOptions) {
    setTitle(options.title);
    setContent(options.content);
  }

  function closeDrawer() {
    setContent(undefined);
  }

  return (
    <DrawerContext.Provider
      value={{
        showDrawer,
        closeDrawer,
        isOpen: content !== undefined,
      }}
    >
      <ResizeableDrawer
        open={content !== undefined}
        offset={props.headerRef.current?.offsetHeight}
        title={title}
        onClose={closeDrawer}
      >
        {content}
      </ResizeableDrawer>
      {props.children}
    </DrawerContext.Provider>
  );
};

const useDrawer = () => {
  const context = useContext(DrawerContext);

  if (context === undefined) {
    throw new Error("DrawerContext is not initialized.");
  }

  return context;
};

export { DrawerProvider, useDrawer };
