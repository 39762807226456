import { LogoutRounded, VideoCallOutlined } from "@mui/icons-material";
import { Button, Divider, Link, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { Avatar, AvatarGroup, MeetingTimer } from "..";
import { Links } from "../../constants";
import { MeetingInstanceDto, UserDto } from "../../http";

interface IProps {
  title: string;
  meetingInstance: MeetingInstanceDto;
  externalCallUrl?: string;
  participants?: UserDto[];
  isMeetingEnding: boolean;
  onLeaveMeetingClicked?: () => void;
  onCloseMeetingClicked: () => void;
}

const MeetingHeader = (props: IProps) => {
  const meetingStartedAtDateTime = DateTime.fromISO(props.meetingInstance.startedAtDateTimeUtc, { zone: "utc" })
    .toLocal()
    .toLocaleString(DateTime.DATETIME_FULL);

  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
      <Stack spacing={0.5}>
        <Typography variant="h3">{props.title}</Typography>
        <Stack direction="row" spacing={0.25}>
          <Typography>Started by</Typography>
          <Link href={Links.User(props.meetingInstance.startedByUser.userId)}>
            {props.meetingInstance.startedByUser.displayName}
          </Link>
          <Stack
            direction="row"
            spacing={0.25}
            divider={<Divider flexItem orientation="vertical" sx={{ height: 16, alignSelf: "center" }} />}
          >
            <Typography>on {meetingStartedAtDateTime}</Typography>
            <Typography>
              Running Time{" "}
              <MeetingTimer
                startDateTimeUtc={DateTime.fromISO(props.meetingInstance.startedAtDateTimeUtc, { zone: "utc" })}
              />
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }} alignItems="center">
          <AvatarGroup
            users={props.participants ?? []}
            avatarComponent={(user) => <Avatar user={user} tooltip />}
            max={2}
          />
          <Button
            variant="tertiary"
            startIcon={<LogoutRounded />}
            onClick={props.onCloseMeetingClicked}
            disabled={props.isMeetingEnding}
          >
            Close Meeting For All
          </Button>

          {props.onLeaveMeetingClicked && (
            <Button
              variant="tertiary"
              startIcon={<LogoutRounded />}
              onClick={props.onLeaveMeetingClicked}
              disabled={props.isMeetingEnding}
            >
              Leave Meeting
            </Button>
          )}

          {props.externalCallUrl && (
            <Button
              variant="tertiary"
              startIcon={<VideoCallOutlined />}
              href={props.externalCallUrl}
              disabled={props.isMeetingEnding}
              target="_blank"
              rel="noopener noreferrer"
            >
              Join Call
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export { MeetingHeader };
