import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSession } from "../../hooks";
import { useEditSection } from "../../http";
import { ISection } from "../../models";
import { CreateEditSectionForm, ICreateEditSectionFormState } from "./CreateEditSectionForm";
import { usePlanContext } from "./PlanContext";

interface IProps {
  visible: boolean;
  section: ISection;
  blockGuid: string;
  onSaved: () => void;
  onCancelled: () => void;
}

const EditSectionModal = (props: IProps) => {
  const { userId } = useSession();
  const { planGuid } = usePlanContext();
  const { mutate: editSection, isLoading } = useEditSection();
  const { formState, control, handleSubmit, reset } = useForm<ICreateEditSectionFormState>({
    defaultValues: {
      name: props.section.name,
      hintText: props.section.hintText,
    },
  });

  useEffect(() => {
    reset({
      name: props.section.name,
      hintText: props.section.hintText,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.section]);

  async function handleSaveClicked() {
    handleSubmit((data) => {
      editSection(
        {
          planGuid: planGuid,
          guid: props.section.guid,
          blockGuid: props.blockGuid,
          name: data.name,
          hintText: data.hintText,
          sortOrder: props.section.sortOrder,
          modifiedByUserId: userId,
        },
        {
          onSuccess: () => {
            props.onSaved();
          },
        }
      );
    })();
  }

  function handleClose(_event: object, reason: "backdropClick" | "escapeKeyDown") {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleCancelClicked();
  }

  function handleCancelClicked() {
    props.onCancelled();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <DialogTitle>Edit Section</DialogTitle>
      <DialogContent>
        <CreateEditSectionForm formState={formState} control={control} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isLoading} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleSaveClicked}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditSectionModal };
