import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { SurveyResponseAnswerDto } from "../http";
import { ISurveyResponseAnswer } from "../models";

interface IProps {
  surveyResponseAnswer: SurveyResponseAnswerDto | ISurveyResponseAnswer;
  compact?: boolean;
}

const SurveyResponseAnswerView = (props: IProps) => {
  const theme = useTheme();
  return (
    <Stack spacing={props.compact ? 0 : 0.5}>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.25}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ color: theme.palette.grey[300], height: "15px", alignSelf: "center" }}
            />
          }
        >
          <Typography variant="caption" sx={props.compact ? { lineHeight: "1rem" } : null}>
            {props.surveyResponseAnswer.teamName}
          </Typography>
          <Typography variant="caption" sx={props.compact ? { lineHeight: "1rem" } : null}>
            {DateTime.fromISO(props.surveyResponseAnswer.submittedOnDateTimeUtc, { zone: "utc" })
              .toLocal()
              .toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Stack>
        <Typography
          variant={props.compact ? "caption" : "body2"}
          sx={props.compact ? { lineHeight: "1rem", fontWeight: "bold" } : null}
        >
          {props.surveyResponseAnswer.questionText}
        </Typography>
      </Box>
      <Typography variant={props.compact ? "caption" : "body1"} sx={props.compact ? { lineHeight: "1rem" } : null}>
        {props.surveyResponseAnswer.textAnswer}
      </Typography>
    </Stack>
  );
};

export { SurveyResponseAnswerView };
