import { ManageAccountsOutlined } from "@mui/icons-material";
import { Chip, Link, Skeleton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { Links } from "../../../constants";
import { GetWikiPageDto } from "../../../http";

interface IProps {
  page: GetWikiPageDto | undefined;
  isLoading: boolean;
}

const WikiPageTitle = (props: IProps) => {
  const theme = useTheme();

  if (props.isLoading) {
    return (
      <Stack spacing={0.25}>
        <Skeleton variant="rectangular" height="40px" width="200px" />
        <Skeleton variant="rectangular" height="25px" width="500px" />
      </Stack>
    );
  }

  if (props.page) {
    const modifiedDateTime = DateTime.fromISO(props.page.modifiedDateTimeUtc, { zone: "utc" })
      .toLocal()
      .toLocaleString(DateTime.DATE_FULL);

    return (
      <Stack spacing={0.25}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 0, md: 1 }}
          sx={{ alignItems: { xs: "unset", md: "end" } }}
        >
          <Typography variant="h3" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {props.page.title}
          </Typography>
          <Tooltip
            title={
              <Stack>
                <Typography variant="caption" sx={{ color: theme.palette.text.primary, fontWeight: 700 }}>
                  Editors
                </Typography>
                {props.page.editors
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .map((u, i) => (
                    <Link key={i} href={Links.User(u.userId)}>
                      <Typography variant="caption" sx={{ color: theme.palette.primary.main }}>
                        {u.displayName}
                      </Typography>
                    </Link>
                  ))}
              </Stack>
            }
            placement="bottom-start"
            disableTouchListener={props.page.editors.length === 0}
            disableHoverListener={props.page.editors.length === 0}
          >
            <Chip icon={<ManageAccountsOutlined />} label="Editors" sx={{ mb: "5px !important" }} />
          </Tooltip>
        </Stack>
        <Typography>
          Last updated by {props.page.modifiedByUserDisplayName} on {modifiedDateTime}
        </Typography>
      </Stack>
    );
  }

  return null;
};

export { WikiPageTitle };
