import { useTeams } from "../../../../hooks/use-teams";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../../http";
import { useError } from "../../../../hooks/use-error";
import { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { BookmarkBorderRounded, BookmarkRounded } from "@mui/icons-material";
import { useGetTeam } from "../../../../http/hooks/teams";
import { useBreakpoints } from "../../../../hooks";

interface TeamFollowersButtonProps {
  userId: string;
  teamSlug: string;
  buttonVariant: "tertiary" | "outlined";
}

export const TeamFollowersButton = (props: TeamFollowersButtonProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const { followTeam, unfollowTeam } = useTeams();
  const queryClient = useQueryClient();
  const { setError } = useError();
  const { data: team } = useGetTeam(props.teamSlug, props.userId);
  const { isMdUp } = useBreakpoints();

  async function handleUnfollow() {
    if (team === undefined || props.userId === undefined) {
      return;
    }

    setIsSaving(true);

    (await unfollowTeam(team.slug, props.userId)).match(
      async () => {
        await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeam(team?.slug, props.userId) });
        setIsSaving(false);
      },
      (error) => {
        setIsSaving(false);
        setError(error);
      }
    );
  }
  async function handleFollow() {
    if (team === undefined || props.userId === undefined) {
      return;
    }

    setIsSaving(true);

    (await followTeam(team.slug, props.userId)).match(
      async () => {
        await queryClient.refetchQueries({ queryKey: queryKeys.teams.getTeam(team?.slug, props.userId) });
        setIsSaving(false);
      },
      (error) => {
        setIsSaving(false);
        setError(error);
      }
    );
  }

  return isMdUp ? (
    <Button
      variant={props.buttonVariant}
      disabled={isSaving}
      onClick={team?.isFollowing ? handleUnfollow : handleFollow}
      startIcon={team?.isFollowing ? <BookmarkRounded /> : <BookmarkBorderRounded />}
    >
      {team?.isFollowing ? "Following" : "Follow Team"}
    </Button>
  ) : (
    <IconButton color="primary" onClick={team?.isFollowing ? handleUnfollow : handleFollow}>
      {team?.isFollowing ? <BookmarkRounded /> : <BookmarkBorderRounded />}
    </IconButton>
  );
};
