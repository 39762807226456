import { useContext } from "react";
import { SignalRContext } from "../SignalRContext";
import { SignalRClientMethod, SignalRServerMethod } from "../constants";
import { SignalRMeetingsContext } from "./SignalRMeetingsContext";

interface IProps {
  children?: React.ReactNode;
}

const SignalRMeetingsProvider = (props: IProps) => {
  const context = useContext(SignalRContext);

  return (
    <SignalRMeetingsContext.Provider
      value={{
        sendRefetchMeetingListItem: (...args) => context.invoke(SignalRServerMethod.SendRefetchMeetingListItem, args),
        sendRefetchMeetingNotes: (...args) => context.invoke(SignalRServerMethod.SendRefetchMeetingNotes, args),
        sendRefetchMeetingParticipants: (...args) =>
          context.invoke(SignalRServerMethod.SendRefetchMeetingParticipants, args),
        sendEndMeeting: (...args) => context.invoke(SignalRServerMethod.SendEndMeeting, args),
        onShouldRefetchMeetingListItem: (handler) =>
          context.registerHandler(SignalRClientMethod.ShouldRefetchMeetingListItem, handler),
        onShouldRefetchMeetingNotes: (handler) =>
          context.registerHandler(SignalRClientMethod.ShouldRefetchMeetingNotes, handler),
        onShouldRefetchMeetingParticipants: (handler) =>
          context.registerHandler(SignalRClientMethod.ShouldRefetchMeetingParticipants, handler),
        onShouldEndMeeting: (handler) => context.registerHandler(SignalRClientMethod.ShouldEndMeeting, handler),
        offShouldRefetchMeetingListItem: (handler) =>
          context.unregisterHandler(SignalRClientMethod.ShouldRefetchMeetingListItem, handler),
        offShouldRefetchMeetingNotes: (handler) =>
          context.unregisterHandler(SignalRClientMethod.ShouldRefetchMeetingNotes, handler),
        offShouldRefetchMeetingParticipants: (handler) =>
          context.unregisterHandler(SignalRClientMethod.ShouldRefetchMeetingParticipants, handler),
        offShouldEndMeeting: (handler) => context.registerHandler(SignalRClientMethod.ShouldEndMeeting, handler),
      }}
    >
      {props.children}
    </SignalRMeetingsContext.Provider>
  );
};

export { SignalRMeetingsProvider };
