import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { v4 as newGuid } from "uuid";
import { useSession } from "../../hooks";
import { useCreateBlock } from "../../http";
import { IBlock } from "../../models";
import { lexorank } from "../../utilities";
import { CreateEditBlockForm, ICreateEditBlockFormState } from "./CreateEditBlockForm";
import { usePlanContext } from "./PlanContext";

interface IProps {
  visible: boolean;
  blocks: IBlock[];
  onAdded: () => void;
  onCancelled: () => void;
}

const CreateBlockModal = (props: IProps) => {
  const { userId } = useSession();
  const { planGuid } = usePlanContext();
  const { mutate, isLoading: isCreating } = useCreateBlock();
  const { formState, control, handleSubmit, reset } = useForm<ICreateEditBlockFormState>({
    defaultValues: {
      name: "",
      hintText: "",
    },
  });

  async function handleCreateClicked() {
    handleSubmit((data) => {
      mutate(
        {
          planGuid: planGuid,
          guid: newGuid(),
          name: data.name,
          hintText: data.hintText,
          sortOrder: lexorank.getNextRank(props.blocks),
          createdByUserId: userId!,
        },
        {
          onSuccess: () => {
            reset(undefined, { keepDefaultValues: true });
            props.onAdded();
          },
        }
      );
    })();
  }

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleCancelClicked();
  }

  function handleCancelClicked() {
    reset(undefined, { keepDefaultValues: true });
    props.onCancelled();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <DialogTitle>Create Block</DialogTitle>
      <DialogContent>
        <CreateEditBlockForm formState={formState} control={control} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isCreating} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isCreating}
          endIcon={isCreating ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleCreateClicked}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CreateBlockModal };
