import { useQuery } from "@tanstack/react-query";
import { configuration } from "../../../../configuration";
import { FetchError } from "../../../types";
import { GetMeetingNotesDto } from "../../../types/meetings/notes";
import { useAccessToken } from "../../../use-access-token";
import { queryKeys } from "../../queryKeys";

const useGetMeetingNotesRequest = (meetingGuid: string) => {
  const { getAccessToken } = useAccessToken();

  async function getMeetingNotesRequest(): Promise<GetMeetingNotesDto> {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/meetings/${meetingGuid}/notes`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getMeetingNotesRequest };
};

const useGetMeetingNotes = (meetingGuid: string) => {
  const { getMeetingNotesRequest } = useGetMeetingNotesRequest(meetingGuid);

  return useQuery({
    queryKey: queryKeys.meetings.getMeetingNotes(meetingGuid),
    queryFn: getMeetingNotesRequest,
  });
};

export { useGetMeetingNotes };
