import { Box, Link, Skeleton, Stack } from "@mui/material";
import { Avatar, Typography } from "../../../../components";
import { useBreakpoints } from "../../../../hooks";
import { useUserPageLayoutContext } from "../UserPageLayout";

const UserTitle = () => {
  const { isLoadingUser, user } = useUserPageLayoutContext();
  const { isMdUp } = useBreakpoints();

  if (isLoadingUser) {
    return (
      <Stack spacing={0.25}>
        <Skeleton variant="rectangular" height="25px" width="60px" />
        <Skeleton variant="rectangular" height="40px" width="300px" />
      </Stack>
    );
  }

  if (user) {
    return (
      <Stack direction="row" spacing={{ xs: 0.5, md: 1 }} sx={{ alignItems: { xs: "center", md: "flex-start" } }}>
        <Avatar user={user} border size={isMdUp ? "large" : "small"} />
        <Stack>
          <Typography variant="h3">{user.displayName}</Typography>
          <Box sx={{ mb: 0.5 }}>
            {user.enabled ? (
              user.email !== undefined && (
                <Link href={`mailto:${user.email?.toLowerCase()}`} sx={{ textTransform: "lowercase" }}>
                  {user.email}
                </Link>
              )
            ) : (
              <Typography>Deactivated</Typography>
            )}
          </Box>
        </Stack>
      </Stack>
    );
  }

  return null;
};

export { UserTitle };
