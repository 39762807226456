import { Box, Chip, Link, Stack, SxProps } from "@mui/material";
import { Typography } from "../../../components";
import { ConnectObjectType, Links } from "../../../constants";
import { IUserRole } from "../../../models";

interface IProps {
  role: IUserRole;
  sx?: SxProps;
}

const UserPreviewRolesListItem = (props: IProps) => {
  return (
    <Stack spacing={0.25} sx={{ py: 0.25 }}>
      {props.role.isPrimaryRole && <Chip label={ConnectObjectType.PrimaryRole} color="orange" />}
      <Box sx={{ display: "inline" }}>
        <Link href={Links.RoleDetail(props.role.guid)}>{props.role.name}</Link>
        <Typography component="span"> at </Typography>
        <Link href={Links.Team(props.role.parentTeam.slug)}>{props.role.parentTeam.name}</Link>
      </Box>
    </Stack>
  );
};

export { UserPreviewRolesListItem };
