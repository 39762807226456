import { TableRowsOutlined } from "@mui/icons-material";
import { Card, CardContent, Stack, ToggleButtonGroup } from "@mui/material";
import { useContext, useEffect } from "react";
import { SettingsContext, TooltipToggleButton } from "..";
import { useBreakpoints, useSettings } from "../../hooks";

interface IProps {
  planGuid: string;
  content: React.ReactNode;
  isRowView: boolean;
  isPastView: boolean;
  onViewToggled: (value: boolean) => void;
}

const PlanHeader = (props: IProps) => {
  const { isMdUp } = useBreakpoints();
  const { viewPlanSettings, updateViewPlanSettings } = useContext(SettingsContext);

  useEffect(() => {
    loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.planGuid]);

  function loadSettings() {
    const settings = viewPlanSettings[props.planGuid];

    if (settings) {
      // handleComparisonSelected(settings.comparisonTeamSlug);
      handleViewToggled(settings.rowView);
    }
  }

  function handleViewToggled(value?: boolean) {
    if (value != null) {
      props.onViewToggled(value);

      updateViewPlanSettings(props.planGuid, {
        rowView: value,
      });
    }
  }

  const { featureFlags } = useSettings();

  const isGridFeatureEnabled = featureFlags.grids;

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={1} sx={{ justifyContent: "space-between", alignItems: "center" }}>
          {props.content}
          {isMdUp && (
            <Stack direction="row" spacing={1} alignItems="center">
              <ToggleButtonGroup
                value={props.isRowView}
                color="primary"
                size="small"
                exclusive
                onChange={(_, value: boolean) => handleViewToggled(value)}
              >
                <TooltipToggleButton title="Column view" value={false}>
                  <TableRowsOutlined fontSize="large" sx={{ transform: "rotate(90deg)" }} />
                </TooltipToggleButton>

                <TooltipToggleButton title={`${isGridFeatureEnabled ? "Grid" : "Row"} view`} value={true}>
                  <TableRowsOutlined fontSize="large" />
                </TooltipToggleButton>
              </ToggleButtonGroup>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export { PlanHeader };
