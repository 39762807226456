import { DragIndicatorRounded } from "@mui/icons-material";
import { alpha, Box, Divider, lighten, Stack, styled, SxProps } from "@mui/material";
import React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { AccordionViewList } from "./AccordionViewList";

const DroppableZone = styled(Stack)<{ $isDraggingOver: boolean; $color?: string }>`
  transition: ${({ theme }) => theme.transitions.create("background-color")};
  background-color: ${({ $isDraggingOver, $color, theme }) =>
    $isDraggingOver ? ($color ? alpha($color, 0.1) : theme.palette.primary.opacity5) : "transparent"};
  pointer-events: ${({ $isDraggingOver }) => ($isDraggingOver ? "none" : "auto")};
`;

const DragHandleContainer = styled(Stack)<{
  $isDraggable: boolean;
  $isDragging: boolean;
  $color?: string;
}>`
  padding-left: ${({ $isDraggable, theme }) => ($isDraggable ? "0px" : theme.spacing(1))};
  transition: ${({ theme }) => theme.transitions.create("background-color")};
  background-color: ${({ $isDragging, $color, theme }) =>
    $isDragging ? ($color ? lighten($color, 0.75) : theme.palette.primary.opacity10) : undefined};

  .dragHandle {
    opacity: 1;
    @media (pointer: fine) {
      opacity: ${({ $isDragging }) => ($isDragging ? 1 : 0)};
    }
  }

  &:hover {
    background-color: ${({ $color, theme }) => ($color ? alpha($color, 0.1) : theme.palette.primary.opacity5)};

    .dragHandle {
      opacity: 1;
    }
  }
`;

DragHandleContainer.defaultProps = {
  direction: "row",
};

const DragHandle = styled(Box)<{ $isDraggable: boolean; $isDisabled: boolean; $color?: string }>`
  color: ${({ theme, $isDisabled }) => ($isDisabled ? "transparent" : theme.palette.grey[200])};
  background-color: "transparent";

  @media (pointer: fine) {
    color: ${({ theme, $isDisabled, $color }) =>
      $isDisabled ? "transparent" : $color ? $color : theme.palette.primary.main};
    background-color: ${({ theme, $isDisabled, $color }) =>
      $isDisabled ? "transparent" : $color ? alpha($color, 0.1) : theme.palette.primary.transparent5};
  }

  display: ${({ $isDraggable }) => ($isDraggable ? "flex" : "none")};
  align-self: stretch;
  align-items: center;
  justify-content: center;
  transition: ${({ theme }) => theme.transitions.create(["opacity"])};
  cursor: ${({ $isDisabled }) => ($isDisabled ? "default" : "pointer")};
  min-width: 20px;
`;

DragHandle.defaultProps = {
  className: "dragHandle",
};

interface IActiveItemsListProps<T> {
  activeItemsSortFunction: (a: T, b: T) => number;
}

interface IClosedItemsListProps<T> {
  closedItemsFilter: (item: T) => boolean;
  closedItemsSortFunction: (a: T, b: T) => number;
}

interface IProps<T> {
  droppableId: string;
  title: string;
  icon?: React.ReactNode;
  dataSource?: T[];
  isLoading: boolean;
  isExpanded: boolean;
  isDragDisabled?: boolean;
  color?: string;
  divider?: React.ReactNode;
  renderItem: (item: T, index: number, isClosed: boolean) => React.ReactNode;
  activeItemsListProps: IActiveItemsListProps<T>;
  closedItemsListProps: IClosedItemsListProps<T>;
  onAddClicked: (e: React.MouseEvent) => void;
  onExpandClicked: () => void;
  onItemReordered: (itemGuid: string, sourceIndex: number, destinationIndex: number) => void;
  sx?: SxProps;
}

interface IDraggable {
  guid: string;
}

const DraggableAccordionViewList = <T extends IDraggable>(props: IProps<T>) => {
  const { droppableId, renderItem, activeItemsListProps, ...otherProps } = props;
  const isDragDisabled = props.isDragDisabled ?? true;

  function handleDragEnd(result: DropResult) {
    const { draggableId: itemGuid, destination, source } = result;

    // User dragged the item outside the container
    if (!destination) {
      return;
    }

    // User dragged the item back to where it was
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    props.onItemReordered(itemGuid, source.index, destination.index);
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable key={props.droppableId} droppableId={props.droppableId}>
        {(provided, snapshot) => (
          <DroppableZone
            ref={provided.innerRef}
            $isDraggingOver={snapshot.isDraggingOver}
            $color={props.color}
            {...provided.droppableProps}
          >
            <AccordionViewList
              renderItem={(item, index, isClosed) => {
                if (isClosed) {
                  return (
                    <Box key={item.guid} sx={{ pl: 1 }}>
                      {props.renderItem(item, index, isClosed)}
                    </Box>
                  );
                } else {
                  return (
                    <Draggable key={item.guid} draggableId={item.guid} index={index} isDragDisabled={isDragDisabled}>
                      {(provided, snapshot) => (
                        <Box ref={provided.innerRef} {...provided.draggableProps}>
                          <DragHandleContainer
                            $isDraggable={!isDragDisabled}
                            $isDragging={snapshot.isDragging}
                            $color={props.color}
                          >
                            <DragHandle
                              $isDraggable={!isDragDisabled}
                              $isDisabled={false}
                              $color={props.color}
                              {...provided.dragHandleProps}
                            >
                              <DragIndicatorRounded />
                            </DragHandle>
                            {props.renderItem(item, index, isClosed)}
                          </DragHandleContainer>
                          <Divider variant="dashed" sx={{ mx: { xs: 0.5, sm: 1 } }} />
                        </Box>
                      )}
                    </Draggable>
                  );
                }
              }}
              backgroundColorOnHover={props.color && alpha(props.color, 0.1)}
              activeItemsListProps={{
                draggablePlaceholder: provided.placeholder,
                divider: null,
                ...activeItemsListProps,
              }}
              {...otherProps}
            />
          </DroppableZone>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DraggableAccordionViewList };
