import { AlarmRounded } from "@mui/icons-material";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { GetActionItemDto, useEditActionItemDueDate } from "../../http";
import { ItemProperty } from "../ItemProperty";

interface IProps {
  actionItem: GetActionItemDto;
}

const ActionItemDueDateProperty = (props: IProps) => {
  const [selectedDueDate, setSelectedDueDate] = useState<DateTime | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const { mutate: editDueDate, isLoading } = useEditActionItemDueDate();
  const theme = useTheme();

  useEffect(() => {
    setSelectedDueDate(getDateTimeFromISO(props.actionItem.dueDate));
  }, [props.actionItem.dueDate]);

  function handleCancelClicked() {
    setIsEditing(false);
    setSelectedDueDate(getDateTimeFromISO(props.actionItem.dueDate));
  }

  function getDateTimeFromISO(dateTime: string | null) {
    return dateTime != null ? DateTime.fromISO(dateTime) : null;
  }

  function handleSaveClicked() {
    setIsEditing(false);

    editDueDate({
      guid: props.actionItem.guid,
      dueDate:
        selectedDueDate != null
          ? DateTime.fromObject({
              year: selectedDueDate.year,
              month: selectedDueDate.month,
              day: selectedDueDate.day,
              hour: 23,
              minute: 59,
              second: 59,
            }).toISO({
              includeOffset: false,
            })
          : null,
    });
  }

  return (
    <>
      <ItemProperty
        labelIcon={<AlarmRounded />}
        label="Due"
        labelProps={{
          sx: {
            alignSelf: "start",
            top: 1,
          },
        }}
        canEdit={props.actionItem.userCanEdit}
        isEditing={isEditing}
        onEditClicked={() => setIsEditing(true)}
      >
        {isEditing ? (
          <Stack spacing={0.5}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DesktopDatePicker
                format="yyyy-MM-dd"
                value={selectedDueDate}
                onChange={(value) => setSelectedDueDate(value)}
                slotProps={{
                  field: {
                    clearable: true,
                  },
                  textField: {
                    fullWidth: true,
                  },
                  openPickerButton: { size: "small" },
                  clearButton: { size: "small", sx: { mr: 0.5 } },
                }}
              />
            </LocalizationProvider>
            <Stack direction="row" spacing={0.5} sx={{ justifyContent: "flex-end" }}>
              <Button variant="text" disabled={isLoading} onClick={handleCancelClicked}>
                Cancel
              </Button>
              <Button variant="tertiary" disabled={isLoading} onClick={handleSaveClicked}>
                Save
              </Button>
            </Stack>
          </Stack>
        ) : selectedDueDate != null ? (
          <Typography sx={{ color: selectedDueDate < DateTime.now() ? theme.palette.error.main : undefined }}>
            {selectedDueDate.toLocaleString(DateTime.DATE_MED)}
          </Typography>
        ) : (
          <Typography variant="placeholder">
            {props.actionItem.userCanEdit ? "Set due date..." : "No due date"}
          </Typography>
        )}
      </ItemProperty>
    </>
  );
};

export { ActionItemDueDateProperty };
