import { Box, Skeleton, Stack } from "@mui/material";

const CommentSkeleton = () => {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      <Skeleton variant="circular" width={36} height={36} />
      <Box>
        <Skeleton width={100} height={24} />
        <Skeleton width={50} height={24} />
      </Box>
    </Stack>
  );
};

export { CommentSkeleton };
