import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetOneOnOneMeetingsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetOneOnOneMeetingsForUserRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getOneOnOnesForUserRequest({
    queryKey,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.meetings.getOneOnOneMeetingsForUser>
  >): Promise<GetOneOnOneMeetingsDto> {
    const accessToken = await getAccessToken();
    const [{ userId }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/users/${userId}/one-on-ones`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getOneOnOnesForUserRequest };
};

const useGetOneOnOnesForUser = (userId: string) => {
  const { getOneOnOnesForUserRequest } = useGetOneOnOneMeetingsForUserRequest();

  return useQuery({
    queryKey: queryKeys.meetings.getOneOnOneMeetingsForUser(userId),
    queryFn: getOneOnOnesForUserRequest,
  });
};

export { useGetOneOnOnesForUser };
