import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditActionItemSortOrderDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  actionItemGuid: string;
  sectionGuid: string | null;
  sortOrder: string;
};

const useEditActionItemSortOrderRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editActionItemSortOrderRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: EditActionItemSortOrderDto = {
      sectionGuid: variables.sectionGuid,
      sortOrder: variables.sortOrder,
    };

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${variables.actionItemGuid}/sort-order`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editActionItemSortOrderRequest };
};

const useEditActionItemSortOrder = () => {
  const { editActionItemSortOrderRequest } = useEditActionItemSortOrderRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editActionItemSortOrderRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({
        queryKey: queryKeys.actionItems.getActionItemSummary(variables.actionItemGuid),
      });

      await queryClient.refetchQueries({
        queryKey: queryKeys.actionItems.getActionItemDetail(variables.actionItemGuid),
      });
    },
  });
};

export { useEditActionItemSortOrder };
