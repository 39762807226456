import { Button, CircularProgress, Divider, Skeleton, Stack, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { SmallInputLabel, useDrawer, UserPicker } from "../../../../components";
import { useDeleteMeeting, useEditOneOnOneMeeting, useGetOneOnOneMeetingDetail } from "../../../../http";
import { useDestructiveConfirm } from "../../../../utilities";
import { DeleteOutlineRounded } from "@mui/icons-material";

interface IProps {
  meetingGuid: string;
}

const OneOnOneSettingsDrawerContent = (props: IProps) => {
  const { isLoading, data: meeting } = useGetOneOnOneMeetingDetail(props.meetingGuid);
  const { mutate: editMeeting } = useEditOneOnOneMeeting();
  const { mutate: deleteMeeting, isLoading: isDeletingMeeting } = useDeleteMeeting();
  const confirm = useDestructiveConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { closeDrawer } = useDrawer();

  function handleLeadChanged(newLeadUserId: string) {
    if (meeting === undefined || meeting.leadUser.userId === newLeadUserId) {
      return;
    }

    editMeeting({
      meetingGuid: props.meetingGuid,
      newLeadUserId: newLeadUserId,
      externalCallUrl: meeting.externalCallUrl,
    });
  }

  function handleExternalCallUrlBlurred(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (meeting === undefined || meeting.externalCallUrl === e.target.value) {
      return;
    }

    editMeeting({
      meetingGuid: props.meetingGuid,
      newLeadUserId: meeting.leadUser.userId,
      externalCallUrl: e.target.value,
    });
  }

  async function handleDeleteClicked() {
    if (meeting == null) {
      return;
    }

    try {
      await confirm({
        title: "Delete Meeting",
        content: "Are you sure you want to delete this meeting, its history, and all of its notes?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteMeeting(
      {
        meetingGuid: props.meetingGuid,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            `1:1 meeting for ${meeting.leadUser.displayName} and ${meeting.otherUser.displayName} has been deleted.`
          );
          closeDrawer();
        },
      }
    );
  }

  const isEditable = !isDeletingMeeting && meeting?.currentInstance == null;

  return isLoading || meeting == null ? (
    <Stack spacing={1} sx={{ px: { xs: 0.5, sm: 1 }, py: 0.5 }}>
      <Skeleton />
      <Skeleton />
    </Stack>
  ) : (
    <Stack spacing={0.5} divider={<Divider />} sx={{ px: { xs: 0.5, sm: 1 }, py: 0.5 }}>
      <Stack spacing={0.25}>
        <Stack spacing={0.25}>
          <SmallInputLabel>Lead</SmallInputLabel>
          <UserPicker
            placeholder={"Select a person"}
            value={meeting!.leadUser}
            disableClearable
            disabled={!isEditable}
            onChange={(user) => handleLeadChanged(user!.userId)}
          />
        </Stack>
        <Stack spacing={0.25}>
          <SmallInputLabel>Team Member</SmallInputLabel>
          <UserPicker placeholder={"Select a person"} value={meeting!.otherUser} disableClearable disabled />
        </Stack>
      </Stack>

      <Stack spacing={0.25}>
        <SmallInputLabel>{"Video Call Link (Optional)"}</SmallInputLabel>
        <TextField
          defaultValue={meeting.externalCallUrl ?? ""}
          placeholder="Enter link to your video call (Teams, Zoom, etc.)"
          disabled={!isEditable}
          onBlur={handleExternalCallUrlBlurred}
        />
      </Stack>
      <Button
        variant="tertiary"
        color="error"
        startIcon={<DeleteOutlineRounded />}
        endIcon={isDeletingMeeting ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
        disabled={!isEditable}
        onClick={handleDeleteClicked}
        sx={{ alignSelf: "flex-end" }}
      >
        Delete Meeting
      </Button>
    </Stack>
  );
};

export { OneOnOneSettingsDrawerContent };
