import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { ContextPicker } from "..";
import { IContextActionItem, IPlanConnectionPoint } from "../../models";
import { Typography } from "../Typography";

interface IProps {
  open: boolean;
  context?: IContextActionItem;
  onUpdateClicked?: (
    selectedContextActionItemGuid?: string,
    selectedContextActionItemHistoryId?: number
  ) => Promise<void>;
  onCancelled: () => void;
}

const EditContextModal = (props: IProps) => {
  const [selectedConnectionPoint, setSelectedConnectionPoint] = useState<IPlanConnectionPoint | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const defaultContext = useMemo(() => {
    if (props.context) {
      return {
        teamSlug: props.context?.teamPlanSection.teamSlug,
        actionItemGuid: props.context?.actionItemGuid,
      };
    } else {
      return undefined;
    }
  }, [props.context]);

  function handleCancelClicked() {
    props.onCancelled();
  }

  return (
    <Dialog open={props.open} onClose={handleCancelClicked}>
      <DialogTitle>Connect to Context</DialogTitle>
      <DialogContent>
        <Stack spacing={{ xs: 0.5, sm: 1 }}>
          <Typography>Select an item that provides context.</Typography>
          <ContextPicker defaultContext={defaultContext} onSelect={(value) => setSelectedConnectionPoint(value)} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isSaving} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isSaving}
          endIcon={isSaving ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={async () => {
            setIsSaving(true);
            await props.onUpdateClicked?.(selectedConnectionPoint?.guid, selectedConnectionPoint?.historyId);
            setIsSaving(false);
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditContextModal };
