import { StickyNote2Outlined } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSession } from "../../hooks";
import { GetActionItemDto, useEditActionItemNote } from "../../http";
import { ItemProperty } from "../ItemProperty";
import { TextEditor, TextEditorRef, TextViewer } from "../textEditor";

interface IProps {
  actionItem: GetActionItemDto;
}

const ActionItemNoteProperty = (props: IProps) => {
  const { userId } = useSession();
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState(props.actionItem.note);
  const { mutate: editNote, isLoading } = useEditActionItemNote();
  const editorRef = useRef<TextEditorRef | null>(null);

  useEffect(() => {
    if (isEditing) {
      editorRef.current?.focus();
    }
  }, [isEditing]);

  function handleCancelClicked() {
    setIsEditing(false);
  }

  function handleSaveClicked() {
    if (props.actionItem === undefined) {
      throw new Error("Action item is undefined");
    }

    editNote({
      guid: props.actionItem.guid,
      modifiedByUserId: userId,
      note: note,
    });

    setIsEditing(false);
  }

  return (
    <ItemProperty
      labelIcon={<StickyNote2Outlined />}
      label="Notes"
      labelProps={{ sx: { alignSelf: "start", top: 1 } }}
      canEdit={props.actionItem.userCanEdit}
      isEditing={isEditing}
      onEditClicked={() => setIsEditing(true)}
    >
      <Box sx={{ display: "flex" }}>
        {isEditing ? (
          <Stack spacing={0.5} sx={{ flex: 1 }}>
            <TextEditor
              ref={editorRef}
              initialValue={props.actionItem.note ?? ""}
              onChange={(value) => setNote(value)}
            />
            <Stack direction="row" spacing={0.5} sx={{ justifyContent: "flex-end" }}>
              <Button variant="text" disabled={isLoading} onClick={handleCancelClicked}>
                Cancel
              </Button>
              <Button variant="tertiary" disabled={isLoading} onClick={handleSaveClicked}>
                Save
              </Button>
            </Stack>
          </Stack>
        ) : (
          <TextViewer
            placeholder={props.actionItem.userCanEdit ? "Enter details..." : "No notes"}
            value={props.actionItem.note ?? ""}
          />
        )}
      </Box>
    </ItemProperty>
  );
};

export { ActionItemNoteProperty };
