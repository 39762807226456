import { AddRounded } from "@mui/icons-material";
import { Box, Button, IconButton, Skeleton, Stack, SxProps, Typography } from "@mui/material";
import React, { useState } from "react";
import { Accordion } from "./Accordion";
import { ViewList } from "./ViewList";

interface IActiveItemsListProps<T> {
  draggablePlaceholder?: React.ReactNode;
  divider?: React.ReactNode;
  activeItemsSortFunction: (a: T, b: T) => number;
}

interface IClosedItemsListProps<T> {
  closedItemsFilter: (item: T) => boolean;
  closedItemsSortFunction: (a: T, b: T) => number;
}

interface IProps<T> {
  title: string;
  icon?: React.ReactNode;
  dataSource?: T[];
  isLoading: boolean;
  isExpanded: boolean;
  backgroundColorOnHover?: string;
  divider?: React.ReactNode;
  renderItem: (item: T, index: number, isClosed: boolean) => React.ReactNode;
  activeItemsListProps: IActiveItemsListProps<T>;
  closedItemsListProps: IClosedItemsListProps<T>;
  onAddClicked: (e: React.MouseEvent) => void;
  onExpandClicked: () => void;
  sx?: SxProps;
}

const AccordionViewList = <T extends unknown>(props: IProps<T>) => {
  const [showClosed, setShowClosed] = useState(false);

  const activeItemsCount =
    props.dataSource?.filter((item) => !props.closedItemsListProps.closedItemsFilter(item)).length ?? 0;
  const closedItemsCount =
    props.dataSource?.filter((item) => props.closedItemsListProps.closedItemsFilter(item)).length ?? 0;

  return (
    <Box sx={{ pb: props.isLoading ? 0 : closedItemsCount > 0 ? 0 : 1 }}>
      <Accordion
        title={
          <Stack direction="row" spacing={0.25} flexGrow={1} alignItems="top">
            <Box sx={{ display: "flex", alignSelf: "center" }}>{props.icon}</Box>
            <Typography variant="body2" sx={{ color: "grey.400" }}>
              {props.title}
            </Typography>
            {!props.isExpanded && (
              <Typography variant="caption" sx={{ position: "relative", top: 2 }}>
                ({activeItemsCount} Active, {closedItemsCount} Closed)
              </Typography>
            )}
          </Stack>
        }
        hoverActions={
          <IconButton size="small" onClick={props.onAddClicked}>
            <AddRounded />
          </IconButton>
        }
        expanded={props.isExpanded}
        hideTitleUnderline={!props.isExpanded}
        onExpandClicked={() => {
          if (showClosed && props.isExpanded) {
            setShowClosed(false);
          }

          props.onExpandClicked();
        }}
        backgroundColorOnHover={props.backgroundColorOnHover}
      >
        <ViewList
          dataSource={props.dataSource}
          isLoading={props.isLoading}
          divider={props.divider}
          activeItemsListProps={props.activeItemsListProps}
          closedItemsListProps={{
            ...props.closedItemsListProps,
            showClosed: showClosed,
            renderShowClosedButton: (params) => {
              return (
                <Button
                  variant="tertiary"
                  size="small"
                  onClick={() => {
                    params.onClick();
                    setShowClosed(!showClosed);
                  }}
                  sx={{ alignSelf: "flex-start", m: 0.5, ml: 1 }}
                >
                  {!params.closedItemsVisible ? `Show Closed (${params.closedItemCount})` : `Hide Closed`}
                </Button>
              );
            },
          }}
          renderSkeleton={(index) => <Skeleton variant="rectangular" key={index} height={24} sx={{ mx: 1, my: 0.5 }} />}
          renderItem={props.renderItem}
        />
      </Accordion>
    </Box>
  );
};

export { AccordionViewList };
