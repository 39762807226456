import { FlagOutlined, OpenInBrowserRounded } from "@mui/icons-material";
import { Box, Button, Divider, alpha, styled, useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { v4 as newGuid } from "uuid";
import { DraggableAccordionViewList, Issue, IssueDrawerContent, SignalRGroup, useDrawer } from "../../../../components";
import { Links } from "../../../../constants";
import { useSession, useSignalR } from "../../../../hooks";
import { IssueSummaryDto, queryKeys, useCreateIssue, useEditIssueSortOrder, useGetIssues } from "../../../../http";
import { lexorank } from "../../../../utilities";
import { useTeamPageLayoutContext } from "../../TeamPageLayout";

const Container = styled(Box)`
  background-color: ${({ theme }) => alpha(theme.palette.orange.main, 0.1)};
  border: solid;
  border-color: ${({ theme }) => alpha(theme.palette.orange.main, 0.3)};
  border-width: 1px;
  border-radius: 8px;
`;

interface IProps {
  teamSlug: string | undefined;
  teamName: string | undefined;
}

const TeamIssuesList = (props: IProps) => {
  const { team } = useTeamPageLayoutContext();
  const { data, isLoading } = useGetIssues(props.teamSlug, undefined);
  const [issues, setIssues] = useState<IssueSummaryDto[]>([]);
  const { showDrawer } = useDrawer();
  const [focusedIssueGuid, setFocusedIssueGuid] = useState<string | undefined>();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { mutate: createIssue } = useCreateIssue();
  const { mutate: editIssueSortOrder } = useEditIssueSortOrder();
  const user = useSession();
  const navigate = useNavigate();
  const theme = useTheme();
  const signalR = useSignalR();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data !== undefined) {
      setIssues(data.issues);
    }
  }, [data]);

  useEffect(() => {
    if (props.teamName) {
      signalR.joinGroup(SignalRGroup.TeamIssues(props.teamSlug!));
      return () => signalR.leaveGroup(SignalRGroup.TeamIssues(props.teamSlug!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.teamSlug]);

  useEffect(() => {
    const handleShouldRefetchTeamIssues = (teamSlug: string) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssues(teamSlug, undefined));
    };

    signalR.onShouldRefetchTeamIssues(handleShouldRefetchTeamIssues);
    return () => signalR.offShouldRefetchTeamIssues(handleShouldRefetchTeamIssues);
  }, [queryClient, signalR, user.userId]);

  async function handleAddClicked(e: React.MouseEvent) {
    e.stopPropagation();

    if (!isExpanded) {
      setIsExpanded(true);
    }

    const transitionDuration = !isExpanded ? theme.transitions.duration.standard : 0;

    // Wait for the accordion expansion transition to complete
    setTimeout(() => {
      if (props.teamSlug && props.teamName) {
        const newIssueGuid = newGuid();

        createIssue({
          guid: newIssueGuid,
          teamSlug: props.teamSlug,
          teamName: props.teamName,
          description: "",
          sortOrder: lexorank.getPrevRank(issues),
          createdByUser: {
            userId: user.userId,
            profilePhotoSmallUrl: user.profilePhotoSmallUrl,
            displayName: user.displayName,
            enabled: true,
          },
        });

        setFocusedIssueGuid(newIssueGuid);
      }
    }, transitionDuration);
  }

  async function handleIssueClicked(e: React.MouseEvent, issue: IssueSummaryDto) {
    e.stopPropagation();

    showDrawer({
      title: (
        <Button
          variant="tertiary"
          endIcon={<OpenInBrowserRounded />}
          onClick={() => navigate(Links.IssueDetail(issue.guid))}
        >
          Open Page
        </Button>
      ),
      content: <IssueDrawerContent issueGuid={issue.guid} />,
    });
  }

  async function handleItemReordered(issueGuid: string, sourceIndex: number, destinationIndex: number) {
    if (props.teamSlug == null) {
      return;
    }

    const openIssues = issues.filter((issue) => issue.closedOnDateTimeUtc == null) ?? [];
    const newSortOrder = lexorank.getRank(openIssues, sourceIndex, destinationIndex);

    setIssues([
      ...issues.map((issue) => {
        if (issue.guid === issueGuid) {
          return {
            ...issue,
            sortOrder: newSortOrder,
          };
        }

        return issue;
      }),
    ]);

    editIssueSortOrder({
      issueGuid,
      teamSlug: props.teamSlug,
      sortOrder: newSortOrder,
    });
  }

  function closedIssueSortFunction(a: IssueSummaryDto, b: IssueSummaryDto) {
    const dateA = DateTime.fromISO(a.closedOnDateTimeUtc || a.createdOnDateTimeUtc);
    const dateB = DateTime.fromISO(b.closedOnDateTimeUtc || b.createdOnDateTimeUtc);

    return dateA < dateB ? 1 : -1;
  }

  return (
    <Container>
      <DraggableAccordionViewList
        droppableId="issues"
        title="Issues"
        icon={<FlagOutlined />}
        divider={<Divider variant="dashed" sx={{ mx: { xs: 0.5, sm: 1 } }} />}
        dataSource={issues}
        isLoading={isLoading}
        isExpanded={isExpanded}
        isDragDisabled={!team?.isMember}
        renderItem={(issue) => (
          <Issue
            issue={issue}
            isFocused={issue.guid === focusedIssueGuid}
            onClick={(e) => handleIssueClicked(e, issue)}
            onBlur={() => setFocusedIssueGuid(undefined)}
            sx={{ pr: 1 }}
          />
        )}
        activeItemsListProps={{
          activeItemsSortFunction: (a, b) => a.sortOrder.localeCompare(b.sortOrder),
        }}
        closedItemsListProps={{
          closedItemsFilter: (issue) => issue.closedOnDateTimeUtc != null,
          closedItemsSortFunction: closedIssueSortFunction,
        }}
        onAddClicked={handleAddClicked}
        onExpandClicked={() => setIsExpanded(!isExpanded)}
        onItemReordered={handleItemReordered}
        color={theme.palette.orange.main}
        sx={{ mx: { xs: 0.5, sm: 1 } }}
      />
    </Container>
  );
};

export { TeamIssuesList };
