import { useMutation } from "@tanstack/react-query";
import { FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { configuration } from "../../../configuration";

type Variables = {
  meetingGuid: string;
};

const useEndMeetingRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function endMeetingRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/meetings/${variables.meetingGuid}/end`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "put",
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { endMeetingRequest };
};

const useEndMeeting = () => {
  const { endMeetingRequest } = useEndMeetingRequest();

  return useMutation({
    mutationFn: endMeetingRequest,
  });
};

export { useEndMeeting };
