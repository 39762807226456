import { QueryClient, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditMeetingAgendaStepDto, FetchError, GetOneOnOneMeetingDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  meetingGuid: string;
  agendaStepIndex: number;
};

const useEditMeetingAgendaStepRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editMeetingAgendaStepRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: EditMeetingAgendaStepDto = {
      activeAgendaStepIndex: variables.agendaStepIndex,
    };

    const response = await fetch(`${configuration.apiRootUrl}/meetings/${variables.meetingGuid}/set-step`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editMeetingAgendaStepRequest };
};

const useEditMeetingAgendaStep = () => {
  const { editMeetingAgendaStepRequest } = useEditMeetingAgendaStepRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editMeetingAgendaStepRequest,
    onMutate: async (variables) => {
      const cachedOneOnOneMeetingDetailData = await optimisticallyUpdateOneOnOneMeetingDetail(
        queryClient,
        queryKeys.meetings.getOneOnOneMeetingDetail(variables.meetingGuid),
        variables
      );
      return { cachedOneOnOneMeetingDetailData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(
        queryKeys.meetings.getOneOnOneMeetingDetail(variables.meetingGuid),
        context?.cachedOneOnOneMeetingDetailData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.meetings.getOneOnOneMeetingDetail(variables.meetingGuid));
    },
  });
};

async function optimisticallyUpdateOneOnOneMeetingDetail(
  queryClient: QueryClient,
  queryKey: QueryKey,
  variables: Variables
) {
  await queryClient.cancelQueries();
  const cachedData = queryClient.getQueryData<GetOneOnOneMeetingDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: GetOneOnOneMeetingDto = {
      ...cachedData,
      currentInstance: {
        ...cachedData.currentInstance!,
        activeAgendaStepIndex: variables.agendaStepIndex,
      },
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);

    return cachedData;
  }
}

export { useEditMeetingAgendaStep };
