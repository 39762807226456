import { FilterListRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import { Button, Card, CardHeader, Divider, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { SnackbarContent, TertiarySelect } from "../../../../components";
import { OneOnOneMeetingDto, useGetOneOnOnesForUser } from "../../../../http";
import {
  CreateOneOnOneMeetingAsLeadModal,
  CreateOneOnOneMeetingWithLeadModal,
  OneOnOneMeetingListItem,
  OneOnOneMeetingListItemSkeleton,
} from ".";
import { DateTime } from "luxon";
import { useSession } from "../../../../hooks";
import { useSnackbar } from "notistack";

type FilterOptionValue = "All" | "WithLead" | "AsLead";

interface IParams {
  userId: string;
}

interface FilterOption {
  label: string;
  value: FilterOptionValue;
}

const filterOptions: FilterOption[] = [
  { label: "All", value: "All" },
  { label: "With Lead", value: "WithLead" },
  { label: "As Lead", value: "AsLead" },
];

const OneOnOneMeetingsList = () => {
  const sessionUser = useSession();
  const { userId: userGuid } = useParams<keyof IParams>() as IParams;
  const { data, isLoading } = useGetOneOnOnesForUser(userGuid);
  const [filteredMeetings, setFilteredMeetings] = useState<OneOnOneMeetingDto[]>([]);
  const [filterValue, setFilterValue] = useState<FilterOptionValue>("All");
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isCreateWithLeadModalVisible, setIsCreateWithLeadModalVisible] = useState(false);
  const [isCreateAsLeadModalVisible, setIsCreateAsLeadModalVisible] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFilteredMeetings(
      data?.meetings.filter((meeting) => {
        switch (filterValue) {
          case "All":
            return true;
          case "AsLead":
            return meeting.leadUser.userId === userGuid;
          case "WithLead":
            return meeting.otherUser.userId === userGuid;
          default:
            return false;
        }
      }) ?? []
    );
  }, [data, filterValue, userGuid]);

  function handleCreateWithLeadClicked() {
    setMenuAnchor(null);
    setIsCreateWithLeadModalVisible(true);
  }

  function handleCreateAsLeadClicked() {
    setMenuAnchor(null);
    setIsCreateAsLeadModalVisible(true);
  }

  function handleMeetingCreated() {
    enqueueSnackbar(<SnackbarContent title="Meeting Created" />, {
      variant: "success",
    });
  }

  function meetingSortFunction(a: OneOnOneMeetingDto, b: OneOnOneMeetingDto) {
    if ((a.leadUser.userId === userGuid) !== (b.leadUser.userId === userGuid)) {
      return a.leadUser.userId === userGuid ? 1 : -1;
    }

    let aDate = a.lastInstanceDateTimeUtc ? DateTime.fromISO(a.lastInstanceDateTimeUtc) : null;
    let bDate = b.lastInstanceDateTimeUtc ? DateTime.fromISO(b.lastInstanceDateTimeUtc) : null;

    if (aDate == null && bDate == null) {
      return 0;
    }
    if (aDate == null) {
      return 1;
    }
    if (bDate == null) {
      return -1;
    }

    return bDate < aDate ? -1 : 1;
  }

  return (
    <>
      <Card>
        <CardHeader title={<Typography variant="h6">1:1 Meetings</Typography>} />
        <Divider />
        <Stack>
          <TertiarySelect
            value={filterValue}
            renderValue={(selectedValue: any) => {
              const selectedOption = filterOptions.find((option: any) => option.value === selectedValue);
              return (
                <Stack direction="row" spacing={0.25} alignItems="center">
                  <FilterListRounded fontSize="large" />
                  <Typography variant="button">{selectedOption!.label}</Typography>
                </Stack>
              );
            }}
            onChange={(e) => setFilterValue(e.target.value as FilterOptionValue)}
            sx={{ mx: 1, my: 0.5, alignSelf: "start" }}
          >
            {filterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TertiarySelect>
          <Divider />
          <Stack divider={<Divider variant="dashed" sx={{ mx: 1 }} />}>
            {isLoading ? (
              <OneOnOneMeetingListItemSkeleton />
            ) : data === undefined ? (
              <Typography sx={{ m: 1 }}>Nothing here yet.</Typography>
            ) : filteredMeetings.length > 0 ? (
              filteredMeetings
                .sort(meetingSortFunction)
                .map((meeting) => <OneOnOneMeetingListItem key={meeting.guid} meeting={meeting} />)
            ) : (
              <Typography sx={{ m: 1 }}>No matches found.</Typography>
            )}

            {sessionUser.userId === userGuid && (
              <Button
                variant="outlined"
                endIcon={<KeyboardArrowDownRounded />}
                sx={{ alignSelf: "start", m: 1 }}
                onClick={(e) => setMenuAnchor(e.currentTarget)}
              >
                Create 1:1 Meeting
              </Button>
            )}
          </Stack>
        </Stack>
      </Card>

      {sessionUser.userId === userGuid && (
        <>
          <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
            <MenuItem onClick={handleCreateWithLeadClicked}>With Lead</MenuItem>
            <MenuItem onClick={handleCreateAsLeadClicked}>As Lead</MenuItem>
          </Menu>

          <CreateOneOnOneMeetingWithLeadModal
            open={isCreateWithLeadModalVisible}
            onClose={() => setIsCreateWithLeadModalVisible(false)}
            onAdded={handleMeetingCreated}
          />

          <CreateOneOnOneMeetingAsLeadModal
            open={isCreateAsLeadModalVisible}
            onClose={() => setIsCreateAsLeadModalVisible(false)}
            onAdded={handleMeetingCreated}
          />
        </>
      )}
    </>
  );
};

export { OneOnOneMeetingsList };
