import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../../configuration";
import { EditMeetingNoteDto, FetchError, GetMeetingNotesDto } from "../../../types";
import { useAccessToken } from "../../../use-access-token";
import { queryKeys } from "../../queryKeys";

type Variables = {
  meetingNoteGuid: string;
  meetingGuid: string;
  text: string;
};

const useEditMeetingNoteRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editMeetingNoteRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: EditMeetingNoteDto = {
      text: variables.text,
    };

    const response = await fetch(`${configuration.apiRootUrl}/meeting-notes/${variables.meetingNoteGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "puT",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editMeetingNoteRequest };
};

const useEditMeetingNote = () => {
  const { editMeetingNoteRequest } = useEditMeetingNoteRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editMeetingNoteRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.meetings.getMeetingNotes(variables.meetingGuid));
      const cachedData = queryClient.getQueryData<GetMeetingNotesDto>(
        queryKeys.meetings.getMeetingNotes(variables.meetingGuid)
      );

      if (cachedData) {
        const optimisticallyUpdatedData: GetMeetingNotesDto = {
          ...cachedData,
          meetingNotes: cachedData.meetingNotes.map((meetingNote) => {
            if (meetingNote.guid === variables.meetingNoteGuid) {
              return {
                ...meetingNote,
                text: variables.text,
                modifiedDateTimeUtc: DateTime.utc().toISO(),
              };
            }

            return meetingNote;
          }),
        };

        queryClient.setQueryData(queryKeys.meetings.getMeetingNotes(variables.meetingGuid), optimisticallyUpdatedData);
      }

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.meetings.getMeetingNotes(variables.meetingGuid), context?.cachedData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.meetings.getMeetingNotes(variables.meetingGuid));
    },
  });
};

export { useEditMeetingNote };
