enum SignalRClientMethod {
  ReceiveChatMessage = "ReceiveChatMessage",
  ShouldRefetchActionItem = "ShouldRefetchActionItem",
  ShouldRefetchComments = "ShouldRefetchComments",
  ShouldRefetchMeetingListItem = "ShouldRefetchMeetingListItem",
  ShouldRefetchMeetingNotes = "ShouldRefetchMeetingNotes",
  ShouldRefetchMeetingParticipants = "ShouldRefetchMeetingParticipants",
  ShouldEndMeeting = "ShouldEndMeeting",
  ShouldRefetchPlan = "ShouldRefetchPlan",
  ShouldRefetchTeamIssues = "ShouldRefetchTeamIssues",
}

enum SignalRServerMethod {
  JoinGroup = "JoinGroup",
  JoinGroups = "JoinGroups",
  LeaveGroup = "LeaveGroup",
  LeaveGroups = "LeaveGroups",
  SendChatMessage = "SendChatMessage",
  SendRefetchActionItem = "SendRefetchActionItem",
  SendRefetchComments = "SendRefetchComments",
  SendRefetchMeetingListItem = "SendRefetchMeetingListItem",
  SendRefetchMeetingNotes = "SendRefetchMeetingNotes",
  SendRefetchMeetingParticipants = "SendRefetchMeetingParticipants",
  SendEndMeeting = "SendEndMeeting",
  SendRefetchPlan = "SendRefetchPlan",
  SendRefetchTeamIssues = "SendRefetchTeamIssues",
}

const SignalRGroup = {
  ActionItem: (actionItemGuid: string) => `ActionItem-${actionItemGuid}`,
  CommentThread: (threadGuid: string) => `CommentThread-${threadGuid}`,
  Meeting: (meetingGuid: string) => `Meeting-${meetingGuid}`,
  MeetingNotes: (meetingGuid: string) => `MeetingNotes-${meetingGuid}`,
  MeetingListItem: (meetingGuid: string) => `MeetingListItem-${meetingGuid}`,
  Plan: (planGuid: string) => `Plan-${planGuid}`,
  TeamIssues: (teamSlug: string) => `TeamIssues-${teamSlug}`,
};

export { SignalRClientMethod, SignalRGroup, SignalRServerMethod };
