import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { HeaderLayout, SmallInputLabel, TextEditor, TextEditorRef, UserSelect } from "../../../components";
import { Links } from "../../../constants";
import { useSession } from "../../../hooks";
import {
  useEditNotificationPreferences,
  useEditPrimaryRole,
  useEditUser,
  useGetNotificationPreferences,
  useGetRolesForUser,
  useGetUser,
} from "../../../http";
import { ILocation, IUser } from "../../../models";
import { getTop } from "../../../utilities";
import { LocationPicker } from "./components";

const StickySideMenuCard = styled(Card)`
  position: sticky;
  top: 100px;
  overflow: visible;
  z-index: 2;
  padding: ${({ theme }) => theme.spacing(1)};

  a {
    color: ${({ theme }) => theme.palette.text.primary};
    text-decoration: none;
    text-transform: uppercase;
  }
`;

interface IUserSettingsFormState {
  bio: string;
  joinedDate: DateTime | null;
  peopleLead: IUser | null;
  pronouns: string;
  location: ILocation | null;
  notificationPreferences: {
    [typeGuid: string]: {
      email: boolean;
      inApp: boolean;
    };
  };
  primaryRoleGuid: string | null;
}

const UserSettingsPage = () => {
  const { userId } = useSession();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading: isLoadingUserDetails, data: userDetails } = useGetUser(userId);
  const { isLoading: isLoadingNotificationPreferences, data: notificationPreferences } =
    useGetNotificationPreferences(userId);
  const { isLoading: isLoadingRoles, data: roles } = useGetRolesForUser(userId!);
  const { isLoading: isUpdatingNotificationPreferences, mutateAsync: editNotificationPreferencesAsync } =
    useEditNotificationPreferences();
  const { isLoading: isUpdatingPrimaryRole, mutateAsync: editPrimaryRoleAsync } = useEditPrimaryRole();
  const { isLoading: isUpdatingUserDetails, mutateAsync: editUserDetailsAsync } = useEditUser();
  const { formState, control, handleSubmit, reset, setValue, getValues } = useForm<IUserSettingsFormState>({
    defaultValues: {
      bio: "",
      joinedDate: null,
      peopleLead: null,
      pronouns: "",
      location: {
        countryCode: null,
        stateCode: null,
        cityName: null,
      },
      notificationPreferences: {},
      primaryRoleGuid: null,
    },
  });
  const navigate = useNavigate();

  const profileRef = useRef<HTMLParagraphElement>(null);
  const notificationsRef = useRef<HTMLParagraphElement>(null);
  const rolesRef = useRef<HTMLParagraphElement>(null);
  const bioRef = useRef<TextEditorRef>(null);

  useEffect(() => {
    scrollToHash(location.hash);
  }, [location.hash]);

  useEffect(() => {
    if (userDetails) {
      reset({
        ...getValues(),
        bio: userDetails.bio,
        joinedDate: userDetails.joinedDate,
        peopleLead: userDetails.peopleLead,
        pronouns: userDetails.pronouns,
        location: {
          countryCode: userDetails.countryCode,
          stateCode: userDetails.stateCode,
          cityName: userDetails.cityName,
        },
      });

      // Update TextEditor state
      bioRef.current?.setContent(userDetails.bio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUserDetails]);

  useEffect(() => {
    if (notificationPreferences) {
      const map = new Map<string, { email: boolean; inApp: boolean }>();

      notificationPreferences.categories.forEach((category) => {
        category.types.forEach((type) => {
          map.set(type.guid, { email: type.email, inApp: type.inApp });
        });
      });

      reset({
        ...getValues(),
        notificationPreferences: Object.fromEntries(map),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationPreferences]);

  useEffect(() => {
    if (roles) {
      reset({
        ...getValues(),
        primaryRoleGuid: roles.find((x) => x.isPrimaryRole)?.guid ?? null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRoles]);

  async function handleSaveClicked() {
    handleSubmit(async (data) => {
      await Promise.all([
        editUserDetailsAsync({
          guid: userId!,
          bio: data.bio,
          joinedDate: data.joinedDate,
          pronouns: data.pronouns,
          peopleLeadUserId: data.peopleLead?.userId ?? null,
          location: data.location,
        }),
        editPrimaryRoleAsync({
          userId: userId!,
          roleGuid: data.primaryRoleGuid,
        }),
        editNotificationPreferencesAsync({
          userId: userId!,
          notificationPreferences: Object.entries(data.notificationPreferences).map((x) => ({
            typeGuid: x[0],
            email: x[1].email,
            inApp: x[1].inApp,
          })),
        }),
      ]);

      navigate(Links.User(userId));
      enqueueSnackbar("Settings saved successfully.", { variant: "info" });
    })();
  }

  function scrollToHash(hash: string) {
    let top = 0;

    switch (hash) {
      case "#profile":
        top = getTop(profileRef, 100);
        break;
      case "#notifications":
        top = getTop(notificationsRef, 100);
        break;
      case "#roles":
        top = getTop(rolesRef, 100);
        break;
    }

    window.scrollTo({ top, behavior: "smooth" });
  }

  const isLoading = isLoadingNotificationPreferences || isLoadingRoles;
  const isUpdating = isUpdatingUserDetails || isUpdatingNotificationPreferences || isUpdatingPrimaryRole;

  return (
    <HeaderLayout
      title={
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h3">Settings</Typography>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" disabled={isUpdating} onClick={() => navigate(Links.User(userId!))}>
              Cancel
            </Button>
            <Button
              variant="contained"
              endIcon={isUpdating ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
              disabled={!formState.isDirty || isLoading || isUpdating}
              onClick={handleSaveClicked}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Grid container spacing={1} sx={{ justifyContent: "start" }}>
        <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
          <StickySideMenuCard>
            <Stack spacing={1}>
              <Link href="#profile">Profile</Link>
              <Link href="#roles">Roles</Link>
              <Link href="#notifications">Notifications</Link>
            </Stack>
          </StickySideMenuCard>
        </Grid>
        <Grid item sm={12} md={9} sx={{ width: "100%" }}>
          <Stack spacing={1}>
            <Card ref={profileRef}>
              <CardHeader title={<Typography variant="h6">Profile</Typography>} />
              <Divider />
              <Stack spacing={1} sx={{ m: 1 }}>
                <Stack spacing={0.25}>
                  <SmallInputLabel>Bio</SmallInputLabel>
                  <Controller
                    name="bio"
                    control={control}
                    render={({ field }) => (
                      <TextEditor
                        ref={bioRef}
                        initialValue={field.value}
                        onChange={field.onChange}
                        placeholder="Type a few words about yourself..."
                      />
                    )}
                  />
                </Stack>
                <Stack spacing={0.25}>
                  <SmallInputLabel>People Lead</SmallInputLabel>
                  <Controller
                    name="peopleLead"
                    control={control}
                    render={({ field }) => (
                      <UserSelect value={field.value} onChange={field.onChange} excludeSessionUser />
                    )}
                  />
                </Stack>

                <Stack spacing={0.25}>
                  <SmallInputLabel>Location</SmallInputLabel>
                  <Controller
                    name="location"
                    control={control}
                    render={({ field }) => <LocationPicker value={field.value} onChange={field.onChange} />}
                  />
                </Stack>

                <Stack spacing={0.25}>
                  <SmallInputLabel>Date Joined</SmallInputLabel>
                  <Controller
                    name="joinedDate"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DesktopDatePicker
                          format="MMMM d, yyyy"
                          value={field.value}
                          onChange={field.onChange}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                            },
                            openPickerButton: {
                              size: "small",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Stack>

                <Stack spacing={0.25}>
                  <SmallInputLabel>Pronouns</SmallInputLabel>
                  <Controller
                    name="pronouns"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        value={field.value}
                        onChange={field.onChange}
                        placeholder="Enter your pronouns here..."
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Card>
            <Card ref={rolesRef}>
              <CardHeader title={<Typography variant="h6">Primary Role</Typography>} />
              <Divider />
              <Box sx={{ mx: 1, my: 0.5 }}>
                {isLoading ? (
                  <Stack spacing={0.25}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </Stack>
                ) : (
                  <Controller
                    name={`primaryRoleGuid`}
                    control={control}
                    render={({ field }) => (
                      <RadioGroup value={field.value}>
                        {roles?.map((role) => (
                          <FormControlLabel
                            value={role.guid}
                            key={role.guid}
                            disabled={isUpdating}
                            control={
                              <Radio
                                onClick={(e) => {
                                  if (role.guid === field.value) {
                                    setValue(`primaryRoleGuid`, null, { shouldDirty: true });
                                  } else {
                                    setValue(`primaryRoleGuid`, role.guid, { shouldDirty: true });
                                  }
                                }}
                              />
                            }
                            label={`${role.name} @ ${role.parentTeam.name}`}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                )}
              </Box>
            </Card>
            <Card ref={notificationsRef}>
              <CardHeader title={<Typography variant="h6">Notifications</Typography>} />
              <Divider />
              <Stack spacing={1} sx={{ m: 1 }}>
                {isLoading ? (
                  <Stack spacing={0.25}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </Stack>
                ) : (
                  <>
                    {notificationPreferences?.categories.map((category, index) => (
                      <TableContainer key={index}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ width: "100%" }}>
                                <SmallInputLabel>{category.description}</SmallInputLabel>
                              </TableCell>
                              <TableCell align="center" sx={{ px: 0.5 }}>
                                <SmallInputLabel>In App</SmallInputLabel>
                              </TableCell>
                              <TableCell align="center" sx={{ px: 0.5 }}>
                                <SmallInputLabel>Email</SmallInputLabel>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {category.types.map((type, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Typography>{type.description}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`notificationPreferences.${type.guid}.inApp`}
                                    control={control}
                                    render={({ field }) => (
                                      <Switch
                                        disabled={isLoading || isUpdating}
                                        onChange={field.onChange}
                                        checked={field.value ?? true}
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`notificationPreferences.${type.guid}.email`}
                                    control={control}
                                    render={({ field }) => (
                                      <Switch
                                        disabled={isLoading || isUpdating}
                                        onChange={field.onChange}
                                        checked={field.value ?? true}
                                      />
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ))}
                  </>
                )}
              </Stack>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </HeaderLayout>
  );
};

export { UserSettingsPage };
