import { Card, CardContent, CardHeader, Divider, Skeleton, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Blocks } from "../../../../components";
import { useGetPlan, UserDto } from "../../../../http";
import { IPlan } from "../../../../models";
import { useSession } from "../../../../hooks";

interface IProps {
  user: UserDto;
}

const PersonalMapReview = (props: IProps) => {
  const { userId: sessionUserId } = useSession();
  const { data, isLoading } = useGetPlan(props.user.planGuid!);
  const [plan, setPlan] = useState<IPlan>();
  const theme = useTheme();

  useEffect(() => {
    if (data !== undefined) {
      setPlan(data);
    }
  }, [data]);

  if (isLoading || plan === undefined) {
    return (
      <Stack spacing={1}>
        {[...new Array(2)].map((_, index) => (
          <Card key={index}>
            <CardHeader title={<Skeleton />} />
            <Divider sx={{ borderColor: theme.palette.grey[200] }} />
            <CardContent>
              <Skeleton />
              <Skeleton />
            </CardContent>
          </Card>
        ))}
      </Stack>
    );
  }

  return (
    <Blocks
      plan={plan}
      isRowView={true}
      isPastView={false}
      canEdit={props.user.userId === sessionUserId}
      onActionItemSortOrderChanged={(updatedPlan) => setPlan(updatedPlan)}
    />
  );
};

export { PersonalMapReview };
