import { Typography } from "@mui/material";
import { HeaderLayout } from "../../components";
import { setTitlePrefix } from "../../utilities";
import { OrganizationNetworkGraph } from "./components";

const HomePage = () => {
  setTitlePrefix();

  return (
    <HeaderLayout
      title={<Typography variant="h3">Home</Typography>}
      contentContainerProps={{
        sx: { p: 0 },
      }}
    >
      <OrganizationNetworkGraph />
    </HeaderLayout>
  );
};

export { HomePage };
