import { AddRounded, AddTask, AssignmentIndOutlined, FlagOutlined, GroupsOutlined } from "@mui/icons-material";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography, styled } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { CreateIssueModal, CreateRoleModal, CreateTaskModal, CreateTeamModal } from "..";
import { Links } from "../../constants";
import { useSession, useSettings } from "../../hooks";

const IconButtonText = styled(Typography)<{ $isExpanded: boolean }>`
  opacity: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
  margin-left: ${({ $isExpanded }) => ($isExpanded ? "5px" : 0)};
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.875rem;
  transition: ${({ theme }) => theme.transitions.create(["opacity", "margin"])};
  text-transform: uppercase;
`;

const SideMenuAddButton = () => {
  const { isSessionLoaded } = useSession();
  const { sideMenuSettings } = useSettings();
  const navigate = useNavigate();
  const [addMenuAnchor, setAddMenuAnchor] = useState<null | HTMLElement>(null);
  const [isCreateIssueModalOpen, setIsCreateIssueModalOpen] = useState(false);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] = useState(false);

  function handleTeamCreated(slug: string) {
    setIsCreateTeamModalOpen(false);
    navigate(Links.Team(slug));
  }

  function handleRoleCreated(guid: string) {
    setIsCreateRoleModalOpen(false);
    navigate(Links.RoleDetail(guid));
  }

  return (
    <>
      <Stack
        component="label"
        direction="row"
        spacing={0.5}
        sx={{ alignItems: "center", cursor: "pointer", width: "100%" }}
      >
        <IconButton
          variant="filled"
          onClick={(event) => setAddMenuAnchor(event.currentTarget)}
          disabled={!isSessionLoaded}
        >
          <AddRounded fontSize="large" />
        </IconButton>
        <IconButtonText $isExpanded={sideMenuSettings.isExpanded}>Add</IconButtonText>
      </Stack>

      <Menu
        anchorEl={addMenuAnchor}
        open={Boolean(addMenuAnchor)}
        onClose={() => setAddMenuAnchor(null)}
        sx={{ m: 0.5 }}
        slotProps={{
          paper: {
            sx: {
              minWidth: 180,
            },
          },
        }}
        anchorOrigin={
          sideMenuSettings.isExpanded
            ? { vertical: "top", horizontal: "right" }
            : { vertical: "bottom", horizontal: "right" }
        }
        transformOrigin={
          sideMenuSettings.isExpanded
            ? { vertical: "bottom", horizontal: "left" }
            : { vertical: "bottom", horizontal: "left" }
        }
      >
        <MenuItem
          onClick={() => {
            setAddMenuAnchor(null);
            setIsCreateTaskModalOpen(true);
          }}
        >
          <ListItemIcon>
            <AddTask />
          </ListItemIcon>
          <ListItemText>Task</ListItemText>
        </MenuItem>
        <MenuItem
          divider
          onClick={() => {
            setAddMenuAnchor(null);
            setIsCreateIssueModalOpen(true);
          }}
        >
          <ListItemIcon>
            <FlagOutlined />
          </ListItemIcon>
          <ListItemText>Issue</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAddMenuAnchor(null);
            setIsCreateTeamModalOpen(true);
          }}
        >
          <ListItemIcon>
            <GroupsOutlined />
          </ListItemIcon>
          <ListItemText>Team</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAddMenuAnchor(null);
            setIsCreateRoleModalOpen(true);
          }}
        >
          <ListItemIcon>
            <AssignmentIndOutlined />
          </ListItemIcon>
          <ListItemText>Role</ListItemText>
        </MenuItem>
      </Menu>

      <CreateIssueModal
        visible={isCreateIssueModalOpen}
        onCreated={() => setIsCreateIssueModalOpen(false)}
        onCancelled={() => setIsCreateIssueModalOpen(false)}
      />

      <CreateTaskModal
        visible={isCreateTaskModalOpen}
        onCreated={() => setIsCreateTaskModalOpen(false)}
        onCancelled={() => setIsCreateTaskModalOpen(false)}
      />

      <CreateTeamModal
        visible={isCreateTeamModalOpen}
        onCreated={handleTeamCreated}
        onCancel={() => setIsCreateTeamModalOpen(false)}
      />

      <CreateRoleModal
        visible={isCreateRoleModalOpen}
        onCreated={handleRoleCreated}
        onCancelled={() => setIsCreateRoleModalOpen(false)}
      />
    </>
  );
};

export { SideMenuAddButton };
