import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { v4 as newGuid } from "uuid";
import { SmallInputLabel, UserPicker } from "../../../../components";
import { useSession } from "../../../../hooks";
import { useCreateOneOnOneMeeting, UserDto } from "../../../../http";

interface ICreateOneOnOneMeetingWithLeadFormState {
  lead: UserDto | null;
}

interface IProps {
  open: boolean;
  onClose: () => void;
  onAdded?: () => void;
}

const CreateOneOnOneMeetingWithLeadModal = (props: IProps) => {
  const user = useSession();
  const { mutate: createMeeting } = useCreateOneOnOneMeeting();
  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = useForm<ICreateOneOnOneMeetingWithLeadFormState>({
    defaultValues: {
      lead: null,
    },
  });

  function handleCreateClicked() {
    handleSubmit((data) => {
      createMeeting(
        {
          meetingGuid: newGuid(),
          leadUser: data.lead!,
          otherUser: {
            displayName: user.displayName,
            profilePhotoSmallUrl: user.profilePhotoSmallUrl,
            userId: user.userId,
            enabled: true,
          },
        },
        {
          onSuccess: () => {
            reset(undefined, { keepDefaultValues: true });
            props.onAdded?.();
            props.onClose();
          },
        }
      );
    })();
  }

  function handleCancelClicked() {
    reset(undefined, { keepDefaultValues: true });
    props.onClose();
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Create 1:1 Meeting With Lead</DialogTitle>
      <DialogContent>
        <Stack spacing={0.25}>
          <SmallInputLabel>Lead</SmallInputLabel>
          <Controller
            name="lead"
            control={control}
            rules={{ required: "Lead is required" }}
            render={({ field }) => (
              <UserPicker
                {...field}
                filterOptions={(options) => options.filter((u) => u.userId !== user.userId)}
                placeholder="Select a person..."
                error={errors["lead"] !== undefined}
                helperText={errors["lead"]?.message}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleCreateClicked}>
          Create Meeting
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CreateOneOnOneMeetingWithLeadModal };
