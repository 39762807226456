import { useContext } from "react";
import { SignalRContext } from "../SignalRContext";
import { SignalRClientMethod, SignalRServerMethod } from "../constants";
import { SignalRPlansContext } from "./SignalRPlansContext";

interface IProps {
  children?: React.ReactNode;
}

const SignalRPlansProvider = (props: IProps) => {
  const context = useContext(SignalRContext);

  return (
    <SignalRPlansContext.Provider
      value={{
        sendRefetchPlan: (...args) => context.invoke(SignalRServerMethod.SendRefetchPlan, args),
        onShouldRefetchPlan: (handler) => context.registerHandler(SignalRClientMethod.ShouldRefetchPlan, handler),
        offShouldRefetchPlan: (handler) => context.unregisterHandler(SignalRClientMethod.ShouldRefetchPlan, handler),
      }}
    >
      {props.children}
    </SignalRPlansContext.Provider>
  );
};

export { SignalRPlansProvider };
