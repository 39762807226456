import { useQueryClient } from "@tanstack/react-query";
import { Comments } from "../../../components";
import { CommentSubject } from "../../../constants";
import { GetWikiPageDto, queryKeys } from "../../../http";

interface Props {
  page: GetWikiPageDto;
}

const WikiPageCommentsDrawerContent = (props: Props) => {
  const queryClient = useQueryClient();

  return (
    <Comments
      subjectType={CommentSubject.WikiPage}
      subjectGuid={props.page.wikiPageGuid}
      onCommentCreated={() => queryClient.invalidateQueries(queryKeys.wiki.getWikiPage(props.page.slug))}
      onCommentDeleted={() => queryClient.invalidateQueries(queryKeys.wiki.getWikiPage(props.page.slug))}
    />
  );
};

export { WikiPageCommentsDrawerContent };
