import { Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { ISurveyInstance } from "../../../models";
import { DateTime } from "luxon";
import { SurveyInstance } from "./SurveyInstance";
import { InfoOutlined } from "@mui/icons-material";
import { ViewList } from "../../../components";
import { SurveyInstanceModal } from ".";

interface IProps {
  instances: ISurveyInstance[];
  surveyGuid: string;
}

const SurveyInstancesList = (props: IProps) => {
  const [selectedInstanceGuid, setSelectedInstanceGuid] = useState<string | undefined>(undefined);
  const [isInstanceModalVisible, setIsInstanceModalVisible] = useState(false);
  const disableCreateNewInstance = props.instances.some((item) => item.endDateUtc == null);

  return (
    <>
      <Stack spacing={0.25} divider={<Divider />}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ px: 1 }}>
          <Typography variant="body2">Share Settings</Typography>
          <Stack flexDirection="row" alignItems="center">
            <Button variant="text" disabled={disableCreateNewInstance} onClick={() => setIsInstanceModalVisible(true)}>
              Add survey instance
            </Button>
            <Tooltip
              title={
                <Stack direction="row" spacing={0.5} sx={{ alignItems: "baseline" }}>
                  <InfoOutlined sx={{ position: "relative", top: "2px" }} />
                  <Typography variant="caption">
                    Share with the organization that help is needed in this role.
                  </Typography>
                </Stack>
              }
            >
              <InfoOutlined />
            </Tooltip>
          </Stack>
        </Stack>
        <Stack>
          <Typography sx={{ px: 1, py: 0.5 }}>
            Define how you want to share the survey with your target audience.
          </Typography>
          <ViewList
            dataSource={props.instances}
            activeItemsListProps={{
              activeItemsSortFunction: (a, b) => (a.startDateUtc > b.startDateUtc ? -1 : 1),
            }}
            closedItemsListProps={{
              closedItemsSortFunction: (a, b) => (a.startDateUtc > b.startDateUtc ? -1 : 1),
              closedItemsFilter: (instance: ISurveyInstance) =>
                instance.endDateUtc != null && instance.endDateUtc.plus({ days: 1 }) < DateTime.utc(),
              renderShowClosedButton: (params) => {
                return (
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={params.onClick}
                    sx={{ alignSelf: "flex-start", mx: 1, my: 0.5 }}
                  >
                    {!params.closedItemsVisible
                      ? `Show Past Instances (${params.closedItemCount})`
                      : `Hide Past Instances`}
                  </Button>
                );
              },
            }}
            divider={<Divider variant="dashed" sx={{ mx: 1 }} />}
            renderItem={(instance: ISurveyInstance) => (
              <SurveyInstance
                key={instance.guid}
                surveyGuid={props.surveyGuid}
                instance={instance}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedInstanceGuid(instance.guid);
                  setIsInstanceModalVisible(true);
                }}
                sx={{ px: 1, py: 0.5 }}
                canDelete={instance.endDateUtc == null || DateTime.utc() < instance.endDateUtc.plus({ days: 1 })}
                disabled={instance.endDateUtc != null && DateTime.utc() >= instance.endDateUtc.plus({ days: 1 })}
              />
            )}
          />
        </Stack>
      </Stack>
      <SurveyInstanceModal
        surveyInstances={props.instances}
        visible={isInstanceModalVisible}
        selectedInstanceGuid={selectedInstanceGuid}
        onClose={() => {
          setIsInstanceModalVisible(false);
          setSelectedInstanceGuid(undefined);
        }}
      />
    </>
  );
};

export { SurveyInstancesList };
