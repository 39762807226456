import { Link, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { Links, SystemStatus } from "../../constants";
import { FeedItemDto, FeedItemDueDateValueDto } from "../../http";

interface IProps {
  feedItem: FeedItemDto;
}

const DueDatePropertyChange = (props: IProps) => {
  const changeType = props.feedItem.propertyChange!.changeType;
  const value = props.feedItem.propertyChange!.value as FeedItemDueDateValueDto;
  const oldDueDate = value.oldDueDate != null ? DateTime.fromISO(value.oldDueDate) : null;
  const newDueDate = value.newDueDate != null ? DateTime.fromISO(value.newDueDate) : null;

  if (changeType === SystemStatus.Deleted || (changeType === SystemStatus.Edited && newDueDate == null)) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> removed due date</span>
      </Typography>
    );
  }

  if (
    (changeType === SystemStatus.Created || (changeType === SystemStatus.Edited && oldDueDate == null)) &&
    newDueDate != null
  ) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> added due date </span>
        <strong>{newDueDate.toLocaleString(DateTime.DATE_MED)}</strong>
      </Typography>
    );
  }

  if (changeType === SystemStatus.Edited && oldDueDate != null && newDueDate != null) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> updated due date from {oldDueDate.toLocaleString(DateTime.DATE_MED)} to </span>
        <strong>{newDueDate.toLocaleString(DateTime.DATE_MED)}</strong>
      </Typography>
    );
  }

  return null;
};

export { DueDatePropertyChange };
