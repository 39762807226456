import { Box, Stack, TextField, Typography } from "@mui/material";
import { useNewGetUsers, UserDto } from "../http";
import { Autocomplete } from "./Autocomplete";
import React from "react";

interface IProps {
  placeholder: string;
  value: UserDto | null;
  error?: boolean;
  helperText?: React.ReactNode;
  disableClearable?: boolean;
  disabled?: boolean;
  filterOptions?: (options: UserDto[]) => UserDto[];
  onBlur?: () => void;
  onChange?: (value: UserDto | null) => void;
}

const UserPicker = React.forwardRef((props: IProps, ref: React.Ref<unknown>) => {
  const { data, isLoading } = useNewGetUsers();

  return (
    <Autocomplete
      ref={ref}
      isLoading={isLoading}
      disabled={isLoading || !data || props.disabled}
      options={data?.users ? data.users : []}
      getOptionLabel={(option) => option.displayName}
      filterOptions={props.filterOptions}
      onBlur={props.onBlur}
      onChange={(_, value) => props.onChange?.(value)}
      renderOption={(props, option) => (
        <Box sx={{ pl: "10px !important" }} component="li" {...props} key={option.userId}>
          <Stack>
            <Typography>{option.displayName}</Typography>
            {option.email && <Typography variant="caption">{option.email}</Typography>}
          </Stack>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={props.placeholder}
          inputProps={{ ...params.inputProps }}
          error={props.error}
          helperText={props.helperText}
        />
      )}
      blurOnSelect={false}
      disableClearable={props.disableClearable}
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      value={props.value}
    />
  );
});

export { UserPicker };
