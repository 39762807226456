import { SendRounded } from "@mui/icons-material";
import { Box, IconButton as MuiIconButton, Stack, styled, SxProps } from "@mui/material";
import { useRef, useState } from "react";
import { isWhitespace, TextEditor, TextEditorRef } from "./textEditor";

const IconButton = styled(MuiIconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

interface IProps {
  placeholder?: string;
  disabled?: boolean;
  onSendClicked: (text: string) => void;
  sx?: SxProps;
}

const CommentForm = (props: IProps) => {
  const [text, setText] = useState<string>("");
  const textEditor = useRef<TextEditorRef>(null);

  function handleSendClicked() {
    textEditor.current?.setContent("");
    props.onSendClicked(text);
  }

  return (
    <Box sx={{ pl: { xs: 0.5, sm: 1 }, pr: 0.5, py: 0.75, ...props.sx }}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <TextEditor
          ref={textEditor}
          initialValue={text}
          placeholder={props.placeholder}
          readonly={props.disabled}
          onChange={(value) => setText(value)}
        />
        <Box>
          <IconButton disabled={isWhitespace(text) || props.disabled} onClick={handleSendClicked}>
            <SendRounded />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};

export { CommentForm };
