import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetOneOnOneMeetingDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useGetOneOnOneMeetingDetailRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getOneOnOneMeetingDetailRequest({
    queryKey,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.meetings.getOneOnOneMeetingDetail>
  >): Promise<GetOneOnOneMeetingDto> {
    const accessToken = await getAccessToken();
    const [{ meetingGuid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/meetings/${meetingGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getOneOnOneMeetingDetailRequest };
};

const useGetOneOnOneMeetingDetail = (meetingGuid: string, enabled?: boolean) => {
  const { getOneOnOneMeetingDetailRequest } = useGetOneOnOneMeetingDetailRequest();

  return useQuery({
    queryKey: queryKeys.meetings.getOneOnOneMeetingDetail(meetingGuid),
    queryFn: getOneOnOneMeetingDetailRequest,
    enabled: enabled,
  });
};

export { useGetOneOnOneMeetingDetail };
