import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditIssueNoteDto, FetchError, GetIssuesDto, IssueDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  issueGuid: string;
  teamSlug: string;
  note: string;
};

const useEditIssueNoteRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editIssueNoteRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditIssueNoteDto = {
      note: variables.note,
    };

    const response = await fetch(`${configuration.apiRootUrl}/issues/${variables.issueGuid}/note`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editIssueNoteRequest };
};

const useEditIssueNote = () => {
  const { editIssueNoteRequest } = useEditIssueNoteRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editIssueNoteRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.issues.getIssueDetail(variables.issueGuid));
      await queryClient.cancelQueries(queryKeys.issues.getIssues(variables.teamSlug, undefined));

      const cachedTeamData = queryClient.getQueryData<GetIssuesDto>(
        queryKeys.issues.getIssues(variables.teamSlug, undefined)
      );

      if (cachedTeamData) {
        const optimisticallyUpdatedTeamData: GetIssuesDto = {
          ...cachedTeamData,
          issues: cachedTeamData.issues.map((issue) => {
            if (issue.guid === variables.issueGuid) {
              return {
                ...issue,
                note: variables.note,
              };
            }

            return issue;
          }),
        };

        queryClient.setQueryData(
          queryKeys.issues.getIssues(variables.teamSlug, undefined),
          optimisticallyUpdatedTeamData
        );
      }

      const cachedDetailsData = queryClient.getQueryData<IssueDto>(
        queryKeys.issues.getIssueDetail(variables.issueGuid)
      );

      if (cachedDetailsData) {
        const optimisticallyUpdatedDetailsData: IssueDto = {
          ...cachedDetailsData,
          note: variables.note,
        };

        queryClient.setQueryData(
          queryKeys.issues.getIssueDetail(variables.issueGuid),
          optimisticallyUpdatedDetailsData
        );
      }

      return { cachedTeamData, cachedDetailsData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.issues.getIssues(variables.teamSlug, undefined), context?.cachedTeamData);
      queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.issueGuid), context?.cachedDetailsData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssueDetail(variables.issueGuid));
      queryClient.invalidateQueries(queryKeys.issues.getIssues(variables.teamSlug, undefined));
      queryClient.invalidateQueries(queryKeys.issues.getIssueFeed(variables.issueGuid));
    },
  });
};

export { useEditIssueNote };
