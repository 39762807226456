import { DeleteOutlineRounded, HowToVote, HowToVoteOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Link, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { Links } from "../../constants";
import { useSession } from "../../hooks";
import {
  ProposalDto,
  useDeleteProposal,
  useEditProposal,
  useRemoveProposalUpvote,
  useUpvoteProposal,
} from "../../http";
import { useDestructiveConfirm } from "../../utilities";
import { InlineTextField } from "../InlineTextField";

interface IProps {
  issueGuid: string;
  proposal: ProposalDto;
  isFocused: boolean;
}

const Proposal = (props: IProps) => {
  const user = useSession();
  const confirm = useDestructiveConfirm();
  const { mutate: editProposal } = useEditProposal();
  const { mutate: deleteProposal } = useDeleteProposal();
  const { mutate: upvoteProposal } = useUpvoteProposal();
  const { mutate: removeProposalUpvote } = useRemoveProposalUpvote();
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.isFocused) {
      ref.current?.focus();
    }
  }, [props.isFocused]);

  async function handleDescriptionChanged(proposal: ProposalDto, value: string) {
    editProposal({
      guid: proposal.guid,
      issueGuid: props.issueGuid,
      description: value,
    });
  }

  async function handleDeleteClicked(proposal: ProposalDto) {
    try {
      await confirm({
        title: "Delete Proposal",
        content: "Are you sure you want to delete this proposal?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteProposal({
      guid: proposal.guid,
      issueGuid: props.issueGuid,
    });
  }

  async function handleUpvoteClicked(proposal: ProposalDto) {
    if (proposal.upvotes.some((x) => x.userId === user.userId)) {
      removeProposalUpvote({
        guid: proposal.guid,
        issueGuid: props.issueGuid,
        userId: user.userId,
      });
    } else {
      upvoteProposal({
        guid: proposal.guid,
        issueGuid: props.issueGuid,
        user: { userId: user.userId, displayName: user.displayName, enabled: true, profilePhotoSmallUrl: undefined },
      });
    }
  }

  return (
    <Stack key={props.proposal.guid} direction="row" spacing={0.5} sx={{ width: "100%" }} alignItems="baseline">
      <Box minWidth="130px" maxWidth="130px">
        <Tooltip
          placement="bottom-end"
          title={
            props.proposal.upvotes.length > 0
              ? props.proposal.upvotes
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .map((u, i) => [
                    <Typography key={i} sx={{ display: "inline-block", fontSize: 13, marginRight: "2px" }}>
                      <Link href={Links.User(u.userId)}>{u.displayName}</Link>
                      {i < props.proposal.upvotes.length - 1 && ", "}
                    </Typography>,
                  ])
              : null
          }
        >
          <Button
            onClick={(e) => {
              handleUpvoteClicked(props.proposal);
              e.stopPropagation();
            }}
            variant="tertiary"
            sx={{ minWidth: 0, textDecoration: "none !important" }}
            startIcon={
              props.proposal.upvotes.findIndex((x) => x.userId === user.userId) !== -1 ? (
                <HowToVote />
              ) : (
                <HowToVoteOutlined />
              )
            }
          >
            {`${props.proposal.upvotes.findIndex((x) => x.userId === user.userId) !== -1 ? "Voted" : "Vote"} (${
              props.proposal.upvotes.length
            })`}
          </Button>
        </Tooltip>
      </Box>

      <Stack width="100%" spacing={-0.25}>
        <InlineTextField
          inputRef={ref}
          value={props.proposal.description}
          isEditable={props.proposal.createdByUser.userId === user.userId}
          onBlur={(e) => handleDescriptionChanged(props.proposal, e.target.value)}
          placeholder="Enter proposal..."
          sx={{ flex: 1, alignSelf: "baseline" }}
        />
        <Typography
          variant="caption"
          sx={{ pl: "5px" }}
        >{`proposed by ${props.proposal.createdByUser.displayName}`}</Typography>
      </Stack>

      {props.proposal.createdByUser.userId === user.userId && (
        <IconButton
          size="small"
          sx={{ color: "rgba(0, 0, 0, 0.3)" }}
          onClick={() => handleDeleteClicked(props.proposal)}
        >
          <DeleteOutlineRounded />
        </IconButton>
      )}
    </Stack>
  );
};

export { Proposal };
