import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditOneOnOneMeetingDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  meetingGuid: string;
  newLeadUserId: string;
  externalCallUrl?: string;
};

const useEditOneOnOneMeetingRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editOneOnOneMeetingRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditOneOnOneMeetingDto = {
      newLeadUserId: variables.newLeadUserId,
      externalCallUrl: variables.externalCallUrl,
    };

    const response = await fetch(`${configuration.apiRootUrl}/meetings/one-on-ones/${variables.meetingGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editOneOnOneMeetingRequest };
};

const useEditOneOnOneMeeting = () => {
  const { editOneOnOneMeetingRequest } = useEditOneOnOneMeetingRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editOneOnOneMeetingRequest,
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.meetings.getOneOnOneMeetingsForAllUsers());
      queryClient.invalidateQueries(queryKeys.meetings.getOneOnOneMeetingDetail(variables.meetingGuid));
    },
  });
};

export { useEditOneOnOneMeeting };
