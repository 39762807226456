import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditWikiPageDto, FetchError, GetWikiPageDto, UserDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  title: string;
  slug: string;
  newSlug?: string;
  content: string;
  editors: UserDto[];
};

const useEditWikiPageRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editWikiPageRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditWikiPageDto = {
      title: variables.title,
      slug: variables.slug,
      newSlug: variables.newSlug,
      content: variables.content,
      editorUserIds: variables.editors.map((user) => user.userId),
    };

    const response = await fetch(`${configuration.apiRootUrl}/wiki/${variables.slug}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editWikiPageRequest };
};

const useEditWikiPage = () => {
  const { editWikiPageRequest } = useEditWikiPageRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editWikiPageRequest,
    onMutate: async (variables) => {
      const cachedData = queryClient.getQueryData<GetWikiPageDto>(queryKeys.wiki.getWikiPage(variables.slug));

      if (cachedData) {
        const optimisticallyUpdatedData: GetWikiPageDto = {
          ...cachedData,
          title: variables.title,
          content: variables.content,
          editors: variables.editors,
        };

        queryClient.setQueryData(queryKeys.wiki.getWikiPage(variables.slug), optimisticallyUpdatedData);
      }

      return { cachedData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.wiki.getWikiPage(variables.slug), context?.cachedData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.wiki.getWikiPage(variables.slug));
    },
  });
};

export { useEditWikiPage };
