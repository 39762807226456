import { Card, CardHeader, Divider, Typography } from "@mui/material";
import { RolesList } from "../../../../components";
import { UserDto } from "../../../../http";

interface IProps {
  user: UserDto;
}

const RolesReview = (props: IProps) => {
  return (
    <Card>
      <CardHeader title={<Typography variant="h6">Roles Review</Typography>} />
      <Divider />
      <RolesList userId={props.user.userId} />
    </Card>
  );
};

export { RolesReview };
