import { CalendarMonthOutlined, PersonOutlineRounded } from "@mui/icons-material";
import { Divider, Link, Skeleton, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { ItemProperty, Typography } from "..";
import { Links, SupportingItemType } from "../../constants";
import { IssueDto } from "../../http";
import { ITeamSummary } from "../../models";
import { NoteProperty } from "../itemProperties";
import { IssueTeamProperty } from "./IssueTeamProperty";

interface IProps {
  issue: IssueDto | undefined;
  isLoading?: boolean;
  onTeamChanged?: (team: ITeamSummary) => void;
}

const IssueProperties = (props: IProps) => {
  return (
    <>
      {props.isLoading && (
        <Stack spacing={0.5} sx={{ px: 1, py: 0.5 }} divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}>
          <Skeleton width={"80%"} />
          <Skeleton width={"80%"} />
          <Skeleton width={"80%"} />
        </Stack>
      )}

      {!props.isLoading && props.issue && (
        <Stack spacing={0.5} sx={{ px: 1, py: 0.5 }} divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}>
          <IssueTeamProperty issue={props.issue} onTeamChanged={props.onTeamChanged} />

          <ItemProperty labelIcon={<PersonOutlineRounded />} label="Creator">
            <Link href={Links.User(props.issue.createdByUser.userId)}>{props.issue.createdByUser.displayName}</Link>
          </ItemProperty>

          <ItemProperty labelIcon={<CalendarMonthOutlined />} label="Created On">
            <Typography noWrap>
              {DateTime.fromISO(props.issue.createdOnDateTimeUtc, { zone: "utc" })
                .toLocal()
                .toLocaleString(DateTime.DATE_MED)}
            </Typography>
          </ItemProperty>

          <NoteProperty
            teamSlug={props.issue.assignedTeam.slug}
            itemGuid={props.issue.guid}
            itemType={SupportingItemType.Issue}
            note={props.issue.note}
            userCanEdit={props.issue.userCanEdit}
          />
        </Stack>
      )}
    </>
  );
};

export { IssueProperties };
