import React from "react";

interface ISignalRPlansContext {
  sendRefetchPlan: (planGuid: string) => void;
  onShouldRefetchPlan: (handler: (planGuid: string) => void) => void;
  offShouldRefetchPlan: (handler: (planGuid: string) => void) => void;
}

const SignalRPlansContext = React.createContext<ISignalRPlansContext>({
  sendRefetchPlan: () => null,
  onShouldRefetchPlan: () => null,
  offShouldRefetchPlan: () => null,
});

export { SignalRPlansContext };
