import { AlarmRounded } from "@mui/icons-material";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";

interface IProps {
  dueDate: string;
}

const ActionItemDueDateIcon = (props: IProps) => {
  const theme = useTheme();
  const dueDate = DateTime.fromISO(props.dueDate);

  return (
    <Tooltip title="Due Date">
      <Stack direction="row" spacing={0.1} sx={{ alignItems: "center" }}>
        <AlarmRounded sx={{ color: theme.palette.grey[300] }} />
        <Typography variant="caption" sx={{ color: dueDate < DateTime.now() ? theme.palette.error.main : undefined }}>
          {dueDate.toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export { ActionItemDueDateIcon };
