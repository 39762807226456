import { StickyNote2Outlined } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { SupportingItemType } from "../../constants";
import { useSession } from "../../hooks";
import { useEditActionItemNote } from "../../http";
import { useEditIssueNote } from "../../http/";
import { ItemProperty } from "../ItemProperty";
import { TextEditor, TextEditorRef, TextViewer } from "../textEditor";

interface IProps {
  teamSlug: string;
  itemGuid: string;
  itemType: SupportingItemType;
  note: string | undefined;
  userCanEdit: boolean;
}

const NoteProperty = (props: IProps) => {
  const { userId } = useSession();
  const [isEditing, setIsEditing] = useState(false);
  const { mutate: editActionItemNote, isLoading: isSavingActionItemNote } = useEditActionItemNote();
  const { mutate: editIssueNote, isLoading: isSavingIssueNote } = useEditIssueNote();
  const [note, setNote] = useState(props.note ?? "");
  const editorRef = useRef<TextEditorRef | null>(null);
  const isLoading = isSavingActionItemNote || isSavingIssueNote;

  useEffect(() => {
    if (isEditing) {
      editorRef.current?.focus();
    }
  }, [isEditing]);

  function handleCancelClicked() {
    setIsEditing(false);
  }

  function handleSaveClicked() {
    switch (props.itemType) {
      case SupportingItemType.ActionItem: {
        editActionItemNote({
          guid: props.itemGuid,
          modifiedByUserId: userId,
          note,
        });
        break;
      }
      case SupportingItemType.Issue: {
        editIssueNote({
          issueGuid: props.itemGuid,
          teamSlug: props.teamSlug,
          note,
        });
        break;
      }
    }

    setIsEditing(false);
  }

  return (
    <ItemProperty
      labelIcon={<StickyNote2Outlined />}
      label="Notes"
      labelProps={{ sx: { alignSelf: "start", top: 0 } }}
      canEdit={true}
      isEditing={isEditing}
      onEditClicked={() => setIsEditing(true)}
    >
      <Box sx={{ display: "flex" }}>
        {isEditing ? (
          <Stack spacing={0.5} sx={{ flex: 1 }}>
            <TextEditor ref={editorRef} initialValue={props.note ?? ""} onChange={(value) => setNote(value)} />
            <Stack direction="row" spacing={0.5} sx={{ justifyContent: "flex-end" }}>
              <Button variant="text" disabled={isLoading} onClick={handleCancelClicked}>
                Cancel
              </Button>
              <Button variant="tertiary" disabled={isLoading} onClick={handleSaveClicked}>
                Save
              </Button>
            </Stack>
          </Stack>
        ) : (
          <TextViewer placeholder={props.userCanEdit ? "Enter details..." : "No notes"} value={props.note ?? ""} />
        )}
      </Box>
    </ItemProperty>
  );
};

export { NoteProperty };
