const Links = {
  User: (userId: string) => `/people/${userId}`,
  UserMeetings: (userId: string) => `/people/${userId}/meetings`,
  UserMap: (userId: string) => `/people/${userId}/map`,
  UserSettings: () => `/settings`,
  Meeting: (meetingGuid: string) => `/meetings/${meetingGuid}`,
  Role: (guid: string) => `/roles/${guid}`,
  Team: (slug: string) => `/teams/${slug}`,
  TeamStrategicMap: (slug: string) => `/teams/${slug}/strategic-map`,
  TeamImplementation: (slug: string) => `/teams/${slug}/implementation`,
  TeamMetrics: (slug: string) => `/teams/${slug}/metrics`,
  TeamResources: (slug: string) => `/teams/${slug}/resources`,
  TeamSettings: (slug: string) => `/teams/${slug}/settings`,
  ActionItemDetail: (guid: string) => `/cards/${guid}`,
  IssueDetail: (guid: string) => `/issues/${guid}`,
  MetricDetail: (guid: string) => `/metrics/${guid}`,
  RoleDetail: (guid: string) => `/roles/${guid}`,
  Surveys: () => `/surveys`,
  Survey: (guid: string) => `/surveys/${guid}`,
  SurveyResponses: (slug: string) => `/surveys/${slug}/responses`,
  SurveyActions: (slug: string) => `/surveys/${slug}/actions`,
  SurveyResponse: (slug: string) => `/surveys/${slug}/response`,
  Search: (query: string, filter?: string) => {
    let urlSearchParams = new URLSearchParams({ query: query });

    if (filter != null) {
      urlSearchParams.append("filter", filter);
    }

    return `/search?${urlSearchParams.toString()}`;
  },
};

export { Links };
