import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditActionItemDueDateDto, FetchError, GetActionItemDto, GetActionItemSummaryDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { useSignalR } from "../../../hooks";

type Variables = {
  guid: string;
  dueDate: string | null;
};

const useEditActionItemDueDateRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editActionItemDueDateRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditActionItemDueDateDto = {
      dueDate: variables.dueDate,
    };

    const response = await fetch(`${configuration.apiRootUrl}/action-items/${variables.guid}/due-date`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editActionItemDueDateRequest };
};

const useEditActionItemDueDate = () => {
  const { editActionItemDueDateRequest } = useEditActionItemDueDateRequest();
  const queryClient = useQueryClient();
  const signalR = useSignalR();

  return useMutation({
    mutationFn: editActionItemDueDateRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
      await queryClient.cancelQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));

      // Snapshot the current cache value
      const cachedActionItemSummaryData = queryClient.getQueryData<GetActionItemSummaryDto>(
        queryKeys.actionItems.getActionItemSummary(variables.guid)
      );
      const cachedActionItemDetailsData = queryClient.getQueryData<GetActionItemDto>(
        queryKeys.actionItems.getActionItemDetail(variables.guid)
      );

      if (cachedActionItemSummaryData) {
        const optimisticallyUpdatedActionItemSummaryData: GetActionItemSummaryDto = {
          ...cachedActionItemSummaryData,
          dueDate: variables.dueDate,
        };

        queryClient.setQueryData(
          queryKeys.actionItems.getActionItemSummary(variables.guid),
          optimisticallyUpdatedActionItemSummaryData
        );
      }

      if (cachedActionItemDetailsData) {
        const optimisticallyUpdatedActionItemDetailsData: GetActionItemDto = {
          ...cachedActionItemDetailsData,
          dueDate: variables.dueDate,
        };

        queryClient.setQueryData(
          queryKeys.actionItems.getActionItemDetail(variables.guid),
          optimisticallyUpdatedActionItemDetailsData
        );
      }

      return { cachedActionItemSummaryData, cachedActionItemDetailsData };
    },
    onSuccess: (_data, variables) => {
      signalR.sendRefetchActionItem(variables.guid);
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(
        queryKeys.actionItems.getActionItemSummary(variables.guid),
        context?.cachedActionItemSummaryData
      );
      queryClient.setQueryData(
        queryKeys.actionItems.getActionItemDetail(variables.guid),
        context?.cachedActionItemDetailsData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(variables.guid));
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemDetail(variables.guid));
      queryClient.invalidateQueries(queryKeys.actionItems.getActionItemFeed(variables.guid));
    },
  });
};

export { useEditActionItemDueDate };
