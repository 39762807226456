import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { HeaderLayout, MeetingHeader } from "../../../components";
import { MeetingAgendaItemType } from "../../../constants";
import { useSession } from "../../../hooks";
import { GetTeamMeetingDto, MeetingInstanceDto } from "../../../http";

const MeetingBorder = styled(Box)`
  height: 100vh;
  border: solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-width: 5px;
`;

let meeting: GetTeamMeetingDto = {
  guid: "",
  title: "Implementation Weekly",
  description: "Talk about implementation",
  administrators: [],
  participantList: [],
  agendaItems: [
    {
      name: "Check In",
      description: "Talk about stuff.",
      type: MeetingAgendaItemType.CheckIn,
      allocatedTimeInMinutes: 5,
    },
    {
      name: "Strategic Map Review",
      description: "Talk about maps.",
      type: MeetingAgendaItemType.TeamMapReview,
      allocatedTimeInMinutes: 20,
    },
  ],
};

const TeamMeetingPage = () => {
  const user = useSession();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function handleLeaveMeeting() {
    window.close();
  }

  //TODO: Signal R for Meeting Start/End, User Join/Leave, Meeting Settings Changed.

  let currentDateTime = DateTime.now().toUTC().toISO();

  let meetingInstance: MeetingInstanceDto = {
    guid: "",
    startedByUser: {
      userId: user.userId,
      displayName: user.displayName,
      profilePhotoSmallUrl: user.profilePhotoSmallUrl,
      enabled: true,
    },
    startedAtDateTimeUtc: currentDateTime,
    endedAtDateTimeUtc: null,
    activeAgendaStepIndex: 0,
  };

  return (
    <MeetingBorder>
      <HeaderLayout
        title={
          <MeetingHeader
            title={meeting.title}
            meetingInstance={meetingInstance}
            isMeetingEnding={false}
            onLeaveMeetingClicked={handleLeaveMeeting}
            onCloseMeetingClicked={handleLeaveMeeting}
          />
        }
      >
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Card sx={{ flex: 1 }}>
              <CardHeader title={<Typography variant="h6">Agenda</Typography>} />
              <Divider />
              <Stepper orientation="vertical" activeStep={activeStep} sx={{ p: 1 }}>
                {meeting.agendaItems.map((item, index) => (
                  <Step key={item.name}>
                    <StepLabel>
                      <Stack direction="row" spacing={0.5}>
                        <Typography variant="body2" onClick={() => setActiveStep(index)} sx={{ cursor: "pointer" }}>
                          {item.name}
                        </Typography>
                        <Typography>{`${item.allocatedTimeInMinutes}min`}</Typography>
                      </Stack>
                    </StepLabel>
                    <StepContent>
                      <Stack spacing={0.5}>
                        <Typography>{item.description}</Typography>
                        <Stack direction="row" spacing={1}>
                          <Button variant="contained" onClick={handleNext}>
                            {index === meeting.agendaItems.length - 1 ? "Finish" : "Continue"}
                          </Button>
                          <Button variant="outlined" disabled={index === 0} onClick={handleBack}>
                            Back
                          </Button>
                        </Stack>
                      </Stack>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Card>
            <Card sx={{ flex: 2 }}>
              <CardHeader title={<Typography variant="h6">{meeting.agendaItems[activeStep].name}</Typography>} />
              <Divider />
            </Card>
          </Stack>
        </Stack>
      </HeaderLayout>
    </MeetingBorder>
  );
};

export { TeamMeetingPage };
