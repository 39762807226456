import { FilterListRounded } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { HeaderLayout, TertiarySelect } from "../../components";
import { Links, SearchFilter } from "../../constants";
import { useSettings } from "../../hooks";
import { useGetOpenRoles, useSearch } from "../../http";
import { setTitlePrefix } from "../../utilities";
import { OpenRole, SearchResult } from "./components";

const DEBOUNCE_TIMEOUT_MS = 500;

const SearchPage = () => {
  setTitlePrefix("Search");

  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<SearchFilter>(SearchFilter.All);
  const isSearchEnabled = searchQuery !== "";
  const { data: searchResults, isLoading: isSearching } = useSearch(searchQuery, searchFilter, isSearchEnabled);
  const { data: openRoles, isLoading: isLoadingOpenRoles } = useGetOpenRoles();
  const { searchSettings, updateSearchSettings } = useSettings();
  const theme = useTheme();
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 200);
  }, []);

  useEffect(() => {
    const query = searchParams.get("query") ?? "";
    const filter = searchParams.get("filter") ?? "";

    if (Object.values(SearchFilter).find((x) => x === filter)) {
      setSearchFilter(filter as SearchFilter);
    } else {
      setSearchFilter(SearchFilter.All);
    }

    setSearchQuery(query);
    setInputValue(query);
  }, [searchParams]);

  useEffect(() => {
    if (searchResults && !isSearching) {
      appendToSearchHistory(inputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  function handleQueryChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setInputValue(e.target.value);
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      setSearchParams(
        (searchParams) => {
          searchParams.set("query", e.target.value);
          return searchParams;
        },
        { replace: true }
      );
    }, DEBOUNCE_TIMEOUT_MS);
  }

  function handleFilterChanged(e: SelectChangeEvent<unknown>) {
    const filter = e.target.value as string;

    setSearchParams(
      (searchParams) => {
        searchParams.set("filter", filter.toLowerCase());
        return searchParams;
      },
      { replace: true }
    );
  }

  function appendToSearchHistory(query: string) {
    let searchHistory = [...searchSettings.searchHistory];

    // If a query is already in the search history, remove the older query
    if (searchHistory.includes(query)) {
      searchHistory = searchHistory.filter((x) => x !== query);
    }

    // Make sure search history doesn't exceed 5 queries
    if (searchHistory.length >= 5) {
      searchHistory = searchHistory.slice(0, 5);
    }

    updateSearchSettings({
      ...searchSettings,
      searchHistory: [query, ...searchHistory],
    });
  }

  return (
    <HeaderLayout
      title={
        <Grid container alignItems="center">
          <Grid item xs={12} md={3}>
            <Typography variant="h3">Search</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={0.5}>
              <TextField
                inputRef={inputRef}
                placeholder="Search teams, roles, and people..."
                onFocus={(e) => inputRef.current?.setSelectionRange(0, e.target.value.length)}
                onChange={handleQueryChanged}
                fullWidth
                value={inputValue}
              />
              <TertiarySelect
                value={searchFilter}
                renderValue={(value) => (
                  <Stack direction="row" spacing={0.25} alignItems="center">
                    <FilterListRounded fontSize="large" />
                    <Typography variant="button">{value as SearchFilter}</Typography>
                  </Stack>
                )}
                onChange={handleFilterChanged}
              >
                <MenuItem value={SearchFilter.All}>All</MenuItem>
                <MenuItem value={SearchFilter.People}>People</MenuItem>
                <MenuItem value={SearchFilter.Roles}>Roles</MenuItem>
                <MenuItem value={SearchFilter.Teams}>Teams</MenuItem>
              </TertiarySelect>
            </Stack>
          </Grid>
        </Grid>
      }
    >
      <Grid container>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={6}>
          {isSearchEnabled && isSearching && (
            <Stack spacing={1}>
              {[...Array(3)].map((_, i) => (
                <Card key={i}>
                  <CardContent>
                    <Skeleton variant="rectangular" height={20} width={75} sx={{ mb: 0.5 }} />
                    <Skeleton variant="rectangular" height={28} width={200} sx={{ mb: 0.25 }} />
                    <Skeleton variant="rectangular" height={24} width={150} />
                  </CardContent>
                </Card>
              ))}
            </Stack>
          )}

          {!isSearchEnabled && (
            <Stack spacing={1}>
              <Stack spacing={0.5}>
                <Typography variant="overline">Recent Searches</Typography>
                <Box>
                  {searchSettings.searchHistory.length === 0 && <Typography>No recent search history.</Typography>}
                  {searchSettings.searchHistory.length > 0 && (
                    <Stack>
                      {searchSettings.searchHistory.map((query, i) => (
                        <Link key={i} href={Links.Search(query)}>
                          {query}
                        </Link>
                      ))}
                    </Stack>
                  )}
                </Box>
              </Stack>

              <Box>
                <Typography variant="overline">
                  Open Roles {openRoles != null && openRoles.length > 0 && <>({openRoles.length})</>}
                </Typography>
                <Typography sx={{ mt: 0.5 }}>
                  Find out more about roles that have been recently flagged as open.
                </Typography>
              </Box>

              {isLoadingOpenRoles ? (
                <Stack spacing={1}>
                  {[...Array(2)].map((_, i) => (
                    <Card key={i}>
                      <CardContent>
                        <Stack spacing={0.5}>
                          <Skeleton variant="rectangular" height={20} width={46} />
                          <Skeleton variant="rectangular" height={28} />
                          <Skeleton variant="rectangular" height={24} />
                        </Stack>
                      </CardContent>
                    </Card>
                  ))}
                </Stack>
              ) : (
                <>
                  {openRoles?.length === 0 && <Typography>No open roles at this time.</Typography>}

                  {openRoles?.map((openRole, i) => (
                    <OpenRole key={i} role={openRole} />
                  ))}
                </>
              )}
            </Stack>
          )}

          {!isSearching && searchResults && (
            <>
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.grey[500], fontSize: "0.875rem", fontWeight: "bold" }}
                >
                  {searchResults.length === 0 ? (
                    <>
                      No search results for <span style={{ fontStyle: "italic" }}>"{searchQuery}"</span>
                    </>
                  ) : (
                    <>
                      {searchResults.length} search results for{" "}
                      <span style={{ fontStyle: "italic", fontWeight: "normal" }}>"{searchQuery}"</span>
                    </>
                  )}
                </Typography>
              </Box>

              {searchResults.length > 0 && (
                <Stack spacing={1}>
                  {searchResults.map((searchResult) => (
                    <SearchResult key={searchResult.id} searchResult={searchResult} />
                  ))}
                </Stack>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </HeaderLayout>
  );
};

export { SearchPage };
