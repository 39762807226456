import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useConfirm } from "material-ui-confirm";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  AddIssueOrTaskButton,
  HeaderLayout,
  MeetingHeader,
  SignalRGroup,
  TextViewer,
  useDrawer,
} from "../../../components";
import { Links } from "../../../constants";
import { useSession, useSignalR } from "../../../hooks";
import {
  queryKeys,
  useEditMeetingAgendaStep,
  useEndMeeting,
  useGetMeetingParticipants,
  useGetOneOnOneMeetingDetail,
  useJoinMeeting,
} from "../../../http";
import { MeetingAgendaStepContent, MeetingNotesDrawerContent } from "./components";

const StickySideMenuCard = styled(Card)`
  position: sticky;
  top: 176px;
  overflow: visible;
  z-index: 2;
  min-width: 400px;
  min-height: 0;
  height: fit-content;
`;

interface UrlParams {
  meetingGuid: string;
}

const OneOnOneMeetingPage = () => {
  const { meetingGuid } = useParams<keyof UrlParams>() as UrlParams;
  const sessionUser = useSession();
  const { mutate: joinMeeting, isLoading: isJoiningMeeting } = useJoinMeeting();
  const [shouldFetchMeetingData, setShouldFetchMeetingData] = useState(false);
  const { data: meeting, isLoading: isLoadingMeeting } = useGetOneOnOneMeetingDetail(
    meetingGuid,
    shouldFetchMeetingData
  );
  const { data: currentParticipants } = useGetMeetingParticipants(meetingGuid, shouldFetchMeetingData);
  const { mutate: endMeeting, isLoading: isMeetingEnding } = useEndMeeting();
  const { mutate: editAgendaStep } = useEditMeetingAgendaStep();
  const theme = useTheme();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const signalR = useSignalR();
  const queryClient = useQueryClient();

  useEffect(() => {
    joinMeeting(
      { meetingGuid: meetingGuid },
      {
        onSuccess: () => {
          setShouldFetchMeetingData(true);
          signalR.sendRefetchMeetingListItem(meetingGuid);
          signalR.sendRefetchMeetingParticipants(meetingGuid);
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingGuid]);

  useEffect(() => {
    signalR.joinGroup(SignalRGroup.Meeting(meetingGuid));
    return () => signalR.leaveGroup(SignalRGroup.Meeting(meetingGuid));
  }, [meetingGuid, signalR]);

  useEffect(() => {
    const handleShouldRefetchParticipants = (meetingGuid: string) => {
      queryClient.invalidateQueries(queryKeys.meetings.getMeetingParticipants(meetingGuid));
    };

    signalR.onShouldRefetchMeetingParticipants(handleShouldRefetchParticipants);
    return () => signalR.offShouldRefetchMeetingParticipants(handleShouldRefetchParticipants);
  }, [queryClient, signalR]);

  useEffect(() => {
    const handleShouldEndMeeting = () => {
      navigate(Links.UserMeetings(sessionUser.userId));
    };

    signalR.onShouldEndMeeting(handleShouldEndMeeting);
    return () => signalR.offShouldEndMeeting(handleShouldEndMeeting);
  }, [navigate, sessionUser.userId, signalR]);

  useEffect(() => {
    signalR.joinGroup(SignalRGroup.Meeting(meetingGuid));
    return () => signalR.leaveGroup(SignalRGroup.Meeting(meetingGuid));
  }, [meetingGuid, signalR]);

  useEffect(() => {
    const handleShouldRefetchParticipants = (meetingGuid: string) => {
      queryClient.invalidateQueries(queryKeys.meetings.getMeetingParticipants(meetingGuid));
    };

    signalR.onShouldRefetchMeetingParticipants(handleShouldRefetchParticipants);
    return () => signalR.offShouldRefetchMeetingParticipants(handleShouldRefetchParticipants);
  }, [queryClient, signalR]);

  useEffect(() => {
    const handleShouldEndMeeting = () => {
      navigate(Links.UserMeetings(sessionUser.userId));
    };

    signalR.onShouldEndMeeting(handleShouldEndMeeting);
    return () => signalR.offShouldEndMeeting(handleShouldEndMeeting);
  }, [navigate, sessionUser.userId, signalR]);

  function handleActiveAgendaStepChange(newAgendaStepIndex: number) {
    if (meeting && newAgendaStepIndex >= 0 && newAgendaStepIndex < meeting.agendaItems.length) {
      editAgendaStep({ meetingGuid: meetingGuid, agendaStepIndex: newAgendaStepIndex });
    }
  }

  async function handleCloseMeetingForAll() {
    try {
      await confirm({
        title: "Close 1:1 Meeting",
        content: "Are you sure you want to close the meeting for all participants?",
        confirmationText: "OK",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    endMeeting(
      { meetingGuid: meetingGuid },
      {
        onSuccess: () => {
          signalR.sendRefetchMeetingListItem(meetingGuid);
          signalR.sendEndMeeting(meetingGuid);
          navigate(Links.UserMeetings(sessionUser.userId));
        },
      }
    );
  }

  const isLoading = isJoiningMeeting || isLoadingMeeting;

  return (
    <HeaderLayout
      title={
        isLoading || meeting == null || meeting.currentInstance == null ? (
          <Stack spacing={1}>
            <Skeleton variant="rectangular" height={40} width={400} />
            <Skeleton variant="rectangular" height={24} width={500} />
          </Stack>
        ) : (
          <MeetingHeader
            title={`1:1 ${meeting.otherUser.displayName}, ${meeting.leadUser.displayName}`}
            meetingInstance={meeting.currentInstance}
            participants={currentParticipants?.participants}
            isMeetingEnding={isMeetingEnding}
            externalCallUrl={meeting.externalCallUrl}
            onCloseMeetingClicked={handleCloseMeetingForAll}
          />
        )
      }
      actions={
        !isLoading && (
          <Stack direction="row" spacing={{ xs: 0.5, sm: 1 }}>
            <AddIssueOrTaskButton variant="contained" />
          </Stack>
        )
      }
    >
      <Stack spacing={1}>
        <Stack direction="row" spacing={1}>
          {isLoading && !meeting && (
            <>
              <Card sx={{ flex: 1 }}>
                <CardHeader title={<Skeleton height={28} width={200} />} />
                <Divider />
                <Stack sx={{ p: 1 }}>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Stack>
              </Card>
              <Box sx={{ flex: 2 }}></Box>
            </>
          )}

          {!isLoading && meeting && meeting.currentInstance && (
            <>
              <StickySideMenuCard sx={{ flex: 1 }}>
                <CardHeader
                  title={
                    <Stack direction="row" spacing={1} sx={{ justifyContent: "space-between" }}>
                      <Typography variant="h6">Agenda</Typography>
                      <MeetingNotesButton meetingGuid={meetingGuid} />
                    </Stack>
                  }
                  sx={{ pr: 0.5 }}
                />
                <Divider sx={{ borderColor: theme.palette.grey[200] }} />
                <Stepper
                  orientation="vertical"
                  activeStep={meeting.currentInstance!.activeAgendaStepIndex}
                  sx={{ p: 1 }}
                >
                  {meeting.agendaItems.map((item, index) => (
                    <Step key={item.name}>
                      <StepLabel>
                        <Stack direction="row" spacing={0.5}>
                          <Typography
                            variant="body2"
                            onClick={() => handleActiveAgendaStepChange(index)}
                            sx={{
                              color:
                                index === meeting.currentInstance!.activeAgendaStepIndex
                                  ? theme.palette.primary.main
                                  : undefined,
                              cursor: "pointer",
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Stack>
                      </StepLabel>
                      <StepContent>
                        <Stack spacing={0.5}>
                          <TextViewer value={`<p>${item.description}</p>`} />
                          <Stack direction="row" spacing={0.5}>
                            {index < meeting.agendaItems.length - 1 && (
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleActiveAgendaStepChange(meeting.currentInstance!.activeAgendaStepIndex + 1)
                                }
                              >
                                Next Step
                              </Button>
                            )}

                            {index > 0 && (
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  handleActiveAgendaStepChange(meeting.currentInstance!.activeAgendaStepIndex - 1)
                                }
                              >
                                Back
                              </Button>
                            )}
                          </Stack>
                        </Stack>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </StickySideMenuCard>

              <Box sx={{ flex: 3, minWidth: 0 }}>
                <MeetingAgendaStepContent
                  agendaStep={meeting.agendaItems[meeting.currentInstance!.activeAgendaStepIndex]}
                  user={meeting.otherUser}
                />
              </Box>
            </>
          )}
        </Stack>
      </Stack>
    </HeaderLayout>
  );
};

const MeetingNotesButton = (props: { meetingGuid: string }) => {
  const sessionUser = useSession();
  const { showDrawer, closeDrawer, isOpen: drawerIsOpen } = useDrawer();

  function handleToggleMeetingNotesDrawer() {
    if (drawerIsOpen) {
      closeDrawer();
    } else {
      showDrawer({
        title: (
          <Stack>
            <Typography variant="h6">My Meeting Notes</Typography>
            <Typography variant="caption">Meeting notes are accessible only to {sessionUser.displayName}.</Typography>
          </Stack>
        ),
        content: <MeetingNotesDrawerContent meetingGuid={props.meetingGuid} />,
      });
    }
  }

  return (
    <Button variant="tertiary" onClick={handleToggleMeetingNotesDrawer}>
      {drawerIsOpen ? "Hide Meeting Notes" : "Show Meeting Notes"}
    </Button>
  );
};

export { OneOnOneMeetingPage };
