import { DeleteOutlineRounded } from "@mui/icons-material";
import { Button, Chip, Divider, Skeleton, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ContextProperty, IssueFeed, ItemDescriptionField, LinkBehavior, Proposals } from "../../components";
import { IssueActions } from "../../components/issues";
import { IssueProperties } from "../../components/issues/IssueProperties";
import { Links, SupportingItemType } from "../../constants";
import { useSession, useSignalR } from "../../hooks";
import {
  useDeleteIssue,
  useEditIssue,
  useEditIssueStatus,
  useGetIssueDetail,
  useRemoveIssueUpvote,
  useUpvoteIssue,
} from "../../http";
import { setTitlePrefix, useDestructiveConfirm } from "../../utilities";
import { DetailPageLayout } from "./DetailPageLayout";

interface UrlParams {
  issueGuid: string;
}

const IssueDetailsPage = () => {
  const user = useSession();
  const { issueGuid } = useParams<keyof UrlParams>() as UrlParams;
  const { isLoading, data: issue } = useGetIssueDetail(issueGuid);
  const { mutate: deleteIssue } = useDeleteIssue();
  const { mutate: editIssue } = useEditIssue();
  const { mutate: editIssueStatus } = useEditIssueStatus();
  const { mutate: upvoteIssue } = useUpvoteIssue();
  const { mutate: removeIssueUpvote } = useRemoveIssueUpvote();
  const { enqueueSnackbar } = useSnackbar();
  const [focusedProposalGuid, setFocusedProposalGuid] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const confirm = useDestructiveConfirm();
  const signalR = useSignalR();

  useEffect(() => {
    return () => setFocusedProposalGuid(undefined);
  });

  useEffect(() => {
    if (issue?.assignedTeam.name) {
      setTitlePrefix(`${issue?.assignedTeam.name} Issue`);
    } else {
      setTitlePrefix();
    }
  }, [issue?.assignedTeam.name]);

  async function handleDescriptionChanged(value: string) {
    if (issue === undefined) {
      return;
    }

    editIssue({
      guid: issue.guid,
      oldTeamSlug: issue.assignedTeam.slug,
      newTeamSlug: issue.assignedTeam.slug,
      newTeamName: issue.assignedTeam.name,
      description: value,
      createdByUserId: issue.createdByUser.userId,
      modifiedByUserId: user.userId,
    });
  }

  async function handleCloseClicked() {
    if (issue === undefined) {
      return;
    }

    if (issue.userCanEdit) {
      editIssueStatus({
        guid: issue.guid,
        closed: issue.closedOnDateTimeUtc === null,
        modifiedByUserId: user.userId,
        teamSlug: issue.assignedTeam.slug,
        createdByUserId: issue.createdByUser.userId,
      });
    }
  }

  async function handleUpvoteClicked() {
    if (issue === undefined) {
      return;
    }

    if (issue.upvotes.findIndex((x) => x.userId === user.userId) > -1) {
      removeIssueUpvote({
        guid: issue.guid,
        userId: user.userId,
        teamSlug: issue.assignedTeam.slug,
        createdByUserId: issue.createdByUser.userId,
      });
    } else {
      upvoteIssue({
        guid: issue.guid,
        user: {
          userId: user.userId,
          displayName: user.displayName,
          enabled: true,
          profilePhotoSmallUrl: user.profilePhotoSmallUrl,
        },
        teamSlug: issue.assignedTeam.slug,
        createdByUserId: issue.createdByUser.userId,
      });
    }
  }

  async function handleDeleteClicked(e: React.MouseEvent) {
    if (issue === undefined) {
      return;
    }

    try {
      await confirm({
        title: "Delete Issue",
        content: "Are you sure you want to delete this issue?",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteIssue(
      {
        guid: issueGuid,
        modifiedByUserId: user.userId,
        teamSlug: issue.assignedTeam.slug,
        createdByUserId: issue.createdByUser.userId,
      },
      {
        onSuccess: () => {
          navigate(-1);
          enqueueSnackbar(`Issue has been deleted`);
        },
      }
    );
  }

  return (
    <DetailPageLayout
      actions={
        issue?.userCanEdit && (
          <Button variant="outlined" color="error" startIcon={<DeleteOutlineRounded />} onClick={handleDeleteClicked}>
            Delete Issue
          </Button>
        )
      }
    >
      <Stack divider={<Divider />}>
        {isLoading && <Skeleton sx={{ mx: 1, mt: 0.5 }} width={"10%"} />}

        {!isLoading && issue && (
          <Stack direction="row" gap={0.5} flexWrap="wrap" sx={{ p: 1 }}>
            <Chip
              color="primary"
              component={LinkBehavior}
              href={Links.TeamImplementation(issue.assignedTeam.slug)}
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
              label={issue.assignedTeam.name}
              clickable
            />
            <Chip label="Issue" />
          </Stack>
        )}

        <Stack sx={{ px: 1 }} divider={<Divider variant="dashed" />}>
          {!isLoading && (issue?.context || issue?.userCanEdit) && (
            <ContextProperty
              itemGuid={issue.guid}
              itemType={SupportingItemType.Issue}
              sx={{ py: 0.25 }}
              context={issue.context}
              isEditable={issue.userCanEdit}
              onUpdated={() => signalR.sendRefetchTeamIssues(issue.assignedTeam.slug)}
            />
          )}

          <ItemDescriptionField
            isLoading={isLoading}
            isEditable={issue?.userCanEdit ?? false}
            value={issue?.description}
            onChange={handleDescriptionChanged}
            variant="h4"
            sx={{ py: 0.5 }}
          />

          <IssueActions
            isLoading={isLoading}
            issue={issue}
            onProposalCreated={(proposalGuid) => setFocusedProposalGuid(proposalGuid)}
            onToggleClosed={handleCloseClicked}
            onUpvoteClicked={handleUpvoteClicked}
            sx={{ py: 0.5 }}
          />

          <Proposals
            isLoading={isLoading}
            issueGuid={issueGuid}
            proposals={issue?.proposals}
            focusedProposalGuid={focusedProposalGuid}
            sx={{ py: 0.5 }}
          />
        </Stack>

        <IssueProperties issue={issue} isLoading={isLoading} />
        <IssueFeed issueGuid={issueGuid} />
      </Stack>
    </DetailPageLayout>
  );
};

export { IssueDetailsPage };
