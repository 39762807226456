import { Box, CardContent, Chip, Stack, Typography, styled, useTheme } from "@mui/material";
import React from "react";
import reactStringReplace from "react-string-replace";
import { CardLink, LinkBehavior } from "../../../components";
import { ConnectObjectType, Links, SearchResultChipType, SearchResultType } from "../../../constants";
import { ISearchResult, ISearchResultChip } from "../../../models";

const Highlight = styled("span")`
  color: ${({ theme }) => theme.palette.primary.main};
`;

interface IProps {
  searchResult: ISearchResult;
}

const SearchResult = (props: IProps) => {
  const theme = useTheme();

  function getLink(result: ISearchResult | ISearchResultChip) {
    switch (result.type) {
      case SearchResultType.Team:
      case SearchResultChipType.Team:
        return Links.Team(result.id);
      case SearchResultType.Role:
      case SearchResultChipType.Role:
        return Links.RoleDetail(result.id);
      case SearchResultType.User:
      case SearchResultChipType.User:
        return Links.User(result.id);
    }
  }

  function getChipLabel(type: SearchResultType | SearchResultChipType) {
    switch (type) {
      case SearchResultType.Team:
      case SearchResultChipType.Team:
        return ConnectObjectType.Team;
      case SearchResultType.Role:
      case SearchResultChipType.Role:
        return ConnectObjectType.Role;
      case SearchResultType.User:
      case SearchResultChipType.User:
        return ConnectObjectType.Person;
    }
  }

  function getChipColor(type: SearchResultType | SearchResultChipType) {
    switch (type) {
      case SearchResultType.Team:
      case SearchResultChipType.Team:
        return theme.palette.primary.main;
      case SearchResultType.Role:
      case SearchResultChipType.Role:
        return theme.palette.orange.main;
      case SearchResultType.User:
      case SearchResultChipType.User:
        return theme.palette.wine.main;
    }
  }

  function getMatchTitle(key: string) {
    // TODO: We can't show highlights in rich text fields yet.

    switch (key) {
      // case "team.accountabilities":
      case "role.accountabilities":
        return "Accountabilities";
      // case "team.boundaries":
      // return "Boundaries";
      // case "team.communicationChannels":
      // return "Communication Channels";
      // case "team.otherInformation":
      // return "Other Information";
      case "role.tags":
        return "Tags";
      default:
        return key;
    }
  }

  function formatHighlights(value: string, key: string) {
    return reactStringReplace(value, /\[highlight_start\](.*?)\[highlight_end\]/i, (match, matchIndex) => (
      <Highlight key={`${key}:${matchIndex}`}>{match}</Highlight>
    ));
  }

  return (
    <CardLink
      href={getLink(props.searchResult)}
      backgroundColor={theme.palette.common.white}
      backgroundColorOnHover={theme.palette.primary.opacity5}
      sx={{ borderRadius: "15px" }}
    >
      <CardContent>
        <Stack direction="row" spacing={0.5} sx={{ mb: 0.5 }}>
          {props.searchResult.chips.map((x) => (
            <Chip key={x.id} color="primary" clickable component={LinkBehavior} label={x.title} href={getLink(x)} />
          ))}
          <Chip
            label={getChipLabel(props.searchResult.type)}
            sx={{
              color: theme.palette.common.white,
              backgroundColor: getChipColor(props.searchResult.type),
              cursor: "pointer",
            }}
          />
        </Stack>
        <Typography variant="h5">{formatHighlights(props.searchResult.title, props.searchResult.id)}</Typography>
        <Typography variant="body1">
          {formatHighlights(props.searchResult.description, props.searchResult.id)}
        </Typography>
        {Object.keys(props.searchResult.matches).length > 0 && (
          <Stack spacing={0.5} sx={{ pt: 0.5 }}>
            {Object.keys(props.searchResult.matches).map((key) => (
              <Box key={key}>
                <Typography variant="overline">{getMatchTitle(key)}</Typography>
                <Stack spacing={0.25} component="ul" sx={{ listStylePosition: "outside", pl: 1, m: 0 }}>
                  {props.searchResult.matches[key].map((value, index) => (
                    <Typography key={index} variant="body1" component="li">
                      {formatHighlights(value, props.searchResult.id)}
                    </Typography>
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>
        )}
      </CardContent>
    </CardLink>
  );
};

const MemoizedSearchResult = React.memo(SearchResult);

export { MemoizedSearchResult as SearchResult };
