import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { TableOfContentsEntry } from "@lexical/react/LexicalTableOfContentsPlugin";
import { Box, Stack, styled, Typography } from "@mui/material";

interface TextViewTableOfContentsProps {
  tableOfContentsArray: TableOfContentsEntry[];
}

const TableOfContentsLink = styled(Typography)`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

const TextViewerTableOfContents = (props: TextViewTableOfContentsProps) => {
  const [editor] = useLexicalComposerContext();

  function scrollToNode(key: string) {
    editor.getEditorState().read(() => {
      const domElement = editor.getElementByKey(key);

      if (domElement != null) {
        const y = domElement.getBoundingClientRect().top + window.scrollY - 150;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    });
  }

  function renderTableOfContentsEntry(tableOfContentsEntry: TableOfContentsEntry, index: number) {
    const [key, text, tag] = tableOfContentsEntry;

    switch (tag) {
      case "h1":
        return (
          <Box>
            <TableOfContentsLink variant="h5" onClick={() => scrollToNode(key)} sx={{ mt: index === 0 ? 0 : 1 }}>
              {text}
            </TableOfContentsLink>
          </Box>
        );
      case "h2":
        return (
          <TableOfContentsLink variant="body2" onClick={() => scrollToNode(key)}>
            {text}
          </TableOfContentsLink>
        );
      case "h3":
        return (
          <Stack direction="row">
            <Box sx={{ mx: 0.5 }}>&bull;</Box>
            <TableOfContentsLink variant="body1" onClick={() => scrollToNode(key)}>
              {text}
            </TableOfContentsLink>
          </Stack>
        );
    }
  }

  return (
    <Stack spacing={0.25}>
      {props.tableOfContentsArray.map((tableOfContentsEntry, index) =>
        renderTableOfContentsEntry(tableOfContentsEntry, index)
      )}
    </Stack>
  );
};

export { TextViewerTableOfContents };
