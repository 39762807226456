import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { FetchError, GetMeetingParticipantsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { configuration } from "../../../configuration";
import { queryKeys } from "../queryKeys";

const useGetMeetingParticipantsRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getMeetingParticipantsRequest({
    queryKey,
  }: QueryFunctionContext<
    ReturnType<typeof queryKeys.meetings.getMeetingParticipants>
  >): Promise<GetMeetingParticipantsDto> {
    const accessToken = await getAccessToken();
    const [{ meetingGuid }] = queryKey;

    const response = await fetch(`${configuration.apiRootUrl}/meetings/${meetingGuid}/participants`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getMeetingParticipantsRequest };
};

const useGetMeetingParticipants = (meetingGuid: string, enabled?: boolean) => {
  const { getMeetingParticipantsRequest } = useGetMeetingParticipantsRequest();

  return useQuery({
    queryKey: queryKeys.meetings.getMeetingParticipants(meetingGuid),
    queryFn: getMeetingParticipantsRequest,
    enabled: enabled,
  });
};

export { useGetMeetingParticipants };
