import { Box, Stack, SxProps, styled } from "@mui/material";
import React from "react";
import { Layout } from "./Layout";
import { DrawerProvider } from ".";

const HeaderContainer = styled(Box)`
  background-color: rgba(255, 255, 255, 0.9);
  position: sticky;
  top: 0;
  z-index: 3;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: transparent;
    -webkit-backdrop-filter: saturate(150%) blur(20px);
    backdrop-filter: saturate(150%) blur(20px);
  }
`;

const ContentContainer = styled(Box)``;

interface IProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
  navbar?: React.ReactNode;
  children: React.ReactNode;
  headerContainerProps?: IHeaderContainerProps;
  contentContainerProps?: IContentContainerProps;
}

interface IHeaderContainerProps {
  sx?: SxProps;
}

interface IContentContainerProps {
  sx?: SxProps;
}

const HeaderLayout = (props: IProps) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  return (
    <Layout>
      <DrawerProvider headerRef={headerRef}>
        <HeaderContainer ref={headerRef} {...props.headerContainerProps}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 0.5, sm: 1 }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", md: "center" }}
            sx={{ p: 1, pb: props.navbar ? 0 : 1, width: "100%" }}
          >
            <Box sx={{ minWidth: 0, width: "100%" }}>{props.title}</Box>
            {props.actions && <Box sx={{ flexShrink: 0 }}>{props.actions}</Box>}
          </Stack>
          {props.navbar && <Box>{props.navbar}</Box>}
        </HeaderContainer>
        <ContentContainer ref={containerRef} sx={{ p: { xs: 0.5, sm: 1 } }} {...props.contentContainerProps}>
          {props.children}
        </ContentContainer>
      </DrawerProvider>
    </Layout>
  );
};

export { HeaderLayout };
