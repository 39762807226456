import { Divider } from "@mui/material";
import { ViewList } from "../../../components/ViewList";
import { useGetRolesForUser } from "../../../http";
import { IUserRole } from "../../../models";
import { UserPreviewRolesListItem } from "./UserPreviewRolesListItem";

interface IProps {
  userId: string;
}

const UserPreviewRolesList = (props: IProps) => {
  const { data: roles, isLoading } = useGetRolesForUser(props.userId);

  return (
    <ViewList
      sx={{ py: 0.5 }}
      dataSource={roles?.sort(
        (a, b) => (a.isPrimaryRole ? -1 : b.isPrimaryRole ? 1 : 0) || a.name.localeCompare(b.name)
      )}
      divider={<Divider variant="dashed" />}
      isLoading={isLoading}
      renderItem={(item: IUserRole, index) => <UserPreviewRolesListItem role={item} key={index} sx={{ py: 0.25 }} />}
    />
  );
};

export { UserPreviewRolesList as UserPreviewRoleList };
