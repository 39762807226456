import { useState } from "react";
import { PlanContext } from "./PlanContext";

interface IProps {
  planGuid: string;
  children?: React.ReactNode;
}

const PlanProvider = (props: IProps) => {
  const [selectedActionItemGuid, setSelectedActionItemGuid] = useState<string | undefined>(undefined);
  const [focusedActionItemGuid, setFocusedActionItemGuid] = useState<string | undefined>(undefined);

  return (
    <PlanContext.Provider
      value={{
        planGuid: props.planGuid,
        focusedActionItemGuid,
        selectedActionItemGuid,
        setFocusedActionItemGuid,
        setSelectedActionItemGuid,
      }}
    >
      {props.children}
    </PlanContext.Provider>
  );
};

export { PlanProvider };
