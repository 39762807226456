import { SettingsRounded } from "@mui/icons-material";
import { Button, IconButton, Stack } from "@mui/material";
import { useNavigate } from "react-router";
import { Links } from "../../../../constants";
import { useBreakpoints } from "../../../../hooks";
import { useTeamPageLayoutContext } from "../TeamPageLayout";
import { Followers } from "./Followers";

const TeamActions = () => {
  const { team } = useTeamPageLayoutContext();
  const { isMdUp } = useBreakpoints();
  const navigate = useNavigate();

  if (team === undefined) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Followers />

      {team.isAdministrator &&
        (isMdUp ? (
          <Button
            startIcon={<SettingsRounded />}
            variant="outlined"
            onClick={(e) => navigate(Links.TeamSettings(team.slug))}
          >
            Settings
          </Button>
        ) : (
          <IconButton onClick={(e) => navigate(Links.TeamSettings(team.slug))}>
            <SettingsRounded />
          </IconButton>
        ))}
    </Stack>
  );
};

export { TeamActions };
