import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { IPlan } from "../../../models";
import { CreateBlockDto, CreateEditBlockResultDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { useSignalR } from "../../../hooks";

type Variables = {
  planGuid: string;
  guid: string;
  name: string;
  hintText: string;
  sortOrder: string;
  createdByUserId: string;
};

const useCreateBlockRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createBlockRequest(variables: Variables): Promise<CreateEditBlockResultDto> {
    const accessToken = await getAccessToken();
    const body: CreateBlockDto = {
      planGuid: variables.planGuid,
      guid: variables.guid,
      name: variables.name,
      hintText: variables.hintText,
      sortOrder: variables.sortOrder,
      createdByUserId: variables.createdByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/blocks`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { createBlockRequest };
};

const useCreateBlock = () => {
  const { createBlockRequest } = useCreateBlockRequest();
  const queryClient = useQueryClient();
  const signalR = useSignalR();

  return useMutation({
    mutationFn: createBlockRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.plans.getPlan(variables.planGuid));

      // Snapshot the current cache value
      const cachedData = queryClient.getQueryData<IPlan>(queryKeys.plans.getPlan(variables.planGuid));
      if (cachedData) {
        // Optimistically update the cache
        let optimisticallyUpdatedData: IPlan = {
          ...cachedData,
          blocks: [
            ...cachedData.blocks,
            {
              guid: variables.guid,
              name: variables.name,
              hintText: variables.hintText,
              sortOrder: variables.sortOrder,
              isEditable: true,
              createBlockAllowed: true,
              createSectionAllowed: true,
              sections: [],
            },
          ],
        };
        queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), optimisticallyUpdatedData);
      }

      return { cachedData };
    },
    onSuccess: (_data, variables) => {
      signalR.sendRefetchPlan(variables.planGuid);
    },
    onError: (_error, variables, context) => {
      //on error, rollback
      queryClient.setQueryData(queryKeys.plans.getPlan(variables.planGuid), context?.cachedData);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.plans.getPlan(variables.planGuid));
    },
  });
};

export { useCreateBlock };
