import { Box, Link, Avatar as MuiAvatar, SxProps, Tooltip, Typography, styled } from "@mui/material";
import { Links } from "../constants";
import { IUser } from "../models";
import { Avatar } from "./Avatar";
import { UserDto } from "../http";

const AvatarContainer = styled(Box)`
  display: flex;

  .MuiLink-root {
    margin-right: -8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const MoreAvatar = styled(MuiAvatar)`
  font-size: ${({ theme }) => `${theme.typography.fontSize}px`};
`;

interface IProps {
  users: IUser[] | UserDto[];
  max: number;
  avatarComponent?: (user: IUser | UserDto) => React.ReactNode;
  sx?: SxProps;
}

const AvatarGroup = (props: IProps) => {
  const total = props.users.length;
  const max = Math.min(total, props.max);
  const extra = total - max;

  return (
    <AvatarContainer sx={props.sx}>
      {props.users
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .slice(0, max)
        .map((u, i) => (
          <Link key={i} href={Links.User(u.userId)} underline="none">
            {props.avatarComponent ? props.avatarComponent(u) : <Avatar user={u} tooltip border />}
          </Link>
        ))}

      {extra > 0 && (
        <Tooltip
          title={props.users
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
            .slice(max, total)
            .map((u, i) => (
              <Link key={i} href={Links.User(u.userId)}>
                <Typography sx={{ fontSize: 13 }}>{u.displayName}</Typography>
              </Link>
            ))}
        >
          <MoreAvatar>+{extra}</MoreAvatar>
        </Tooltip>
      )}
    </AvatarContainer>
  );
};

export { AvatarGroup };
