import { Box, Skeleton, Stack, SxProps, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

interface ViewListRenderShowClosedButtonParams {
  closedItemsVisible: boolean;
  closedItemCount: number;
  onClick: () => void;
}

interface IActiveItemsListProps<T> {
  draggablePlaceholder?: React.ReactNode;
  divider?: React.ReactNode;
  activeItemsSortFunction: (a: T, b: T) => number;
}

interface IClosedItemsListProps<T> {
  showClosed?: boolean;
  closedItemsFilter: (item: T) => boolean;
  closedItemsSortFunction: (a: T, b: T) => number;
  renderShowClosedButton: (params: ViewListRenderShowClosedButtonParams) => React.ReactNode;
}

interface IProps<T> {
  dataSource?: T[];
  isLoading?: boolean;
  spacing?: number;
  divider?: React.ReactNode;
  sx?: SxProps;
  activeItemsListProps?: IActiveItemsListProps<T>;
  closedItemsListProps?: IClosedItemsListProps<T>;
  renderSkeleton?: (index: number) => React.ReactNode;
  renderItem: (item: T, index: number, closed: boolean) => React.ReactNode;
}

const ViewList = <T extends unknown>(props: IProps<T>) => {
  const theme = useTheme();
  const [showClosed, setShowClosed] = useState(false);

  const activeItemsSortFunction = props.activeItemsListProps?.activeItemsSortFunction ?? (() => 1);
  const closedItemsFilter = props.closedItemsListProps?.closedItemsFilter ?? (() => false);
  const closedItemsSortFunction = props.closedItemsListProps?.closedItemsSortFunction ?? (() => 1);

  const activeItems = props.dataSource?.filter((item) => !closedItemsFilter(item)) ?? [];
  const closedItems = props.dataSource?.filter((item) => closedItemsFilter(item)) ?? [];

  useEffect(() => {
    setShowClosed(Boolean(props.closedItemsListProps?.showClosed));
  }, [props.closedItemsListProps?.showClosed]);

  return (
    <>
      {props.isLoading || !props.dataSource ? (
        <Stack sx={{ ...props.sx }} spacing={props.spacing} divider={props.divider}>
          {[...Array(3)].map((_, i) =>
            props.renderSkeleton ? props.renderSkeleton(i) : <Skeleton variant="rectangular" key={i} />
          )}
        </Stack>
      ) : (
        <Stack sx={{ ...props.sx }}>
          {!activeItems || activeItems.length === 0 ? (
            <Box sx={{ p: 0.5, pl: 1 }}>
              <Typography variant="body1" sx={{ color: theme.palette.grey[300] }}>
                Nothing here yet.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Stack
                spacing={props.spacing}
                divider={
                  props.activeItemsListProps?.divider === undefined
                    ? props.divider
                    : props.activeItemsListProps.divider ?? null
                }
              >
                {activeItems
                  .sort((a, b) => activeItemsSortFunction(a, b))
                  .map((item, index) => props.renderItem(item, index, false))}
              </Stack>
              {props.activeItemsListProps && props.activeItemsListProps.draggablePlaceholder}
            </Box>
          )}

          {props.closedItemsListProps && closedItems.length > 0 && (
            <>
              {props.closedItemsListProps.renderShowClosedButton({
                closedItemsVisible: showClosed,
                closedItemCount: closedItems.length,
                onClick: () => setShowClosed(!showClosed),
              })}

              {showClosed && (
                <>
                  {props.divider}
                  <Stack spacing={props.spacing} divider={props.divider}>
                    {closedItems
                      .sort((a, b) => closedItemsSortFunction(a, b))
                      .map((item, index) => props.renderItem(item, index, true))}
                  </Stack>
                </>
              )}
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export { ViewList };
