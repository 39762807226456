import { LinkRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { v4 as newGuid } from "uuid";
import { Links } from "../../constants";
import { useSession } from "../../hooks";
import { queryKeys, useCreateActionToDo, useGetTeams, useGetUsers } from "../../http";
import { ISurveyResponseAnswer, ITeamSummary, IUser } from "../../models";
import { Autocomplete } from "../Autocomplete";
import { ContextPicker } from "../contexts";
import { SmallInputLabel } from "../SmallInputLabel";
import { SnackbarContent } from "../SnackbarContent";
import { TextEditor } from "../textEditor";
import { SurveyResponseAnswerView } from "..";

interface UrlParams {
  slug: string;
}

interface ICreateTaskFormState {
  description: string;
  notes: string;
  owners: IUser[];
  team: ITeamSummary | null;
  context?: {
    actionItemGuid: string;
    actionItemHistoryId: number;
  };
}

interface IProps {
  visible: boolean;
  context?: {
    teamName: string;
    teamSlug: string;
    actionItemGuid: string;
    actionItemDescription: string;
  };
  createdFromSurveyResponseAnswer?: ISurveyResponseAnswer;
  onCreated: () => void;
  onCancelled: () => void;
}

const CreateTaskModal = (props: IProps) => {
  const { slug } = useParams<keyof UrlParams>() as UrlParams;
  const { displayName, userId } = useSession();
  const { isLoading: isLoadingTeams, data: teams } = useGetTeams(undefined, props.visible);
  const { isLoading: isLoadingUsers, data: users } = useGetUsers(props.visible);
  const { mutate: createTask, isLoading: isCreatingTask } = useCreateActionToDo();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<ICreateTaskFormState>({
    defaultValues: {
      description: "",
      notes: "",
      team: null,
      owners: [],
      context: undefined,
    },
  });

  useEffect(() => {
    if (users && props.visible) {
      setValue(
        "owners",
        users.filter((x) => x.userId === userId)
      );
    }
  }, [setValue, userId, users, props.visible]);

  useEffect(() => {
    if (teams && props.visible) {
      setValue("team", teams.find((x) => x.slug === slug) ?? null);
    }
  }, [setValue, slug, teams, props.visible]);

  function renderSnackbarOwnerList(owners: IUser[], limit: number) {
    const displayedOwners = owners
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .slice(0, limit)
      .map((owner, index) => (
        <Box key={index} component="span">
          {index > 0 ? ", " : ""}
          <Link onClick={(e) => e.stopPropagation()} href={Links.UserMap(owner.userId)}>
            {owner.displayName}
            {owner.enabled ? "" : " (deactivated)"}
          </Link>
        </Box>
      ));

    const remainingCount = owners.length - limit;

    return (
      <>
        {displayedOwners}
        {remainingCount > 0 && `, +${remainingCount} more.`}
      </>
    );
  }

  async function handleAddClicked() {
    const newActionItemGuid = newGuid();

    handleSubmit((formState) => {
      createTask(
        {
          guid: newActionItemGuid,
          teamSlug: formState.team?.slug,
          contextActionItemGuid: formState.context?.actionItemGuid,
          contextActionItemHistoryId: formState.context?.actionItemHistoryId,
          description: formState.description,
          note: formState.notes,
          owners: formState.owners,
          createdFromSurveyResponseAnswerGuid: props.createdFromSurveyResponseAnswer?.answerGuid,
          createdByDisplayName: displayName,
        },
        {
          onSuccess: () => {
            reset(undefined, { keepDefaultValues: true });
            enqueueSnackbar(
              <SnackbarContent
                title="Task Created"
                content={
                  formState.team != null ? (
                    <>
                      Task added to{" "}
                      <Link href={Links.TeamImplementation(formState.team!.slug)}>{formState.team!.name}</Link>
                    </>
                  ) : (
                    <>Task assigned to {renderSnackbarOwnerList(formState.owners, 2)}</>
                  )
                }
              />,
              {
                variant: "success",
                action: <Button href={Links.ActionItemDetail(newActionItemGuid)}>View Task</Button>,
              }
            );

            // Force an update of the supporting items list for this task's context action item
            if (props.context) {
              queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(props.context.actionItemGuid));
              queryClient.invalidateQueries(queryKeys.actionItems.getActionItemDetail(props.context.actionItemGuid));
            }

            props.onCreated();
          },
        }
      );
    })();
  }

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleCancelClicked();
  }

  function handleCancelClicked() {
    reset(undefined, { keepDefaultValues: true });
    props.onCancelled();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <DialogTitle>Add Task</DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <Stack spacing={1}>
          {props.context && (
            <Stack sx={{ px: 1 }}>
              <SmallInputLabel>Context</SmallInputLabel>
              <Stack direction="row" spacing={0.25} sx={{ alignItems: "start" }}>
                <LinkRounded sx={{ position: "relative", top: 4 }} />
                <Typography>
                  <Box component="span" sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
                    {props.context?.teamName}
                  </Box>
                  {` : ${props.context?.actionItemDescription}`}
                </Typography>
              </Stack>
            </Stack>
          )}

          <Stack spacing={0.25} sx={{ px: 1 }}>
            <SmallInputLabel>Description *</SmallInputLabel>
            <Controller
              name="description"
              control={control}
              rules={{ required: { message: "Description is required", value: true } }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["description"] !== undefined}
                  helperText={errors["description"]?.message}
                  placeholder="Enter item..."
                  autoFocus
                  multiline
                />
              )}
            />
          </Stack>
          <Stack spacing={0.25} sx={{ px: 1 }}>
            <SmallInputLabel>Notes</SmallInputLabel>
            <Controller
              name="notes"
              control={control}
              render={({ field }) => <TextEditor initialValue={field.value} onChange={field.onChange} />}
            />
          </Stack>
          <Stack spacing={0.25} sx={{ px: 1 }}>
            <SmallInputLabel>Ownership</SmallInputLabel>
            <Controller
              name="owners"
              control={control}
              rules={{
                validate: (_, formValues) => {
                  return (
                    formValues.team != null ||
                    formValues.owners.length > 0 ||
                    "At least one owner or assigned team is required"
                  );
                },
              }}
              render={({ field }) => (
                <Autocomplete
                  isLoading={isLoadingUsers}
                  disabled={isLoadingUsers}
                  options={users ?? []}
                  getOptionLabel={(option) => option.displayName}
                  isOptionEqualToValue={(option, value) => option.userId === value.userId}
                  renderOption={(props, option) => (
                    <MenuItem component="li" {...props} key={option.userId}>
                      <Stack>
                        <Typography>{option.displayName}</Typography>
                        <Typography variant="caption">{option.email}</Typography>
                      </Stack>
                    </MenuItem>
                  )}
                  onChange={(_, option) => field.onChange(option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select people..."
                      inputProps={{ ...params.inputProps }}
                      error={errors["team"] !== undefined}
                      helperText={errors["team"]?.message}
                    />
                  )}
                  value={field.value}
                  multiple
                />
              )}
            />
          </Stack>
          <Stack spacing={0.25} sx={{ px: 1 }}>
            <SmallInputLabel>Team To Process</SmallInputLabel>
            <Controller
              name="team"
              control={control}
              rules={{
                validate: (_, formValues) => {
                  return (
                    formValues.team != null ||
                    formValues.owners.length > 0 ||
                    "At least one owner or assigned team is required"
                  );
                },
              }}
              render={({ field }) => (
                <Autocomplete
                  isLoading={isLoadingTeams}
                  disabled={isLoadingTeams}
                  options={teams ?? []}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <MenuItem key={option.slug} component="li" {...props}>
                      <Typography>{option.name}</Typography>
                    </MenuItem>
                  )}
                  onChange={(_, option) => field.onChange(option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select a team..."
                      inputProps={{ ...params.inputProps }}
                      error={errors["team"] !== undefined}
                      helperText={errors["team"]?.message}
                    />
                  )}
                  value={field.value}
                  isOptionEqualToValue={(option, value) => option.slug === value.slug}
                />
              )}
            />
          </Stack>
          {props.createdFromSurveyResponseAnswer && (
            <Stack spacing={1} sx={{ px: 1 }}>
              <Typography>Created issue will reference the survey response: </Typography>
              <SurveyResponseAnswerView surveyResponseAnswer={props.createdFromSurveyResponseAnswer} />
            </Stack>
          )}

          <Stack spacing={0.25} sx={{ display: props.context ? "none" : undefined, px: 1 }}>
            <SmallInputLabel>Connect To Context</SmallInputLabel>
            <Controller
              name="context"
              control={control}
              render={({ field }) => (
                <ContextPicker
                  disabled={props.context !== undefined}
                  defaultContext={props.context}
                  onSelect={(connectionPoint) =>
                    field.onChange({
                      actionItemGuid: connectionPoint?.guid,
                      actionItemHistoryId: connectionPoint?.historyId,
                    })
                  }
                />
              )}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isCreatingTask} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isCreatingTask}
          endIcon={isCreatingTask ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleAddClicked}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CreateTaskModal };
