import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable as LexicalContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TableOfContentsEntry, TableOfContentsPlugin } from "@lexical/react/LexicalTableOfContentsPlugin";
import { HeadingNode } from "@lexical/rich-text";
import { styled } from "@mui/material";
import { $getRoot, $insertNodes, LexicalEditor } from "lexical";
import { useEffect, useRef } from "react";
import { htmlToNodes, TextEditorContainer, TextEditorContentContainer, TextEditorPlaceholder } from "./TextEditor";
import { theme } from "./theme";

const ViewerContainer = styled(TextEditorContainer)`
  border: none;
  padding: 0;
`;

const ViewerContentContainer = styled(TextEditorContentContainer)`
  min-height: 20px;
`;

const ViewerContentEditable = styled(LexicalContentEditable)`
  outline: 0px solid transparent;
`;

const ViewerPlaceholder = styled(TextEditorPlaceholder)`
  top: 0;
  left: 0;
`;

interface TextViewerProps {
  value: string;
  placeholder?: string;
  renderTableOfContents?: (tableOfContentsArray: TableOfContentsEntry[]) => JSX.Element;
}

const TextViewer = (props: TextViewerProps) => {
  const editor = useRef<LexicalEditor>(null);

  useEffect(() => {
    if (editor.current == null) {
      return;
    }

    editor.current.update(() => {
      const root = $getRoot();
      root.clear();
      root.append(...htmlToNodes(editor.current!, props.value));
    });
  }, [props.value]);

  return (
    <ViewerContainer $readonly sx={{ scrollMarginTop: 130 }}>
      <LexicalComposer
        initialConfig={{
          namespace: "TextViewer",
          onError: (e) => console.error(e),
          theme: theme,
          editable: false,
          editorState: (editor) => {
            editor.update(() => {
              $insertNodes(htmlToNodes(editor, props.value));
            });
          },
          nodes: [AutoLinkNode, LinkNode, ListNode, ListItemNode, HeadingNode],
        }}
      >
        <ViewerContentContainer>
          <EditorRefPlugin editorRef={editor} />
          <RichTextPlugin
            contentEditable={<ViewerContentEditable />}
            placeholder={
              props.placeholder ? (
                <ViewerPlaceholder variant="placeholder">{props.placeholder}</ViewerPlaceholder>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <TableOfContentsPlugin>
            {(tableOfContentsArray) => props.renderTableOfContents?.(tableOfContentsArray) ?? <></>}
          </TableOfContentsPlugin>
        </ViewerContentContainer>
      </LexicalComposer>
    </ViewerContainer>
  );
};

export { TextViewer };
