import { MeetingAgendaItemType } from "../../../../constants";
import { MeetingAgendaItemDto, UserDto } from "../../../../http";
import { PersonalMapReview } from "./PersonalMapReview";
import { RolesReview } from "./RolesReview";

interface IProps {
  agendaStep: MeetingAgendaItemDto;
  user: UserDto;
}

const MeetingAgendaStepContent = (props: IProps) => {
  function renderAgendaStep() {
    switch (props.agendaStep.type) {
      case MeetingAgendaItemType.PersonalMapReview:
        return <PersonalMapReview user={props.user} />;
      case MeetingAgendaItemType.RoleReview:
        return <RolesReview user={props.user} />;
      default:
        return null;
    }
  }

  return renderAgendaStep();
};

export { MeetingAgendaStepContent };
