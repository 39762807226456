import { Divider, Skeleton } from "@mui/material";
import { useGetRolesForUser } from "../../http";
import { IUserRole } from "../../models";
import { ViewList } from "../ViewList";
import { RolesListItem } from "./RolesListItem";

interface IProps {
  userId: string;
  faded?: boolean;
}

const RolesList = (props: IProps) => {
  const { data: roles, isLoading } = useGetRolesForUser(props.userId);

  return (
    <ViewList
      dataSource={roles?.sort(
        (a, b) => (a.isPrimaryRole ? -1 : b.isPrimaryRole ? 1 : 0) || a.name.localeCompare(b.name)
      )}
      isLoading={isLoading}
      renderItem={(item: IUserRole, index) => (
        <RolesListItem role={item} key={index} faded={props.faded} sx={{ py: { xs: 0.5, sm: 1 } }} />
      )}
      divider={<Divider variant="dashed" sx={{ mx: 1 }} />}
      renderSkeleton={(index) => <Skeleton key={index} variant="rectangular" sx={{ m: { xs: 0.5, sm: 1 } }} />}
    />
  );
};

export { RolesList };
