import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { EditNotificationPreferencesDto, FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  userId: string;
  notificationPreferences: {
    typeGuid: string;
    email: boolean;
    inApp: boolean;
  }[];
};

const useEditNotificationPreferencesRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editNotificationPreferencesRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditNotificationPreferencesDto = {
      notificationPreferences: variables.notificationPreferences,
    };

    const response = await fetch(`${configuration.apiRootUrl}/users/${variables.userId}/notification-preferences`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editNotificationPreferencesRequest };
};

const useEditNotificationPreferences = () => {
  const { editNotificationPreferencesRequest } = useEditNotificationPreferencesRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editNotificationPreferencesRequest,
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries({ queryKey: queryKeys.users.getNotificationPreferences(variables.userId) });
    },
  });
};

export { useEditNotificationPreferences };
