import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { CommentSubject } from "../../../constants";
import { EditCommentDto, FetchError, GetCommentsDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  subjectType: CommentSubject;
  subjectGuid: string;
  commentGuid: string;
  text: string;
};

const useEditCommentRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editCommentRequest(variables: Variables) {
    const accessToken = await getAccessToken();
    const body: EditCommentDto = {
      text: variables.text,
    };

    const response = await fetch(`${configuration.apiRootUrl}/comments/${variables.commentGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response;
  }

  return { editCommentRequest };
};

const useEditComment = () => {
  const { editCommentRequest } = useEditCommentRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editCommentRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.comments.getComments(variables.subjectGuid));
      const cachedData = queryClient.getQueryData<GetCommentsDto>(
        queryKeys.comments.getComments(variables.subjectGuid)
      );

      if (cachedData) {
        const optimisticallyUpdatedData: GetCommentsDto = {
          ...cachedData,
          comments: cachedData.comments.map((comment) => {
            if (comment.guid === variables.commentGuid) {
              return {
                ...comment,
                text: variables.text,
              };
            }

            return comment;
          }),
        };

        queryClient.setQueryData(queryKeys.comments.getComments(variables.subjectGuid), optimisticallyUpdatedData);
        return { cachedData };
      }
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.comments.getComments(variables.subjectGuid), context?.cachedData);
    },
    onSuccess: (_data, variables) => {
      switch (variables.subjectType) {
        case CommentSubject.ActionItem:
          queryClient.refetchQueries(queryKeys.actionItems.getActionItemFeed(variables.subjectGuid));
          break;
        case CommentSubject.Issue:
          queryClient.refetchQueries(queryKeys.issues.getIssueFeed(variables.subjectGuid));
          break;
      }
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.comments.getComments(variables.subjectGuid));
    },
  });
};

export { useEditComment };
