import React from "react";

interface IPlanContext {
  planGuid: string;
  focusedActionItemGuid: string | undefined;
  selectedActionItemGuid: string | undefined;
  setFocusedActionItemGuid: (guid: string | undefined) => void;
  setSelectedActionItemGuid: (guid: string | undefined) => void;
}

const PlanContext = React.createContext<IPlanContext | undefined>(undefined);

const usePlanContext = () => {
  const context = React.useContext(PlanContext);

  if (context === undefined) {
    throw new Error("PlanContext is not initialized.");
  }

  return context;
};

export { PlanContext, usePlanContext };
