import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import {
  AccordionDetails,
  Box,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Stack,
  SxProps,
  styled,
} from "@mui/material";

const AccordionSummary = styled(MuiAccordionSummary)<{ $backgroundColourOnHover?: string }>`
  min-height: auto;
  transition: ${({ theme }) => theme.transitions.create("background-color")};

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .hoverActions {
    visibility: visible;
    opacity: 1;
    @media (pointer: fine) {
      visibility: hidden;
      opacity: 0;
      transition: ${({ theme }) => theme.transitions.create("opacity")};
    }
  }

  &:hover {
    background-color: ${({ $backgroundColourOnHover, theme }) =>
      $backgroundColourOnHover ? $backgroundColourOnHover : theme.palette.primary.transparent5};
    .hoverActions {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const AccordionTitle = styled(Stack)<{ $hideTitleUnderline?: boolean }>`
  border-bottom-color: #c9c9c9;
  border-bottom-style: ${({ $hideTitleUnderline }) => ($hideTitleUnderline ? "none" : "solid")};
  border-bottom-width: 1px;
  width: 100%;
  display: flex;
  align-items: center;
`;

AccordionTitle.defaultProps = {
  direction: "row",
  spacing: 0.5,
};

interface IProps {
  title: React.ReactNode;
  expanded?: boolean;
  onExpandClicked?: () => void;
  children?: React.ReactNode;
  hoverActions?: React.ReactNode;
  backgroundColorOnHover?: string;
  hideTitleUnderline?: boolean;
  sx?: SxProps;
}
const Accordion = (props: IProps) => {
  return (
    <MuiAccordion disableGutters expanded={props.expanded} sx={{ backgroundColor: "transparent" }}>
      <AccordionSummary
        $backgroundColourOnHover={props.backgroundColorOnHover}
        onClick={() => props.onExpandClicked?.()}
        sx={{ ...props.sx }}
      >
        <AccordionTitle $hideTitleUnderline={props.hideTitleUnderline}>
          {props.expanded ? <ExpandLessRounded fontSize="large" /> : <ExpandMoreRounded fontSize="large" />}
          <Stack direction="row" sx={{ alignItems: "center", minHeight: "35px", width: "100%" }}>
            {props.title}
            {props.hoverActions && <Box className="hoverActions">{props.hoverActions}</Box>}
          </Stack>
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails sx={{ pb: 0 }}>{props.children}</AccordionDetails>
    </MuiAccordion>
  );
};

export { Accordion };
