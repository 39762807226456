import { Divider, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { v4 as newGuid } from "uuid";
import { CommentForm, CommentSkeleton, MeetingNote, SignalRGroup } from "../../../../components";
import { useSession, useSignalR } from "../../../../hooks";
import { queryKeys, useCreateMeetingNote, useGetMeetingNotes } from "../../../../http";

interface Props {
  meetingGuid: string;
}

const MeetingNotesDrawerContent = (props: Props) => {
  const { displayName, userId, profilePhotoSmallUrl } = useSession();
  const { data, isLoading } = useGetMeetingNotes(props.meetingGuid);
  const { mutate: createMeetingNote } = useCreateMeetingNote();
  const signalR = useSignalR();
  const queryClient = useQueryClient();

  useEffect(() => {
    signalR.joinGroup(SignalRGroup.MeetingNotes(props.meetingGuid));
    return () => signalR.leaveGroup(SignalRGroup.MeetingNotes(props.meetingGuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.meetingGuid]);

  useEffect(() => {
    const handleShouldRefetchMeetingNotes = (meetingGuid: string) => {
      queryClient.invalidateQueries(queryKeys.meetings.getMeetingNotes(meetingGuid));
    };

    signalR.onShouldRefetchMeetingNotes(handleShouldRefetchMeetingNotes);
    return () => signalR.offShouldRefetchMeetingNotes(handleShouldRefetchMeetingNotes);
  }, [queryClient, signalR]);

  function handleSendClicked(text: string) {
    createMeetingNote(
      {
        meetingGuid: props.meetingGuid,
        meetingNoteGuid: newGuid(),
        createdByUser: {
          displayName,
          enabled: true,
          profilePhotoSmallUrl,
          userId,
        },
        text,
      },
      {
        onSuccess: () => {
          signalR.sendRefetchMeetingNotes(props.meetingGuid);
        },
      }
    );
  }

  return (
    <Stack divider={<Divider />}>
      <CommentForm
        placeholder="Enter a note to discuss in the meeting..."
        disabled={false}
        onSendClicked={handleSendClicked}
      />

      {isLoading && (
        <Stack spacing={1} sx={{ m: 1 }}>
          {[...new Array(3)].map((_, i) => (
            <CommentSkeleton key={i} />
          ))}
        </Stack>
      )}

      {!isLoading && (
        <Stack divider={<Divider variant="dashed" sx={{ mx: 1 }} />} sx={{ overflowY: "auto" }}>
          {data?.meetingNotes.length === 0 ? (
            <Typography sx={{ m: 1 }}>Nothing here yet.</Typography>
          ) : (
            data?.meetingNotes
              .sort((a, b) => {
                if (a.createdDateTimeUtc < b.createdDateTimeUtc) {
                  return 1;
                } else if (a.createdDateTimeUtc > b.createdDateTimeUtc) {
                  return -1;
                } else {
                  return 0;
                }
              })
              ?.map((meetingNote, i) => (
                <MeetingNote key={i} meetingGuid={props.meetingGuid} meetingNote={meetingNote} />
              ))
          )}
        </Stack>
      )}
    </Stack>
  );
};

export { MeetingNotesDrawerContent };
