import { Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { QuestionType } from "../../../../constants";
import { GetSurveyCoherenceReportResultDto } from "../../../../http";
import { QuestionFilter } from "./QuestionFilter";
import { TextResponse } from "./TextResponse";
import { TextResponseSkeleton } from "./TextResponseSkeleton";

interface IProps {
  isLoading: boolean;
  report: GetSurveyCoherenceReportResultDto | undefined;
}

const TextResponses = (props: IProps) => {
  const [selectedQuestionGuids, setSelectedQuestionGuids] = useState<string[]>([]);

  useEffect(() => {
    // Select all questions by default.
    setSelectedQuestionGuids(
      Object.entries(props.report?.questions ?? {})
        .filter(([, question]) => question.questionTypeId === QuestionType.Text)
        .map(([questionGuid]) => questionGuid)
    );
  }, [props.report]);

  return (
    <Stack>
      <QuestionFilter
        questions={Object.entries(props.report?.questions ?? {})
          .filter(([, question]) => question.questionTypeId === QuestionType.Text)
          .map(([questionGuid, question]) => ({
            questionGuid: questionGuid,
            questionName: question.name,
            questionTags: question.tags,
          }))}
        selectedQuestionGuids={selectedQuestionGuids}
        onChange={(values) => setSelectedQuestionGuids(values)}
        disabled={props.isLoading || props.report === undefined}
      />
      <Divider />
      <Stack spacing={1} divider={<Divider variant="dashed" sx={{ color: "#c9c9c9" }} />} sx={{ m: 1 }}>
        {props.isLoading ? (
          <TextResponseSkeleton />
        ) : props.report?.textResponses.some((x) => selectedQuestionGuids.includes(x.questionGuid)) ? (
          props.report?.textResponses
            .filter((x) => selectedQuestionGuids.includes(x.questionGuid))
            .sort(
              (a, b) =>
                props.report?.questions[a.questionGuid].name.localeCompare(
                  props.report?.questions[b.questionGuid].name
                ) ||
                a.teamName.localeCompare(b.teamName) ||
                a.dateTimeUtc.localeCompare(b.dateTimeUtc)
            )
            .map((x, index) => {
              const question = props.report?.questions[x.questionGuid];

              if (question === undefined) {
                return null;
              }

              return (
                <TextResponse
                  key={index}
                  responseAnswer={{
                    questionText: question.name,
                    teamName: x.teamName,
                    submittedOnDateTimeUtc: x.dateTimeUtc,
                    answerGuid: x.answerGuid,
                    textAnswer: x.response,
                  }}
                />
              );
            })
        ) : (
          <Typography>No responses for selected questions and entities.</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export { TextResponses };
