import { Divider, Stack, Link, Skeleton } from "@mui/material";
import { Avatar, isWhitespace, Typography } from "../../../components";
import { useGetUser } from "../../../http";
import { DateTime } from "luxon";
import { Country } from "country-state-city";
import { UserPreviewRoleList } from "./UserPreviewRolesList";
import { INetworkGraphNode } from "../../../models";
import { NodeObject } from "react-force-graph-2d";

interface NetworkGraphUserPreviewProps {
  node?: NodeObject<INetworkGraphNode>;
}

export const NetworkGraphUserPreview = (props: NetworkGraphUserPreviewProps) => {
  const { data: user, isLoading } = useGetUser(props.node?.id ? props.node?.id : "");

  if (isLoading) {
    return (
      <Stack spacing={0.5} sx={{ p: 1 }}>
        <Skeleton variant="rectangular" height="25px" width="60px" />
        <Skeleton variant="rectangular" height="40px" width="300px" />
      </Stack>
    );
  }

  function getLocationFormattedString() {
    if (user) {
      var countryName = Country.getCountryByCode(user.countryCode ?? "")?.name;
      var result = "";

      if (user.cityName) {
        result = `${user.cityName}, ${user.stateCode}, `;
      }
      if (countryName) {
        result += countryName;
      }
      return result;
    }
    return;
  }
  const formattedLocationString = getLocationFormattedString();

  return (
    <Stack spacing={1} sx={{ p: 1 }}>
      <Stack spacing={0.5} direction="row" sx={{ alignItems: "center" }}>
        {user && <Avatar size="large" user={user} border />}
        <Stack>
          <Typography variant="h4">{user?.displayName}</Typography>
          {user?.enabled ? (
            props.node?.email !== undefined && (
              <Link href={`mailto:${props.node.email?.toLowerCase()}`} sx={{ textTransform: "lowercase" }}>
                {props.node.email}
              </Link>
            )
          ) : (
            <Typography>Deactivated</Typography>
          )}
        </Stack>
      </Stack>

      {props.node && props.node.roles?.length > 0 && (
        <Stack divider={<Divider />}>
          <Typography variant="caption">ROLES</Typography>
          <UserPreviewRoleList userId={props.node?.id} />
        </Stack>
      )}

      <Stack divider={<Divider />}>
        <Typography variant="caption">DATE JOINED</Typography>
        {user?.joinedDate ? (
          <Typography variant="body1">{user.joinedDate?.toLocal().toLocaleString(DateTime.DATE_FULL)}</Typography>
        ) : (
          <Typography variant="placeholder">Nothing here yet.</Typography>
        )}
      </Stack>

      <Stack divider={<Divider />}>
        <Typography variant="caption">LOCATIONS</Typography>
        {!isWhitespace(formattedLocationString ?? "") ? (
          <Typography>{formattedLocationString}</Typography>
        ) : (
          <Typography variant="placeholder">Nothing here yet.</Typography>
        )}
      </Stack>
    </Stack>
  );
};
