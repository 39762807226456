import { CloseRounded } from "@mui/icons-material";
import { Box, Divider, Drawer, GlobalStyles, IconButton, Stack, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DEFAULT_DRAWER_WIDTH } from "../constants";
import { useBreakpoints, useSettings } from "../hooks";

const MIN_DRAWER_WIDTH = DEFAULT_DRAWER_WIDTH;
const MAX_DRAWER_WIDTH = 800;

const Dragger = styled(Box)<{ $isDragging: boolean }>`
  width: 8px;
  cursor: ew-resize;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;

  > div {
    opacity: ${({ $isDragging }) => ($isDragging ? 1 : 0)};
  }

  &:hover {
    > div {
      opacity: 1;
    }
  }
`;

const DragIndicator = styled(Box)`
  width: 3px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.turquoise.main};

  transition: ${({ theme }) => theme.transitions.create("opacity")};
`;

const StyledDrawer = styled(Drawer)<{ $offset: number }>`
  & .MuiDrawer-paper {
    background-color: #f7f7f7;
    box-sizing: border-box;
    z-index: 5;
    margin-top: ${({ $offset }) => `${$offset}px`};
    padding-bottom: ${({ $offset }) => `${$offset}px`};
    width: inherit;

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background-color: transparent;
      -webkit-backdrop-filter: saturate(150%) blur(20px);
      backdrop-filter: saturate(150%) blur(20px);
    }
  }
`;

interface IProps {
  container?: Element | (() => Element | null) | null | undefined;
  offset?: number;
  title?: React.ReactNode;
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const ResizeableDrawer = (props: IProps) => {
  const { drawerSettings, updateDrawerSettings } = useSettings();
  const [isDragging, setIsDragging] = useState(false);
  const { isMdUp } = useBreakpoints();
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (drawerRef.current) {
      drawerRef.current.style.width = isMdUp ? `${drawerSettings.width}px` : "80vw";
    }
  }, [drawerSettings.width, isMdUp]);

  function handleMouseDown() {
    setIsDragging(true);
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  }

  function handleMouseUp() {
    setIsDragging(false);
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);

    if (drawerRef.current) {
      const drawerWidth = parseInt(drawerRef.current.style.width.replace("px", ""));
      updateDrawerSettings({
        ...drawerSettings,
        width: drawerWidth,
      });
    }
  }

  function handleMouseMove(event: MouseEvent) {
    event.preventDefault();

    if (drawerRef.current) {
      const mouseDistanceFromRightEdge = window.innerWidth - event.clientX;
      const newWidth = Math.min(Math.max(mouseDistanceFromRightEdge, MIN_DRAWER_WIDTH), MAX_DRAWER_WIDTH);

      drawerRef.current.style.width = `${newWidth}px`;
    }
  }

  return (
    <StyledDrawer
      ref={drawerRef}
      variant="persistent"
      anchor="right"
      $offset={props.offset ?? 0}
      open={props.open}
      container={props.container}
    >
      {isDragging && <GlobalStyles styles={{ body: { userSelect: "none" } }} />}

      {isMdUp && (
        <Dragger $isDragging={isDragging} onMouseDown={handleMouseDown}>
          <DragIndicator />
        </Dragger>
      )}

      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          pl: { xs: 0.5, sm: 1 },
          pr: { xs: 0.5, sm: 1 },
          py: 0.5,
        }}
      >
        <Box>{props.title}</Box>
        <IconButton size="small" onClick={props.onClose}>
          <CloseRounded />
        </IconButton>
      </Stack>
      <Divider />
      {props.children}
    </StyledDrawer>
  );
};

export { ResizeableDrawer };
