import { Skeleton, Stack, SxProps, Typography } from "@mui/material";
import { ProposalDto } from "../../http";
import { Proposal } from "./Proposal";

interface IProps {
  proposals?: ProposalDto[];
  issueGuid: string;
  isLoading?: boolean;
  focusedProposalGuid?: string;
  sx?: SxProps;
}

const Proposals = (props: IProps) => {
  return (
    <Stack spacing={0.5} sx={{ width: "100%", ...props.sx }}>
      {props.isLoading || props.proposals == null ? (
        <Stack>
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} />
          ))}
        </Stack>
      ) : props.proposals.length === 0 ? (
        <Typography variant="placeholder">No proposals yet.</Typography>
      ) : (
        props.proposals.map((proposal) => (
          <Proposal
            key={proposal.guid}
            issueGuid={props.issueGuid}
            proposal={proposal}
            isFocused={proposal.guid === props.focusedProposalGuid}
          />
        ))
      )}
    </Stack>
  );
};

export { Proposals };
