import { Link, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import {
  ActionItemOwnershipPropertyChange,
  ActionItemStatusPropertyChange,
  ContextPropertyChange,
  DescriptionPropertyChange,
  DueDatePropertyChange,
  IssueStatusPropertyChange,
  NotePropertyChange,
  ProposalPropertyChange,
  SelectedProposalPropertyChange,
} from ".";
import { FeedItemProperty, Links } from "../../constants";
import { FeedItemDto } from "../../http";

interface IProps {
  feedItem: FeedItemDto;
}

const FeedPropertyChange = (props: IProps) => {
  function renderPropertyChange() {
    switch (props.feedItem.propertyChange!.propertyName) {
      case FeedItemProperty.ActionItemNote:
      case FeedItemProperty.Note:
        return <NotePropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.ActionItemOwnership:
        return <ActionItemOwnershipPropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.ActionItemStatus:
        return <ActionItemStatusPropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.Context:
        return <ContextPropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.Description:
        return <DescriptionPropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.DueDate:
        return <DueDatePropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.IssueStatus:
        return <IssueStatusPropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.Proposal:
        return <ProposalPropertyChange feedItem={props.feedItem} />;
      case FeedItemProperty.SelectedProposal:
        return <SelectedProposalPropertyChange feedItem={props.feedItem} />;
      default:
        return (
          <Typography variant="caption">
            <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
            <span> updated </span>
            {props.feedItem.propertyChange!.propertyName}
          </Typography>
        );
    }
  }

  const propertyChange = renderPropertyChange();

  if (propertyChange == null) {
    return null;
  } else {
    return (
      <Stack
        direction="row"
        spacing={0.25}
        sx={{ backgroundColor: "rgba(0, 0, 0, 0.03)", px: { xs: 0.5, sm: 1 }, py: 0.5 }}
      >
        <Typography variant="caption">&bull;</Typography>
        <Stack direction="column">
          <Typography variant="caption">
            {DateTime.fromISO(props.feedItem.timestampUtc, { zone: "utc" })
              .toLocal()
              .toLocaleString(DateTime.DATETIME_MED)}
          </Typography>

          {propertyChange}
        </Stack>
      </Stack>
    );
  }
};

export { FeedPropertyChange };
