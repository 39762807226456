import { QueryClient, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetIssuesDto, IssueDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { useSignalR } from "../../../hooks";

type Variables = {
  guid: string;
  teamSlug: string;
  createdByUserId: string;
  userId: string;
};

const useRemoveIssueUpvoteRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editIssueStatusRequest(variables: Variables) {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/issues/${variables.guid}/upvotes/${variables.userId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editIssueStatusRequest };
};

const useRemoveIssueUpvote = () => {
  const { editIssueStatusRequest } = useRemoveIssueUpvoteRequest();
  const queryClient = useQueryClient();
  const signalR = useSignalR();

  return useMutation({
    mutationFn: editIssueStatusRequest,
    onMutate: async (variables) => {
      const cachedIssueDetailsData = await optimisticallyUpdateIssueDetailsData(
        queryClient,
        queryKeys.issues.getIssueDetail(variables.guid),
        variables
      );

      const cachedTeamIssuesData = optimisticallyUpdateIssuesData(
        queryClient,
        queryKeys.issues.getIssues(variables.teamSlug, undefined),
        variables
      );

      const cachedUserIssuesData = optimisticallyUpdateIssuesData(
        queryClient,
        queryKeys.issues.getIssues(undefined, variables.createdByUserId),
        variables
      );

      return {
        cachedIssueDetailsData,
        cachedTeamIssuesData,
        cachedUserIssuesData,
      };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.guid), context?.cachedTeamIssuesData);
      queryClient.setQueryData(
        queryKeys.issues.getIssues(variables.teamSlug, undefined),
        context?.cachedTeamIssuesData
      );
      queryClient.setQueryData(
        queryKeys.issues.getIssues(undefined, variables.createdByUserId),
        context?.cachedUserIssuesData
      );
    },
    onSuccess: (_data, variables) => {
      signalR.sendRefetchTeamIssues(variables.teamSlug);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssueDetail(variables.guid));
      queryClient.invalidateQueries(queryKeys.issues.getIssues(variables.teamSlug, undefined));
      queryClient.invalidateQueries(queryKeys.issues.getIssues(undefined, variables.createdByUserId));
    },
  });
};

async function optimisticallyUpdateIssuesData(queryClient: QueryClient, queryKey: QueryKey, variables: Variables) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<GetIssuesDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: GetIssuesDto = {
      ...cachedData,
      issues: cachedData.issues.map((issue) => {
        if (issue.guid === variables.guid) {
          return {
            ...issue,
            upvotes: issue.upvotes.filter((x) => x.userId !== variables.userId),
          };
        }

        return issue;
      }),
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

async function optimisticallyUpdateIssueDetailsData(
  queryClient: QueryClient,
  queryKey: QueryKey,
  variables: Variables
) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<IssueDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: IssueDto = {
      ...cachedData,
      upvotes: cachedData.upvotes.filter((x) => x.userId !== variables.userId),
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useRemoveIssueUpvote };
