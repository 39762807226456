import React from "react";

interface ISignalRMeetingsContext {
  sendRefetchMeetingListItem: (meetingGuid: string) => void;
  sendRefetchMeetingNotes: (meetingGuid: string) => void;
  sendRefetchMeetingParticipants: (meetingGuid: string) => void;
  sendEndMeeting: (meetingGuid: string) => void;
  onShouldRefetchMeetingListItem: (handler: (meetingGuid: string) => void) => void;
  onShouldRefetchMeetingNotes: (handler: (meetingGuid: string) => void) => void;
  onShouldRefetchMeetingParticipants: (handler: (meetingGuid: string) => void) => void;
  onShouldEndMeeting: (handler: (meetingGuid: string) => void) => void;
  offShouldRefetchMeetingListItem: (handler: (meetingGuid: string) => void) => void;
  offShouldRefetchMeetingNotes: (handler: (meetingGuid: string) => void) => void;
  offShouldRefetchMeetingParticipants: (handler: (meetingGuid: string) => void) => void;
  offShouldEndMeeting: (handler: (meetingGuid: string) => void) => void;
}

const SignalRMeetingsContext = React.createContext<ISignalRMeetingsContext>({
  sendRefetchMeetingListItem: () => null,
  sendRefetchMeetingNotes: () => null,
  sendRefetchMeetingParticipants: () => null,
  sendEndMeeting: () => null,
  onShouldRefetchMeetingListItem: () => null,
  onShouldRefetchMeetingNotes: () => null,
  onShouldRefetchMeetingParticipants: () => null,
  onShouldEndMeeting: () => null,
  offShouldRefetchMeetingListItem: () => null,
  offShouldRefetchMeetingNotes: () => null,
  offShouldRefetchMeetingParticipants: () => null,
  offShouldEndMeeting: () => null,
});

export { SignalRMeetingsContext };
