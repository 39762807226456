import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Popper,
  Stack,
  styled,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSession } from "../hooks";
import { GetActionItemDto, useEditActionItemSection, useGetPlanStructure, useGetTeams } from "../http";
import { ITeamSummary } from "../models";
import { Autocomplete } from "./Autocomplete";
import { Typography } from "./Typography";

const MenuItem = styled(Box)`
  &:first-child {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }

  &:last-child {
    margin-bottom: 2px;
  }
` as typeof Box;

interface IProps {
  open: boolean;
  actionItem: GetActionItemDto;
  onUpdated: (selectedTeam: ITeamSummary | null, selectedSection: ITeamSection | null) => void;
  onCancelled: () => void;
}

interface ITeamSection {
  blockName?: string;
  sectionName: string;
  sectionGuid?: string;
}

const EditActionItemSectionModal = (props: IProps) => {
  const { userId } = useSession();
  const [selectedTeam, setSelectedTeam] = useState<ITeamSummary | null>(null);
  const [selectedSection, setSelectedSection] = useState<ITeamSection | null>(null);
  const [sections, setSections] = useState<ITeamSection[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const { data: teams, isFetching: isFetchingTeams } = useGetTeams(userId, props.open);
  const { data: planStructure, isFetching: isFetchingStructure } = useGetPlanStructure(
    selectedTeam?.planGuid!,
    props.open
  );
  const { mutate: editActionItemSection, isLoading: isUpdatingSection } = useEditActionItemSection();
  const theme = useTheme();

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.actionItem]);

  useEffect(() => {
    setSections([
      {
        sectionName: "Tasks",
      },
      ...(planStructure?.blocks.flatMap((block) =>
        block.sections.map(
          (section): ITeamSection => ({
            blockName: block.name,
            sectionName: section.name,
            sectionGuid: section.guid,
          })
        )
      ) ?? []),
    ]);
  }, [planStructure]);

  async function handlePlanChanged(e: React.SyntheticEvent, value: ITeamSummary | null) {
    setSelectedTeam(value);
    setShowError(false);
    setSelectedSection(null);
  }

  function handleCancelClicked() {
    reset();
    props.onCancelled();
  }

  function reset() {
    if (props.actionItem.teamPlanSection) {
      setSelectedTeam(teams?.find((x) => x.slug === props.actionItem.teamPlanSection?.teamSlug) ?? null);
      setSelectedSection({
        sectionGuid: props.actionItem.teamPlanSection.sectionGuid,
        sectionName: props.actionItem.teamPlanSection.sectionName,
      });
    } else if (props.actionItem.team) {
      setSelectedTeam(teams?.find((x) => x.slug === props.actionItem.team?.teamSlug) ?? null);
      setSelectedSection({
        sectionGuid: undefined,
        sectionName: "Tasks",
      });
    } else {
      setSelectedTeam(null);
      setSelectedSection(null);
    }

    setShowError(false);
  }

  async function handleUpdateClicked() {
    if (selectedSection == null && !itemCanBeRemovedFromTeamSection) {
      setShowError(true);
      return;
    }

    editActionItemSection(
      {
        fromPlanGuid: props.actionItem.teamPlanSection?.planGuid,
        toPlanGuid: selectedSection?.sectionName === "Tasks" ? undefined : selectedTeam?.planGuid ?? undefined,
        guid: props.actionItem.guid,
        fromSectionGuid: props.actionItem.teamPlanSection?.sectionGuid,
        toSectionGuid: selectedSection?.sectionGuid,
        fromActionsToDoTeamSlug: props.actionItem.team?.teamSlug,
        toActionsToDoTeamSlug: selectedSection?.sectionName === "Tasks" ? selectedTeam?.slug : undefined,
      },
      {
        onSuccess: () => props.onUpdated(selectedTeam, selectedSection),
      }
    );
  }

  const itemCanBeRemovedFromTeamSection =
    props.actionItem.owners.length > 0 &&
    props.actionItem.supportingItems.actionItems.length === 0 &&
    props.actionItem.supportingItems.issues.length === 0;

  return (
    <Dialog open={props.open} onClose={handleCancelClicked}>
      <DialogTitle>Change Section</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Typography>Move this card to another team or section.</Typography>
          <Autocomplete
            isLoading={isFetchingTeams || !teams}
            options={teams ?? []}
            getOptionLabel={(option: ITeamSummary) => option.name}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.slug} component="li">
                <Stack>
                  <Typography>{option.name}</Typography>
                  {!(option.isAdministrator || option.isMember) && (
                    <Typography variant="caption">You are not a member or administrator of this team</Typography>
                  )}
                </Stack>
              </MenuItem>
            )}
            getOptionDisabled={(option) => !(option.isMember || option.isAdministrator)}
            isOptionEqualToValue={(option, value) => option.planGuid === value.planGuid}
            renderInput={(params) => (
              <TextField
                {...params}
                error={showError}
                placeholder="Select a team"
                inputProps={{ ...params.inputProps }}
              />
            )}
            onChange={handlePlanChanged}
            PopperComponent={(props) => (
              <Popper {...props} style={{ ...props.style, margin: 0, padding: 0, minWidth: "300px" }} />
            )}
            value={selectedTeam}
          />
          <Autocomplete
            isLoading={selectedTeam !== null && (isFetchingStructure || !planStructure)}
            disabled={selectedTeam == null || isFetchingStructure || !planStructure}
            groupBy={(option) => option.blockName ?? ""}
            options={sections}
            getOptionLabel={(option) => option.sectionName}
            renderGroup={(group) => (
              <Box key={group.key} sx={{ pb: 0.25 }}>
                {group.group && (
                  <Stack
                    spacing={0.2}
                    sx={{
                      px: 0.5,
                      pt: 0.75,
                      position: "sticky",
                      top: 0,
                      backgroundColor: theme.palette.common.white,
                    }}
                  >
                    <Typography variant="caption">{group.group.toLocaleUpperCase()}</Typography>
                    <Divider />
                  </Stack>
                )}
                {group.children}
              </Box>
            )}
            renderOption={(props, option, state) => (
              <MenuItem {...props} key={state.index} component="li">
                <Typography>{option.sectionName}</Typography>
              </MenuItem>
            )}
            isOptionEqualToValue={(option, value) => option.sectionGuid === value.sectionGuid}
            renderInput={(params) => (
              <TextField
                {...params}
                error={showError}
                helperText={
                  showError &&
                  "This card can only be removed from the team if it has ownership assigned and no supporting items."
                }
                placeholder="Select a section"
                inputProps={{ ...params.inputProps }}
              />
            )}
            onChange={(_, value) => setSelectedSection(value)}
            PopperComponent={(props) => (
              <Popper {...props} style={{ ...props.style, margin: 0, padding: 0, minWidth: "300px" }} />
            )}
            value={selectedSection}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isUpdatingSection} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isUpdatingSection}
          endIcon={isUpdatingSection ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleUpdateClicked}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditActionItemSectionModal };
export type { ITeamSection };
