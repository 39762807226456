import { ChatOutlined, Settings } from "@mui/icons-material";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useDrawer } from "../../../components";
import { useSession } from "../../../hooks";
import { GetWikiPageDto } from "../../../http";
import { WikiPageCommentsDrawerContent } from "./WikiPageCommentsDrawerContent";
import { WikiPageSettingsModal } from "./WikiPageSettingsModal";

interface IProps {
  page: GetWikiPageDto | undefined;
  isLoading: boolean;
  isEditing: boolean;
  onEditClicked: () => void;
  onCancelClicked: () => void;
  onSaveClicked: () => void;
}

const WikiPageActions = (props: IProps) => {
  const { userId: sessionUserId } = useSession();
  const { showDrawer } = useDrawer();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const userCanEdit = props.page?.editors.some((editor) => editor.userId === sessionUserId) ?? false;

  function handleJoinConversationClicked() {
    showDrawer({
      title: (
        <Stack>
          <Typography variant="h6">CODE OS Conversations</Typography>
          <Typography variant="caption">Take part in the evolution of the CODE OS</Typography>
        </Stack>
      ),
      content: <WikiPageCommentsDrawerContent page={props.page!} />,
    });
  }

  if (props.isLoading) {
    return null;
  }

  return (
    <>
      <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
        {userCanEdit && (
          <>
            {props.isEditing ? (
              <Stack direction="row" spacing={1}>
                <Button variant="outlined" startIcon={<Settings />} onClick={() => setIsSettingsOpen(true)}>
                  Settings
                </Button>
                <Divider orientation="vertical" flexItem />
                <Button variant="outlined" onClick={props.onCancelClicked}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={props.onSaveClicked}>
                  Save
                </Button>
              </Stack>
            ) : (
              <Button variant="outlined" onClick={props.onEditClicked}>
                Edit
              </Button>
            )}
          </>
        )}

        {!props.isEditing && (
          <Button variant="contained" startIcon={<ChatOutlined />} onClick={handleJoinConversationClicked}>
            Join The Conversation ({props.page?.commentCount})
          </Button>
        )}
      </Stack>

      <WikiPageSettingsModal
        open={isSettingsOpen}
        page={props.page!}
        onSaved={() => setIsSettingsOpen(false)}
        onCancelled={() => setIsSettingsOpen(false)}
      />
    </>
  );
};

export { WikiPageActions };
