import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  Box,
  Chip,
  IconButton,
  Link,
  AccordionSummary as MuiAccordionSummary,
  Stack,
  styled,
  SxProps,
  useTheme,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { ConnectObjectType, Links, SearchFilter } from "../../constants";
import { IUserRole } from "../../models";
import { AvatarGroup } from "../AvatarGroup";
import { BlockLink } from "../BlockLink";
import { LinkBehavior } from "../LinkBehavior";
import { Typography } from "../Typography";

const AccordionSummary = styled(MuiAccordionSummary)<{
  $hideTitleUnderline?: boolean;
  $backgroundColourOnHover?: string;
}>`
  min-height: auto;
  transition: ${({ theme }) => theme.transitions.create("background-color")};
  padding: 0;

  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

interface IProps {
  role: IUserRole;
  faded?: boolean;
  sx?: SxProps;
}

const RolesListItem = (props: IProps) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Accordion disableGutters expanded={isExpanded} sx={{ backgroundColor: "transparent", p: 0 }}>
      <AccordionSummary>
        <BlockLink href={Links.Role(props.role.guid)} backgroundColorOnHover={theme.palette.primary.transparent5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            sx={{ ...props.sx, p: 1 }}
          >
            <Stack spacing={0}>
              <Stack spacing={0.5} direction="row" sx={{ pb: 0.25 }}>
                <Chip
                  label={props.role.parentTeam.name}
                  component={LinkBehavior}
                  href={Links.Team(props.role.parentTeam.slug)}
                  clickable
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                  }}
                  color="primary"
                />

                <Chip
                  label={props.role.isPrimaryRole ? ConnectObjectType.PrimaryRole : ConnectObjectType.Role}
                  color="orange"
                />

                {props.role.isOpen && (
                  <Chip
                    label={`OPEN as of ${props.role.isOpenLastModifiedUtc
                      .toLocal()
                      .toLocaleString(DateTime.DATE_MED)
                      .toLocaleUpperCase()}`}
                    color="orange"
                    variant="outlined"
                    sx={{
                      ".MuiChip-label": {
                        textTransform: "none",
                      },
                    }}
                  />
                )}
              </Stack>

              <Typography variant="h5" faded={props.faded}>
                {props.role.name}
              </Typography>
              <Typography variant="body1" faded={props.faded} sx={{ wordWrap: "break-word" }}>
                {props.role.description}
              </Typography>
            </Stack>
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <ExpandLessRounded fontSize="large" /> : <ExpandMoreRounded fontSize="large" />}
            </IconButton>
          </Stack>
        </BlockLink>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={0.5} sx={{ mx: 1 }}>
          {props.role.accountabilities && props.role.accountabilities.length > 0 && (
            <Box>
              <Typography variant="overline">Accountabilities</Typography>
              <Stack sx={{ pl: 1, mt: 0.5 }}>
                {props.role.accountabilities.map((accountability, index) => (
                  <Typography key={index} display="list-item">
                    {accountability.description}
                  </Typography>
                ))}
              </Stack>
            </Box>
          )}
          {props.role.tags && props.role.tags.length > 0 && (
            <Box>
              <Typography variant="overline">Tags</Typography>
              <Stack direction="row" divider={<Typography>,&nbsp;</Typography>} sx={{ mt: 0.5 }}>
                {props.role.tags.map((tag, i) => (
                  <Link key={i} href={Links.Search(tag, SearchFilter.Roles)}>
                    {tag}
                  </Link>
                ))}
              </Stack>
            </Box>
          )}
          {props.role.members && props.role.members.length > 0 && (
            <Box>
              <Typography variant="overline">People</Typography>
              <AvatarGroup users={props.role.members} max={10} sx={{ mt: 0.75 }} />
            </Box>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export { RolesListItem };
