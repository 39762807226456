import { MoreVertRounded } from "@mui/icons-material";
import { Box, IconButton, Link, Menu, MenuItem, Stack, styled, SxProps, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { EditMetricModal, LineGraph } from "..";
import { ConnectObjectType, Links } from "../../constants";
import { useSession } from "../../hooks";
import { useDeleteMetric } from "../../http";
import { MetricMapper } from "../../mapping";
import { IMetric, IMetricDataset } from "../../models";

const MetricLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
  display: block;
  transition: ${({ theme }) => theme.transitions.create("background-color")};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.transparent5};
    text-decoration: none;
  }
`;

interface IProps {
  metric: IMetric;
  metricIndex: number;
  sx?: SxProps;
}

const ViewMetric = (props: IProps) => {
  const { userId } = useSession();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [datasets, setDatasets] = useState<IMetricDataset[]>(props.metric.datasets);
  const { mutate: deleteMetric } = useDeleteMetric();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const theme = useTheme();

  useEffect(() => {
    setDatasets(props.metric.datasets);
  }, [props.metric.datasets]);

  async function handleDeleteClicked() {
    setMenuAnchor(null);

    try {
      await confirm({
        title: `Delete ${ConnectObjectType.Metric}`,
        content: `Are you sure you want to delete this ${ConnectObjectType.Metric}?`,
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteMetric(
      {
        teamSlug: props.metric.teamSlug,
        guid: props.metric.guid,
        modifiedByUserId: userId!,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(`Metric has been deleted`);
        },
      }
    );
  }

  return (
    <>
      <MetricLink href={Links.MetricDetail(props.metric.guid)} sx={{ ...props.sx }}>
        <Stack spacing={0.5} sx={{ height: "100%" }}>
          <Stack
            spacing={0.5}
            direction="row"
            sx={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}
          >
            <Typography variant="h5">{props.metric.title}</Typography>
            <IconButton
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setMenuAnchor(e.currentTarget);
              }}
            >
              <MoreVertRounded />
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={() => setMenuAnchor(null)}
              onClick={(e) => e.stopPropagation()}
            >
              <MenuItem
                onClick={() => {
                  setIsModalVisible(true);
                  setMenuAnchor(null);
                }}
              >
                Update
              </MenuItem>
              <MenuItem onClick={handleDeleteClicked} sx={{ color: theme.palette.error.main }}>
                Delete
              </MenuItem>
            </Menu>
          </Stack>
          {props.metric.description && <Typography>{props.metric.description}</Typography>}
          {datasets.length > 0 && (
            <>
              <LineGraph
                datasets={datasets.map((data) => {
                  return {
                    title: data.title,
                    color: data.color,
                    dotted: data.dotted,
                    data: MetricMapper.toChartData(data),
                  };
                })}
                sx={{ flex: 1, px: 0.5, height: "400px" }}
              />
              {props.metric.dataLastModifiedDateTimeUtc && props.metric.dataLastModifiedByDisplayName && (
                <Box>
                  <Typography variant="caption">{`Data updated by ${
                    props.metric.dataLastModifiedByDisplayName
                  } on ${props.metric.dataLastModifiedDateTimeUtc
                    .toLocal()
                    .toLocaleString(DateTime.DATE_FULL)}`}</Typography>
                </Box>
              )}
            </>
          )}
        </Stack>
      </MetricLink>

      <EditMetricModal
        isVisible={isModalVisible}
        metric={props.metric}
        onUpdated={() => setIsModalVisible(false)}
        onCancelled={() => setIsModalVisible(false)}
      />
    </>
  );
};

export { ViewMetric };
