import {
  CalendarMonthOutlined,
  HowToVoteOutlined,
  StickyNote2Outlined,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import { Button, Divider, Link, Stack, SxProps, Tooltip, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { Avatar, isWhitespace } from "..";
import { Links } from "../../constants";
import { useSession } from "../../hooks";
import { IssueSummaryDto } from "../../http";

interface IProps {
  issue: IssueSummaryDto;
  onUpvoteClicked: () => void;
  sx?: SxProps;
}

const IssueIcons = (props: IProps) => {
  const { userId } = useSession();
  const { issue } = props;
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      spacing={0.25}
      divider={<Divider orientation="vertical" sx={{ height: "12px" }} />}
      sx={props.sx}
      alignItems="center"
    >
      <Avatar user={issue.createdByUser} tooltip sx={{ width: 20, height: 20, fontSize: 12 }} />

      <Tooltip
        placement="bottom-end"
        title={
          issue.upvotes.length > 0
            ? issue.upvotes
                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                .map((u, i) => [
                  <Typography key={i} sx={{ display: "inline-block", fontSize: 13, marginRight: "2px" }}>
                    <Link href={Links.User(u.userId)}>{u.displayName}</Link>
                    {i < issue.upvotes.length - 1 && ", "}
                  </Typography>,
                ])
            : null
        }
      >
        <Stack direction="row" spacing={0.1} sx={{ alignItems: "center" }}>
          <Button
            size="small"
            disableRipple
            onClick={(e) => {
              props.onUpvoteClicked();
              e.stopPropagation();
            }}
            sx={{ minWidth: 0, textDecoration: "none !important" }}
            startIcon={
              issue.upvotes.findIndex((x) => x.userId === userId) !== -1 ? (
                <ThumbUp sx={{ color: theme.palette.grey[300] }} />
              ) : (
                <ThumbUpOutlined sx={{ color: theme.palette.grey[300] }} />
              )
            }
          >
            <Typography variant="caption">{issue.upvotes.length}</Typography>
          </Button>
        </Stack>
      </Tooltip>

      <Tooltip title="Created On">
        <Stack direction="row" spacing={0.1} sx={{ alignItems: "center" }}>
          <CalendarMonthOutlined sx={{ color: theme.palette.grey[300] }} />
          <Typography variant="caption">
            {DateTime.fromISO(props.issue.createdOnDateTimeUtc, { zone: "utc" })
              .toLocal()
              .toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Stack>
      </Tooltip>

      {props.issue.proposalCount > 0 && (
        <Tooltip title="Proposals">
          <Stack direction="row" spacing={0.1} sx={{ alignItems: "center" }}>
            <HowToVoteOutlined sx={{ color: theme.palette.grey[300] }} />
            <Typography variant="caption">{props.issue.proposalCount}</Typography>
          </Stack>
        </Tooltip>
      )}

      {props.issue.note != null && !isWhitespace(props.issue.note) && (
        <Tooltip title="Notes">
          <StickyNote2Outlined sx={{ color: theme.palette.grey[300] }} />
        </Tooltip>
      )}
    </Stack>
  );
};

export { IssueIcons };
