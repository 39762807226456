import { EditOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, SxProps, Typography } from "@mui/material";

interface IProps {
  labelIcon: React.ReactNode;
  label: string;
  labelProps?: LabelProps;
  rowLayoutOnSmallScreens?: boolean;
  canEdit?: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  onEditClicked?: () => void;
}

interface LabelProps {
  sx: SxProps;
}

const ItemProperty = (props: IProps) => {
  return (
    <Stack
      direction={{ xs: props.rowLayoutOnSmallScreens ? "row" : "column", sm: "row" }}
      spacing={0}
      sx={{ alignItems: "start", flexGrow: 1, minWidth: "0px" }}
    >
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          minWidth: "112px",
          maxWidth: "112px",
          minHeight: "28px",
          position: "relative",
          top: 0,
          alignSelf: "center",
          alignItems: "center",
          ...props.labelProps?.sx,
        }}
      >
        <Box sx={{ display: "flex" }}>{props.labelIcon}</Box>
        <Typography variant="caption" sx={{ lineHeight: "0.875rem", textTransform: "uppercase" }}>
          {props.label}
        </Typography>
      </Stack>
      <Stack sx={{ alignItems: "center", minHeight: "28px", width: "100%" }} direction="row">
        <Box sx={{ flexGrow: 1, minWidth: "0px" }}>{props.children}</Box>

        {!props.isLoading && props.canEdit && !props.isEditing && (
          <IconButton
            size="small"
            sx={{ color: "rgba(0, 0, 0, 0.3)", alignSelf: "start" }}
            onClick={props.onEditClicked}
          >
            <EditOutlined />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};

export { ItemProperty };
