import { Box, Button, Chip, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { MeetingNotesDrawerContent, OneOnOneSettingsDrawerContent } from ".";
import { AvatarGroup, SignalRGroup, useDrawer } from "../../../../components";
import { Links } from "../../../../constants";
import { useSession, useSignalR } from "../../../../hooks";
import { OneOnOneMeetingDto, queryKeys } from "../../../../http";

interface IParams {
  userId: string;
}

interface IProps {
  meeting: OneOnOneMeetingDto;
}

const OneOnOneMeetingListItem = (props: IProps) => {
  const { userId: pageUserGuid } = useParams<keyof IParams>() as IParams;
  const { showDrawer } = useDrawer();
  const sessionUser = useSession();
  const theme = useTheme();
  const navigate = useNavigate();
  const signalR = useSignalR();
  const queryClient = useQueryClient();

  const inProgress = props.meeting.currentInstance != null;
  const isUserInvited =
    sessionUser.userId === props.meeting.leadUser.userId || sessionUser.userId === props.meeting.otherUser.userId;
  const lastInstance =
    props.meeting.lastInstanceDateTimeUtc != null
      ? DateTime.fromISO(props.meeting.lastInstanceDateTimeUtc, { zone: "utc" })
          .toLocal()
          .toLocaleString(DateTime.DATE_MED)
      : null;

  useEffect(() => {
    signalR.joinGroup(SignalRGroup.MeetingListItem(props.meeting.guid));
    return () => signalR.leaveGroup(SignalRGroup.MeetingListItem(props.meeting.guid));
  }, [props.meeting.guid, signalR]);

  useEffect(() => {
    const handleShouldRefetchMeetingListItem = () => {
      queryClient.invalidateQueries(queryKeys.meetings.getOneOnOneMeetingsForUser(pageUserGuid));
    };

    signalR.onShouldRefetchMeetingListItem(handleShouldRefetchMeetingListItem);
    return () => signalR.offShouldRefetchMeetingListItem(handleShouldRefetchMeetingListItem);
  }, [queryClient, signalR, pageUserGuid]);

  function handleSettingsClicked(meetingGuid: string) {
    showDrawer({
      title: <Typography variant="h6">1:1 Meeting Settings</Typography>,
      content: <OneOnOneSettingsDrawerContent meetingGuid={meetingGuid} />,
    });
  }

  function handleMeetingNotesClicked(meetingGuid: string) {
    showDrawer({
      title: (
        <Stack>
          <Typography variant="h6">My Meeting Notes</Typography>
          <Typography variant="caption">Meeting notes are accessible only to {sessionUser.displayName}.</Typography>
        </Stack>
      ),
      content: <MeetingNotesDrawerContent meetingGuid={meetingGuid} />,
    });
  }

  return (
    <Stack spacing={1} sx={{ p: 1, backgroundColor: inProgress ? theme.palette.primary.opacity5 : undefined }}>
      <Stack spacing={0.5}>
        <Stack direction="row" spacing={0.5}>
          {props.meeting.leadUser.userId === pageUserGuid && <Chip label="As Lead" />}
          {props.meeting.otherUser.userId === pageUserGuid && <Chip label="With Lead" />}
          {inProgress && <Chip color="primary" label="In Progress" />}
        </Stack>
        <Box>
          <Typography variant="h5">
            1:1 {props.meeting.otherUser.displayName}, {props.meeting.leadUser.displayName}
          </Typography>
          <Typography>Discuss work progress, wins, concerns and offer feedback.</Typography>
          <Stack
            direction="row"
            spacing={0.25}
            divider={<Divider flexItem orientation="vertical" sx={{ height: 12, alignSelf: "center" }} />}
            sx={{ alignItems: "center" }}
          >
            <Typography variant="caption">{props.meeting.instancesCount} Occurrences</Typography>
            {lastInstance != null && <Typography variant="caption">Last Meeting {lastInstance}</Typography>}
            {isUserInvited && (
              <Button
                variant="text"
                size="small"
                sx={{ minWidth: 0 }}
                disableRipple
                onClick={() => handleSettingsClicked(props.meeting.guid)}
              >
                Settings
              </Button>
            )}
            {isUserInvited && (
              <Button
                variant="text"
                size="small"
                disableRipple
                onClick={() => handleMeetingNotesClicked(props.meeting.guid)}
              >
                My Meeting Notes
              </Button>
            )}
          </Stack>
        </Box>
      </Stack>
      <AvatarGroup users={[props.meeting.leadUser, props.meeting.otherUser]} max={2} />

      {isUserInvited && (
        <Button
          variant="tertiary"
          sx={{ alignSelf: "start" }}
          onClick={() => navigate(Links.Meeting(props.meeting.guid))}
        >
          {inProgress ? "Join Meeting" : "Start Meeting"}
        </Button>
      )}
    </Stack>
  );
};

export { OneOnOneMeetingListItem };
