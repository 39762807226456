import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import { AddIssueOrTaskButton } from "../../../../components";
import { ReviewsOutlined } from "@mui/icons-material";
import { ISurveyResponseAnswer } from "../../../../models";

interface IProps {
  responseAnswer: ISurveyResponseAnswer;
}

const TextResponse = (props: IProps) => {
  const theme = useTheme();

  return (
    <Stack spacing={0.5}>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.25}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ color: theme.palette.grey[300], height: "15px", alignSelf: "center" }}
            />
          }
        >
          <Typography variant="caption">{props.responseAnswer.teamName}</Typography>
          <Typography variant="caption">
            {DateTime.fromISO(props.responseAnswer.submittedOnDateTimeUtc, { zone: "utc" })
              .toLocal()
              .toLocaleString(DateTime.DATE_MED)}
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{ color: theme.palette.common.black }}>
          {props.responseAnswer.questionText}
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ color: theme.palette.common.black }}>
        {props.responseAnswer.textAnswer}
      </Typography>

      <AddIssueOrTaskButton
        startIcon={<ReviewsOutlined />}
        text="Process Response"
        createdFromSurveyResponseAnswer={props.responseAnswer}
      />
    </Stack>
  );
};

export { TextResponse };
