export * from "./links";
export * from "./paths";

export const DEFAULT_TITLE = "CODE";
export const DEFAULT_DRAWER_WIDTH = 400;

export enum SearchFilter {
  All = "all",
  Teams = "teams",
  Roles = "roles",
  People = "people",
}

export enum SearchResultType {
  Team,
  Role,
  User,
}

export enum SearchResultChipType {
  Team,
  Role,
  User,
}

export enum ConnectObjectType {
  Team = "Team",
  Role = "Role",
  PrimaryRole = "Primary Role",
  Person = "Person",
  Metric = "Critical Metric",
}

export enum TeamTab {
  Overview = "Overview",
  StrategicMap = "Strategic Map",
  Implementation = "Implementation",
  Metrics = "Metrics",
  Resources = "Resources",
}

export enum MeetingAgendaItemType {
  CheckIn = 0,
  PersonalMapReview = 1,
  TeamMapReview = 2,
  RoleReview = 3,
  FeedbackReview = 4,
}

export enum UserTab {
  Overview = "Overview",
  Map = "Map",
  Meetings = "Meetings",
}

export enum SurveyTab {
  Questions = "Questions",
  Responses = "Responses",
  Actions = "Follow up actions",
}

export const GRAPH_COLORS = [
  "#124143",
  "#1E6131",
  "#198590",
  "#33C1E0",
  "#DADEE0",
  "#F4D172",
  "#F98E15",
  "#7D1911",
  "#4A0F0A",
];

export enum SystemStatus {
  "Created" = 1,
  "Edited" = 2,
  "Deleted" = 3,
}

export enum ActionItemStatus {
  "OnTrack" = 1,
  "AtRisk" = 2,
  "OffTrack" = 3,
  "Completed" = 4,
  "Open" = 6,
  "Paused" = 7,
  "Cancelled" = 8,
}

export const ClosedActionItemStatuses = [ActionItemStatus.Completed, ActionItemStatus.Cancelled];

export enum ActionItemPropertyType {
  Status = "status",
  Ownership = "ownership",
  Notes = "notes",
  DueDate = "dueDate",
  SupportingItems = "supportingItems",
}

export enum SupportingItemType {
  ActionItem = "actionItem",
  Issue = "issue",
}

export interface IStatus {
  id: number;
  name: string;
  color: string;
}

export enum CommentType {
  "HistoryLedgerItem" = 0,
  "Comment" = 1,
}

export enum CommentSubject {
  "Comment" = 1,
  "Team" = 2,
  "User" = 3,
  "Role" = 4,
  "PlanMetric" = 13,
  "ActionItem" = 14,
  "Issue" = 15,
  "WikiPage" = 16,
}

export enum TagType {
  Role = "role",
  SurveyQuestion = "surveyQuestion",
}

export const ACTION_ITEM_STATUSES: IStatus[] = [
  { id: ActionItemStatus.Open, name: "Open", color: "#C9C9C9" },
  { id: ActionItemStatus.OnTrack, name: "On Track", color: "#45A324" },
  { id: ActionItemStatus.AtRisk, name: "At Risk", color: "#F2994A" },
  { id: ActionItemStatus.OffTrack, name: "Off Track", color: "#E02626" },
  { id: ActionItemStatus.Paused, name: "Paused", color: "#8C8C8C" },
  { id: ActionItemStatus.Completed, name: "Completed", color: "#45A324" },
  { id: ActionItemStatus.Cancelled, name: "Cancelled", color: "#8C8C8C" },
];

export enum QuestionType {
  Text = 1,
  LinearScale = 2,
  TitleAndDescription = 3,
}

export const DEFAULT_ACTION_ITEM_STATUS = ActionItemStatus.Open;

export enum FeedItemType {
  PropertyChange = 1,
  Comment = 2,
}

export enum FeedItemProperty {
  Description = "description",
  ActionItemStatus = "actionItemStatus",
  IssueStatus = "issueStatus",
  ActionItemOwnership = "actionItemOwnership",
  ActionItemNote = "actionItemNote",
  Proposal = "proposal",
  SelectedProposal = "selectedProposal",
  Context = "context",
  DueDate = "dueDate",
  Note = "note",
}
