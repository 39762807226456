import { AddRounded, InfoOutlined, MoreVertRounded } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Menu, MenuItem, Stack, Tooltip, styled, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { v4 as newGuid } from "uuid";
import { Accordion, ActionItem, Typography } from "..";
import { ClosedActionItemStatuses } from "../../constants";
import { useSession, useSettings } from "../../hooks";
import { useCreatePlanActionItem, useDeleteSection, useEditSection } from "../../http";
import { IBlock, ISection } from "../../models";
import { lexorank, useDestructiveConfirm } from "../../utilities";
import { DraggableActionItem } from "./DraggableActionItem";
import { EditSectionModal } from "./EditSectionModal";
import { usePlanContext } from "./PlanContext";
import { ActionItemGrid } from "./ActionItemGrid";

const DroppableZone = styled(Stack)<{ $isDraggingOver: boolean; $isPastView: boolean }>`
  transition: ${({ theme }) => theme.transitions.create("background-color")};

  background-color: ${({ $isDraggingOver, $isPastView, theme }) =>
    $isDraggingOver ? theme.palette.primary.opacity5 : $isPastView ? "transparent" : theme.palette.common.white};
  pointer-events: ${({ $isDraggingOver }) => ($isDraggingOver ? "none" : "auto")};
`;

interface IProps {
  section: ISection;
  sections: ISection[];
  block: IBlock;
  blocks: IBlock[];
  isPastView: boolean;
  canEditPlan: boolean;
  isRowView: boolean;
}

const Section = (props: IProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showClosed, setShowClosed] = useState(false);
  const destructiveConfirm = useDestructiveConfirm();
  const { mutate: editSection } = useEditSection();
  const { mutate: deleteSection } = useDeleteSection();
  const { mutate: createPlanActionItem } = useCreatePlanActionItem();
  const user = useSession();
  const { planGuid } = usePlanContext();
  const [isEditSectionModalVisible, setIsEditSectionModalVisible] = useState(false);
  const [focusedActionItemGuid, setFocusedActionItemGuid] = useState<string | undefined>(undefined);
  const theme = useTheme();

  const isFirstSection = props.sections.indexOf(props.section) === 0;
  const isLastSection = props.sections.indexOf(props.section) === props.sections.length - 1;

  const activeItems =
    props.section.actionItems.filter((actionItem) => !ClosedActionItemStatuses.includes(actionItem.currentStatus)) ??
    [];

  const closedItems =
    props.section.actionItems.filter((actionItem) => ClosedActionItemStatuses.includes(actionItem.currentStatus)) ?? [];

  function handleMenuItemClicked(e: React.MouseEvent) {
    setMenuAnchor(null);
    e.stopPropagation();
  }

  async function handleAddActionItemClicked(e: React.MouseEvent) {
    e.stopPropagation();

    if (!isExpanded) {
      setIsExpanded(true);
    }

    const transitionDuration = !isExpanded ? theme.transitions.duration.standard : 0;

    // Wait for the accordion expansion transition to complete
    setTimeout(() => {
      const newActionItemGuid = newGuid();
      const sortOrder = lexorank.getPrevRank(activeItems);

      const isCreatingOnUserPlan = planGuid === user.planGuid;

      createPlanActionItem({
        planGuid: planGuid,
        sectionGuid: props.section.guid,
        owners: isCreatingOnUserPlan
          ? [
              {
                userId: user.userId,
                profilePhotoSmallUrl: user.profilePhotoSmallUrl,
                displayName: user.displayName,
                enabled: true,
              },
            ]
          : [],
        guid: newActionItemGuid,
        sortOrder: sortOrder,
        createdByUserId: user.userId,
      });

      setFocusedActionItemGuid(newActionItemGuid);
    }, transitionDuration);
  }

  async function handleEditClicked(e: React.MouseEvent) {
    handleMenuItemClicked(e);
    setIsEditSectionModalVisible(true);
  }

  async function handleMoveUpClicked(e: React.MouseEvent) {
    handleMenuItemClicked(e);

    const oldIndex = props.sections.indexOf(props.section);
    const newSortOrder = lexorank.getRank(props.sections, oldIndex, oldIndex - 1);

    editSection({
      planGuid: planGuid,
      guid: props.section.guid,
      blockGuid: props.block.guid,
      name: props.section.name,
      hintText: props.section.hintText,
      sortOrder: newSortOrder,
      modifiedByUserId: user.userId,
    });
  }

  async function handleMoveDownClicked(e: React.MouseEvent) {
    handleMenuItemClicked(e);

    const oldIndex = props.sections.indexOf(props.section);
    const newSortOrder = lexorank.getRank(props.sections, oldIndex, oldIndex + 1);

    editSection({
      planGuid: planGuid,
      guid: props.section.guid,
      blockGuid: props.block.guid,
      name: props.section.name,
      hintText: props.section.hintText,
      sortOrder: newSortOrder,
      modifiedByUserId: user.userId,
    });
  }

  async function handleMoveToBlock(e: React.MouseEvent, blockGuid: string) {
    handleMenuItemClicked(e);

    const newBlock = props.blocks.find((block) => block.guid === blockGuid);

    if (newBlock === undefined) {
      throw new Error("Invalid block");
    }

    const newSortOrder = lexorank.getNextRank(newBlock.sections);

    editSection({
      planGuid: planGuid,
      guid: props.section.guid,
      blockGuid: newBlock.guid,
      name: props.section.name,
      hintText: props.section.hintText,
      sortOrder: newSortOrder,
      modifiedByUserId: user.userId,
    });
  }

  async function handleDeleteClicked(e: React.MouseEvent) {
    handleMenuItemClicked(e);

    try {
      await destructiveConfirm({
        title: "Delete Section",
        content: "Deleting this section will delete all of its items as well.",
        confirmationText: "Delete",
        cancellationText: "Cancel",
      });
    } catch {
      return;
    }

    deleteSection({
      planGuid: planGuid,
      guid: props.section.guid,
      modifiedByUserId: user.userId,
    });
  }

  const sortedActiveItems = activeItems.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder));
  const sortedClosedItems = closedItems.sort((a, b) =>
    DateTime.fromISO(a.statusLastModifiedDateTimeUtc, { zone: "utc" }) <
    DateTime.fromISO(b.statusLastModifiedDateTimeUtc, { zone: "utc" })
      ? 1
      : -1
  );

  const { featureFlags } = useSettings();
  const isGridFeatureEnabled = featureFlags.grids;

  return (
    <Stack>
      <Droppable key={props.section.guid} droppableId={props.section.guid}>
        {(provided, snapshot) => (
          <DroppableZone
            $isPastView={props.isPastView}
            $isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
            ref={provided.innerRef}
            onMouseEnter={() => {
              setIsHovering(true);
            }}
            onMouseLeave={() => {
              setIsHovering(false);
            }}
          >
            <Accordion
              title={
                <Stack direction="row" sx={{ alignItems: "center", minHeight: "35px", width: "100%" }}>
                  <Stack direction="row" spacing={0.25} flexGrow={1} alignItems="top">
                    <Typography variant="body2" sx={{ color: "grey.400" }}>
                      {props.section.name}
                    </Typography>
                    {!isExpanded && (
                      <Typography variant="caption" sx={{ position: "relative", top: 1 }}>
                        ({activeItems.length} Active, {closedItems.length} Closed)
                      </Typography>
                    )}
                    {props.section.hintText && (
                      <Tooltip title={props.section.hintText}>
                        <InfoOutlined sx={{ color: "grey.300", alignSelf: "center" }} />
                      </Tooltip>
                    )}
                  </Stack>

                  {(isHovering || !window.matchMedia("(pointer: fine)").matches) &&
                    !snapshot.isDraggingOver &&
                    props.canEditPlan &&
                    (props.section.isEditable || props.section.createActionItemAllowed) && (
                      <>
                        {props.section.createActionItemAllowed && (
                          <IconButton size="small" onClick={handleAddActionItemClicked}>
                            <AddRounded />
                          </IconButton>
                        )}

                        {props.section.isEditable && (
                          <>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                setMenuAnchor(e.currentTarget);
                              }}
                            >
                              <MoreVertRounded />
                            </IconButton>
                            <Menu
                              anchorEl={menuAnchor}
                              open={Boolean(menuAnchor)}
                              onClose={(e: React.PointerEvent) => {
                                setMenuAnchor(null);
                                e.stopPropagation();
                              }}
                            >
                              <MenuItem onClick={handleEditClicked}>Edit Section</MenuItem>
                              <MenuItem disabled={isFirstSection} onClick={handleMoveUpClicked}>
                                Move Section Up
                              </MenuItem>
                              <MenuItem disabled={isLastSection} onClick={handleMoveDownClicked}>
                                Move Section Down
                              </MenuItem>
                              {props.blocks
                                .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
                                .map((block, index) => (
                                  <MenuItem
                                    disabled={block.sections.includes(props.section)}
                                    key={index}
                                    onClick={(e) => handleMoveToBlock(e, block.guid)}
                                  >
                                    Move Section to "{block.name}"
                                  </MenuItem>
                                ))}
                              <MenuItem onClick={handleDeleteClicked} sx={{ color: theme.palette.error.main }}>
                                Delete Section
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </>
                    )}
                </Stack>
              }
              expanded={isExpanded}
              onExpandClicked={() => {
                if (showClosed && isExpanded) {
                  setShowClosed(false);
                }

                setIsExpanded(!isExpanded);
              }}
              backgroundColorOnHover={props.isPastView ? "transparent" : undefined}
            >
              {props.isRowView && isGridFeatureEnabled ? (
                <ActionItemGrid actionItems={sortedActiveItems} planGuid={planGuid} section={props.section} />
              ) : (
                sortedActiveItems.map(
                  (actionItem, index) =>
                    !ClosedActionItemStatuses.includes(actionItem.currentStatus) && (
                      <DraggableActionItem
                        key={actionItem.guid}
                        index={index}
                        actionItemGuid={actionItem.guid}
                        planGuid={planGuid}
                        canEdit={actionItem.userCanEdit && !props.isPastView}
                        canEditPlan={props.canEditPlan}
                        isPastView={props.isPastView}
                        isFocused={actionItem.guid === focusedActionItemGuid}
                        onBlur={() => setFocusedActionItemGuid(undefined)}
                      />
                    )
                )
              )}
            </Accordion>
            {provided.placeholder}
          </DroppableZone>
        )}
      </Droppable>

      {closedItems.length > 0 && isExpanded && (
        <Box sx={{ px: 1, my: 0.5 }}>
          <Button variant="tertiary" size="small" onClick={() => setShowClosed(!showClosed)}>
            {!showClosed ? `Show Closed (${closedItems.length})` : `Hide Closed`}
          </Button>
        </Box>
      )}

      {closedItems.length > 0 && showClosed && (
        <>
          <Divider variant="dashed" orientation="horizontal" sx={{ mx: 1 }} />
          <Stack divider={<Divider variant="dashed" orientation="horizontal" sx={{ mx: 1 }} />}>
            {props.isRowView && isGridFeatureEnabled ? (
              <ActionItemGrid
                actionItems={sortedClosedItems}
                planGuid={planGuid}
                section={props.section}
                enableRowReordering={false}
              />
            ) : (
              sortedClosedItems.map((actionItem, _) => (
                <ActionItem
                  key={actionItem.guid}
                  actionItemGuid={actionItem.guid}
                  planGuid={planGuid}
                  canEdit={actionItem.userCanEdit && !props.isPastView}
                  canEditPlan={props.canEditPlan}
                  isPastView={props.isPastView}
                  isFocused={actionItem.guid === focusedActionItemGuid}
                  onBlur={() => setFocusedActionItemGuid(undefined)}
                  sx={{ px: 1 }}
                />
              ))
            )}
          </Stack>
          <Divider variant="dashed" orientation="horizontal" sx={{ mx: 1 }} />
        </>
      )}

      <EditSectionModal
        visible={isEditSectionModalVisible}
        section={props.section}
        blockGuid={props.block.guid}
        onSaved={() => setIsEditSectionModalVisible(false)}
        onCancelled={() => setIsEditSectionModalVisible(false)}
      />
    </Stack>
  );
};

export { Section };
