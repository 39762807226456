import { Link, Typography } from "@mui/material";
import { Links } from "../../constants";
import { FeedItemDto } from "../../http";

interface IProps {
  feedItem: FeedItemDto;
}

const NotePropertyChange = (props: IProps) => {
  return (
    <Typography variant="caption">
      <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
      <span> updated notes</span>
    </Typography>
  );
};

export { NotePropertyChange };
