import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { configuration } from "../../../configuration";
import { queryKeys } from "../queryKeys";

type Variables = {
  meetingGuid: string;
};

const useDeleteMeetingRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteMeetingRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/meetings/${variables.meetingGuid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteMeetingRequest };
};

const useDeleteMeeting = () => {
  const { deleteMeetingRequest } = useDeleteMeetingRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteMeetingRequest,
    onSettled: (_data, _error) => {
      queryClient.invalidateQueries(queryKeys.meetings.getOneOnOneMeetingsForAllUsers());
    },
  });
};

export { useDeleteMeeting };
