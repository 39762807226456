import { Link, Stack, Typography } from "@mui/material";
import { Links, SystemStatus } from "../../constants";
import { FeedItemDto, SurveyResponseAnswerDto } from "../../http";
import { SurveyResponseAnswerView } from "..";

interface IProps {
  feedItem: FeedItemDto;
}

const isCreatedFromSurveyResponseAnswer = (value: any): value is SurveyResponseAnswerDto => {
  return typeof value === "object" && value !== null && "textAnswer" in value;
};

const DescriptionPropertyChange = (props: IProps) => {
  if (props.feedItem.propertyChange!.changeType === SystemStatus.Created) {
    let createdFromObject = props.feedItem.propertyChange?.value;
    return isCreatedFromSurveyResponseAnswer(createdFromObject) ? (
      <Stack spacing={0.5}>
        <Typography variant="caption">
          <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
          <span> created this item based on the survey response:</span>
        </Typography>
        <SurveyResponseAnswerView surveyResponseAnswer={createdFromObject} compact />
      </Stack>
    ) : (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> created this item</span>
      </Typography>
    );
  }

  if (props.feedItem.propertyChange!.changeType === SystemStatus.Deleted) {
    return (
      <Typography variant="caption">
        <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
        <span> deleted this item</span>
      </Typography>
    );
  }

  return (
    <Typography variant="caption">
      <Link href={Links.User(props.feedItem.user.userId)}>{props.feedItem.user.displayName}</Link>
      <span> updated name to </span>
      <strong>{props.feedItem.propertyChange!.value}</strong>
    </Typography>
  );
};

export { DescriptionPropertyChange };
