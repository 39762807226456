import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { configuration } from "../../../configuration";
import { CommentSubject } from "../../../constants";
import { CommentDto, CreateCommentDto, CreateCommentResultDto, FetchError, GetCommentsDto, UserDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  subjectType: CommentSubject;
  subjectGuid: string;
  commentGuid: string;
  createdBy: UserDto;
  text: string;
};

const useCreateCommentRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createCommentRequest(variables: Variables): Promise<CreateCommentResultDto> {
    const accessToken = await getAccessToken();
    const body: CreateCommentDto = {
      subjectType: variables.subjectType,
      commentGuid: variables.commentGuid,
      createdByUserId: variables.createdBy.userId,
      text: variables.text,
    };

    const response = await fetch(`${configuration.apiRootUrl}/threads/${variables.subjectGuid}/comments`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { createCommentRequest };
};

const useCreateComment = () => {
  const { createCommentRequest } = useCreateCommentRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCommentRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.comments.getComments(variables.subjectGuid));
      const cachedData = queryClient.getQueryData<GetCommentsDto>(
        queryKeys.comments.getComments(variables.subjectGuid)
      );

      if (cachedData) {
        const newComment: CommentDto = {
          guid: variables.commentGuid,
          text: variables.text,
          createdBy: variables.createdBy,
          dateCreatedUtc: DateTime.utc().toISO(),
          lastModifiedUtc: DateTime.utc().toISO(),
        };

        const optimisticallyUpdatedData: GetCommentsDto = {
          ...cachedData,
          comments: [newComment, ...cachedData.comments],
        };

        queryClient.setQueryData(queryKeys.comments.getComments(variables.subjectGuid), optimisticallyUpdatedData);
        return { cachedData };
      }
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.comments.getComments(variables.subjectGuid), context?.cachedData);
    },
    onSuccess: (_data, variables) => {
      switch (variables.subjectType) {
        case CommentSubject.ActionItem:
          queryClient.invalidateQueries(queryKeys.actionItems.getActionItemFeed(variables.subjectGuid));
          queryClient.invalidateQueries(queryKeys.actionItems.getActionItemSummary(variables.subjectGuid));
          break;
        case CommentSubject.Issue:
          queryClient.invalidateQueries(queryKeys.issues.getIssueFeed(variables.subjectGuid));
          break;
      }
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.comments.getComments(variables.subjectGuid));
    },
  });
};

export { useCreateComment };
