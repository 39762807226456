import { QueryClient, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { DeleteIssueDto, FetchError, GetIssuesDto, IssueDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { useSignalR } from "../../../hooks";

type Variables = {
  guid: string;
  teamSlug: string;
  createdByUserId: string;
  modifiedByUserId: string;
};

const useDeleteIssueRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function deleteIssueRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: DeleteIssueDto = {
      modifiedByUserId: variables.modifiedByUserId,
    };

    const response = await fetch(`${configuration.apiRootUrl}/issues/${variables.guid}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "delete",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { deleteIssueRequest };
};

const useDeleteIssue = () => {
  const { deleteIssueRequest } = useDeleteIssueRequest();
  const queryClient = useQueryClient();
  const signalR = useSignalR();

  return useMutation({
    mutationFn: deleteIssueRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.issues.getIssueDetail(variables.guid));

      const cachedTeamData = optimisticallyUpdateIssuesData(
        queryClient,
        queryKeys.issues.getIssues(variables.teamSlug, undefined),
        variables
      );

      const cachedUserData = optimisticallyUpdateIssuesData(
        queryClient,
        queryKeys.issues.getIssues(undefined, variables.createdByUserId),
        variables
      );

      const cachedIssueDetailsData = queryClient.getQueryData<IssueDto>(
        queryKeys.issues.getIssueDetail(variables.guid)
      );

      queryClient.removeQueries(queryKeys.issues.getIssueDetail(variables.guid));

      return { cachedTeamData, cachedUserData, cachedIssueDetailsData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(queryKeys.issues.getIssueDetail(variables.guid), context?.cachedIssueDetailsData);
      queryClient.setQueryData(queryKeys.issues.getIssues(variables.teamSlug, undefined), context?.cachedTeamData);
      queryClient.setQueryData(
        queryKeys.issues.getIssues(undefined, variables.createdByUserId),
        context?.cachedUserData
      );
    },
    onSuccess: (_data, variables) => {
      signalR.sendRefetchTeamIssues(variables.teamSlug);
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.issues.getIssues(variables.teamSlug, undefined));
      queryClient.invalidateQueries(queryKeys.issues.getIssues(undefined, variables.createdByUserId));
    },
  });
};

async function optimisticallyUpdateIssuesData(queryClient: QueryClient, queryKey: QueryKey, variables: Variables) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<GetIssuesDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: GetIssuesDto = {
      ...cachedData,
      issues: cachedData.issues.filter((issue) => issue.guid !== variables.guid),
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useDeleteIssue };
