import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PlanHeader, PlanVersionsDrawerContent } from ".";
import { Typography, useDrawer } from "..";
import { useBreakpoints, usePlanToCsv, useSettings } from "../../hooks";
import { useGetPlan } from "../../http";
import { IPlan } from "../../models";
import { Blocks } from "./Blocks";

interface IProps {
  planGuid: string;
  headerContent: (args: {
    isLoading: boolean;
    isPastView: boolean;
    handleVersionsClicked: () => void;
    handleExportClicked: (filename: string) => void;
    plan?: IPlan;
    dateTimeLocal?: string | null;
    dateTimeLocalUtcOffsetInMinutes?: number | null;
  }) => React.ReactNode;
  canEdit?: boolean;
}

const Plan = (props: IProps) => {
  const [queryParams] = useSearchParams();
  const [dateTimeLocal, setDateTimeLocal] = useState<string>();
  const [dateTimeLocalUtcOffsetInMinutes, setDateTimeLocalUtcOffsetInMinutes] = useState<number>();
  const { data, isLoading } = useGetPlan(props.planGuid, dateTimeLocal, dateTimeLocalUtcOffsetInMinutes);
  const { convertAndDownloadPlanAsCsv } = usePlanToCsv(props.planGuid);
  const [plan, setPlan] = useState<IPlan>();
  const [isRowView, setRowView] = useState(false);
  const [isPastView, setIsPastView] = useState(false);
  const container = React.useRef<HTMLDivElement>(null);
  const { showDrawer } = useDrawer();

  useEffect(() => {
    const date = queryParams.get("date");
    const offset = queryParams.get("offset");

    setDateTimeLocal(date ?? undefined);
    setDateTimeLocalUtcOffsetInMinutes(offset ? Number(offset) : undefined);
    setIsPastView(date != null && offset != null);
  }, [queryParams, plan]);

  useEffect(() => {
    if (data !== undefined) {
      setPlan(data);
    }
  }, [data]);

  const { featureFlags } = useSettings();
  const isGridFeatureEnabled = featureFlags.grids;

  const { isMdUp } = useBreakpoints();

  useEffect(() => {
    if (!isMdUp) {
      setRowView(false);
    }
  }, [isMdUp]);

  return (
    <Stack ref={container} spacing={{ xs: 0.75, md: 1 }}>
      <PlanHeader
        planGuid={props.planGuid}
        content={props.headerContent({
          isLoading: isLoading,
          isPastView: isPastView,
          handleVersionsClicked: () =>
            showDrawer({
              title: <Typography variant="h6">Version History</Typography>,
              content: <PlanVersionsDrawerContent planGuid={props.planGuid} planLastModified={plan?.lastModifiedUtc} />,
            }),
          handleExportClicked: (filename) => convertAndDownloadPlanAsCsv(filename),
          plan: plan,
          dateTimeLocal: dateTimeLocal,
          dateTimeLocalUtcOffsetInMinutes: dateTimeLocalUtcOffsetInMinutes,
        })}
        isRowView={isRowView}
        isPastView={isPastView}
        onViewToggled={(value) => setRowView(value)}
      />

      {!isLoading && plan !== undefined && (
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item sx={{ width: "100%" }} md={isRowView && !isGridFeatureEnabled ? 8 : false} sm={12}>
            <Blocks
              plan={plan}
              isRowView={isRowView}
              isPastView={isPastView}
              canEdit={props.canEdit}
              onActionItemSortOrderChanged={(updatedPlan) => setPlan(updatedPlan)}
            />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export { Plan };
