import { AddRounded, AddTaskRounded } from "@mui/icons-material";
import { alpha, Box, Button, Divider, IconButton, Skeleton, Stack, styled, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { v4 as newGuid } from "uuid";
import { Accordion, ActionItem, DraggableActionItem } from "../../../../components";
import { ClosedActionItemStatuses } from "../../../../constants";
import { useSession, useSettings } from "../../../../hooks";
import {
  ActionItemDto,
  useCreateActionToDo,
  useEditActionItemSortOrder,
  useGetTeamActionItems,
} from "../../../../http";
import { lexorank } from "../../../../utilities";
import { ActionItemGrid } from "../../../../components/plans/ActionItemGrid";

const Container = styled(Box)`
  border: solid;
  border-color: ${({ theme }) => alpha(theme.palette.common.black, 0.1)};
  border-width: 1px;
  border-radius: 8px;
`;

const DroppableZone = styled(Stack)<{ $isDraggingOver: boolean }>`
  transition: ${({ theme }) => theme.transitions.create("background-color")};

  background-color: ${({ $isDraggingOver, theme }) =>
    $isDraggingOver ? theme.palette.primary.opacity5 : "transparent"};
  pointer-events: ${({ $isDraggingOver }) => ($isDraggingOver ? "none" : "auto")};
`;

interface IProps {
  teamSlug: string | undefined;
}

const TeamTasksList = (props: IProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [showClosed, setShowClosed] = useState(false);
  const { data, isLoading } = useGetTeamActionItems(props.teamSlug);
  const [actionItems, setActionItems] = useState<ActionItemDto[]>([]);
  const { mutate: createTeamActionItem } = useCreateActionToDo();
  const { mutate: editActionItemSortOrder } = useEditActionItemSortOrder();
  const [focusedActionItemGuid, setFocusedActionItemGuid] = useState<string | undefined>();
  const { displayName } = useSession();
  const theme = useTheme();

  const activeActionItems =
    actionItems.filter((actionItem) => !ClosedActionItemStatuses.includes(actionItem.currentStatus)) ?? [];
  const closedActionItems =
    actionItems.filter((actionItem) => ClosedActionItemStatuses.includes(actionItem.currentStatus)) ?? [];

  useEffect(() => {
    if (data !== undefined) {
      setActionItems(data.actionItems);
    }
  }, [data]);

  function handleAddClicked(e: React.MouseEvent) {
    e.stopPropagation();

    if (!isExpanded) {
      setIsExpanded(true);
    }

    const transitionDuration = !isExpanded ? theme.transitions.duration.standard : 0;

    // Wait for the accordion expansion transition to complete
    setTimeout(() => {
      if (props.teamSlug && data?.actionItems) {
        const newTeamActionItemGuid = newGuid();

        createTeamActionItem({
          guid: newTeamActionItemGuid,
          teamSlug: props.teamSlug,
          description: "",
          note: "",
          owners: [],
          sortOrder: lexorank.getRankAtIndex(actionItems, 0),
          createdByDisplayName: displayName,
        });

        setFocusedActionItemGuid(newTeamActionItemGuid);
      }
    }, transitionDuration);
  }

  function handleDragEnd(result: DropResult) {
    const { draggableId: actionItemGuid, destination, source } = result;

    // User dragged the item outside the container
    if (!destination) {
      return;
    }

    // User dragged the item back to where it was
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newSortOrder = lexorank.getRank(activeActionItems, source.index, destination.index);

    setActionItems([
      ...actionItems.map((actionItem) => {
        if (actionItem.guid === actionItemGuid) {
          return {
            ...actionItem,
            sortOrder: newSortOrder,
          };
        }

        return actionItem;
      }),
    ]);

    editActionItemSortOrder({
      actionItemGuid: actionItemGuid,
      sectionGuid: null,
      sortOrder: newSortOrder,
    });
  }

  const sortedClosedActionItems = closedActionItems.sort((a, b) =>
    DateTime.fromISO(a.statusLastModifiedDateTimeUtc, { zone: "utc" }) <
    DateTime.fromISO(b.statusLastModifiedDateTimeUtc, { zone: "utc" })
      ? 1
      : -1
  );

  const { featureFlags } = useSettings();
  const isGridFeatureEnabled = featureFlags.grids;

  return (
    <Container>
      <Stack>
        {isGridFeatureEnabled ? (
          <Accordion
            title={
              <Stack direction="row" spacing={0.25} flexGrow={1} alignItems="top">
                <AddTaskRounded sx={{ alignSelf: "center" }} />
                <Typography variant="body2" sx={{ color: "grey.400" }}>
                  Tasks
                </Typography>
                {!isExpanded && (
                  <Typography variant="caption" sx={{ position: "relative", top: 2 }}>
                    ({activeActionItems.length} Active, {closedActionItems.length} Closed)
                  </Typography>
                )}
              </Stack>
            }
            hoverActions={
              <IconButton size="small" onClick={handleAddClicked}>
                <AddRounded />
              </IconButton>
            }
            expanded={isExpanded}
            hideTitleUnderline={!isExpanded}
            onExpandClicked={() => {
              if (showClosed && isExpanded) {
                setShowClosed(false);
              }

              setIsExpanded(!isExpanded);
            }}
          >
            <ActionItemGrid
              actionItems={activeActionItems.sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))}
              teamGuid={props.teamSlug}
            />
          </Accordion>
        ) : (
          <>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable key="teamActionItems" droppableId="teamActionItemds">
                {(provided, snapshot) => (
                  <DroppableZone
                    ref={provided.innerRef}
                    $isDraggingOver={snapshot.isDraggingOver}
                    {...provided.droppableProps}
                  >
                    <Accordion
                      title={
                        <Stack direction="row" spacing={0.25} flexGrow={1} alignItems="top">
                          <AddTaskRounded sx={{ alignSelf: "center" }} />
                          <Typography variant="body2" sx={{ color: "grey.400" }}>
                            Tasks
                          </Typography>
                          {!isExpanded && (
                            <Typography variant="caption" sx={{ position: "relative", top: 2 }}>
                              ({activeActionItems.length} Active, {closedActionItems.length} Closed)
                            </Typography>
                          )}
                        </Stack>
                      }
                      hoverActions={
                        <IconButton size="small" onClick={handleAddClicked}>
                          <AddRounded />
                        </IconButton>
                      }
                      expanded={isExpanded}
                      hideTitleUnderline={!isExpanded}
                      onExpandClicked={() => {
                        if (showClosed && isExpanded) {
                          setShowClosed(false);
                        }

                        setIsExpanded(!isExpanded);
                      }}
                    >
                      {isLoading && (
                        <Stack
                          spacing={0.5}
                          divider={<Divider variant="dashed" />}
                          sx={{ mx: { xs: 0.5, sm: 1 }, my: 0.5 }}
                        >
                          {[...new Array(3)].map((_, index) => (
                            <Skeleton key={index} variant="rectangular" height={24} />
                          ))}
                        </Stack>
                      )}

                      {!isLoading && activeActionItems.length === 0 && (
                        <Typography sx={{ color: theme.palette.grey[300], py: 0.5, px: { xs: 0.5, sm: 1 } }}>
                          Nothing here yet.
                        </Typography>
                      )}

                      {!isLoading && activeActionItems.length > 0 && (
                        <>
                          {activeActionItems
                            .sort((a, b) => a.sortOrder.localeCompare(b.sortOrder))
                            .map((actionItem, index) => (
                              <DraggableActionItem
                                key={actionItem.guid}
                                index={index}
                                actionItemGuid={actionItem.guid}
                                canEdit={actionItem.userCanEdit}
                                canEditPlan={actionItem.userCanEdit}
                                isPastView={false}
                                isFocused={actionItem.guid === focusedActionItemGuid}
                                onBlur={() => setFocusedActionItemGuid(undefined)}
                              />
                            ))}
                        </>
                      )}
                    </Accordion>
                    {provided.placeholder}
                  </DroppableZone>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
        {closedActionItems.length > 0 && isExpanded && (
          <Box sx={{ px: { xs: 0.5, sm: 1 }, my: 0.5 }}>
            <Button variant="tertiary" size="small" onClick={() => setShowClosed(!showClosed)}>
              {showClosed ? `Hide Closed` : `Show Closed (${closedActionItems.length})`}
            </Button>
          </Box>
        )}

        {closedActionItems.length > 0 && showClosed && (
          <>
            <Divider variant="dashed" orientation="horizontal" sx={{ mx: { xs: 0.5, sm: 1 } }} />
            <Stack divider={<Divider variant="dashed" orientation="horizontal" sx={{ mx: { xs: 0.5, sm: 1 } }} />}>
              {isGridFeatureEnabled ? (
                <ActionItemGrid actionItems={sortedClosedActionItems} enableRowReordering={false} />
              ) : (
                sortedClosedActionItems.map((actionItem) => (
                  <ActionItem
                    key={actionItem.guid}
                    actionItemGuid={actionItem.guid}
                    canEdit={actionItem.userCanEdit}
                    canEditPlan={actionItem.userCanEdit}
                    isPastView={false}
                    isFocused={actionItem.guid === focusedActionItemGuid}
                    onBlur={() => setFocusedActionItemGuid(undefined)}
                    sx={{ px: 1 }}
                  />
                ))
              )}
            </Stack>
            <Divider variant="dashed" orientation="horizontal" sx={{ mx: 1, mb: 1 }} />
          </>
        )}
      </Stack>
    </Container>
  );
};

export { TeamTasksList };
