import { useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";
import { useSignalR } from "../../../hooks";

type Variables = {
  issueGuid: string;
  sortOrder: string;
  teamSlug: string;
};

const useEditIssueSortOrderRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function editIssueSortOrderRequest(variables: Variables) {
    const accessToken = await getAccessToken();

    const response = await fetch(`${configuration.apiRootUrl}/issues/${variables.issueGuid}/sort-order`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify({
        sortOrder: variables.sortOrder,
      }),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { editIssueSortOrderRequest };
};

const useEditIssueSortOrder = () => {
  const { editIssueSortOrderRequest } = useEditIssueSortOrderRequest();
  const queryClient = useQueryClient();
  const signalR = useSignalR();

  return useMutation({
    mutationFn: editIssueSortOrderRequest,
    onSuccess: async (_, variables) => {
      signalR.sendRefetchTeamIssues(variables.teamSlug);

      await queryClient.refetchQueries({
        queryKey: queryKeys.issues.getIssues(variables.teamSlug, undefined),
      });
    },
  });
};

export { useEditIssueSortOrder };
