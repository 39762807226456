import { QueryClient, QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { MeetingAgendaItemType } from "../../../constants";
import { CreateOneOnOneMeetingDto, FetchError, GetOneOnOneMeetingsDto, OneOnOneMeetingDto, UserDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

type Variables = {
  meetingGuid: string;
  leadUser: UserDto;
  otherUser: UserDto;
};

const useCreateOneOnOneMeetingRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function createOneOnOneMeetingRequest(variables: Variables): Promise<void> {
    const accessToken = await getAccessToken();
    const body: CreateOneOnOneMeetingDto = {
      meetingGuid: variables.meetingGuid,
      leadUserId: variables.leadUser.userId,
      otherUserId: variables.otherUser.userId,
      agendaItems: [
        {
          name: "Check In",
          description:
            "Start with:<br />“What is on your mind today?” or <br />“What are you noticing?” or <br />“What has been most challenging for you lately?”",
          type: MeetingAgendaItemType.CheckIn,
          allocatedTimeInMinutes: null,
        },
        {
          name: "Map Review",
          description:
            "Personal map is reviewed with the special accent on personal current goals and how they are aligned with overall priorities of the team. New tasks or issues are created as needed.",
          type: MeetingAgendaItemType.PersonalMapReview,
          allocatedTimeInMinutes: null,
        },
        {
          name: "Roles Review",
          description:
            "Roles and accountabilities are reviewed. Growth plans are discussed. Issues and tasks are created accordingly.",
          type: MeetingAgendaItemType.RoleReview,
          allocatedTimeInMinutes: null,
        },
        {
          name: "Check Out",
          description:
            "Depending upon the time remaining, ask a question like: “Is there anything else?” or capture topics for next time and reminders in the Notes for next time.",
          type: MeetingAgendaItemType.CheckIn,
          allocatedTimeInMinutes: null,
        },
      ],
    };

    const response = await fetch(`${configuration.apiRootUrl}/meetings/one-on-ones`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
  }

  return { createOneOnOneMeetingRequest };
};

const useCreateOneOnOneMeeting = () => {
  const { createOneOnOneMeetingRequest } = useCreateOneOnOneMeetingRequest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createOneOnOneMeetingRequest,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(queryKeys.meetings.getOneOnOneMeetingsForUser(variables.leadUser.userId));
      await queryClient.cancelQueries(queryKeys.meetings.getOneOnOneMeetingsForUser(variables.otherUser.userId));

      let newMeeting: OneOnOneMeetingDto = {
        guid: variables.meetingGuid,
        leadUser: variables.leadUser,
        otherUser: variables.otherUser,
        currentInstance: null,
        currentParticipants: [],
        instancesCount: 0,
        lastInstanceDateTimeUtc: null,
      };

      const cachedLeadUserData = optimisticallyUpdateOneOnOneMeetingData(
        queryClient,
        queryKeys.meetings.getOneOnOneMeetingsForUser(variables.leadUser.userId),
        newMeeting
      );

      const cachedOtherUserData = optimisticallyUpdateOneOnOneMeetingData(
        queryClient,
        queryKeys.meetings.getOneOnOneMeetingsForUser(variables.otherUser.userId),
        newMeeting
      );

      return { cachedLeadUserData, cachedOtherUserData };
    },
    onError: (_error, variables, context) => {
      queryClient.setQueryData(
        queryKeys.meetings.getOneOnOneMeetingsForUser(variables.leadUser.userId),
        context?.cachedLeadUserData
      );
      queryClient.setQueryData(
        queryKeys.meetings.getOneOnOneMeetingsForUser(variables.otherUser.userId),
        context?.cachedOtherUserData
      );
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries(queryKeys.meetings.getOneOnOneMeetingsForUser(variables.leadUser.userId));
      queryClient.invalidateQueries(queryKeys.meetings.getOneOnOneMeetingsForUser(variables.otherUser.userId));
    },
  });
};

async function optimisticallyUpdateOneOnOneMeetingData(
  queryClient: QueryClient,
  queryKey: QueryKey,
  newOneOnOneMeeting: OneOnOneMeetingDto
) {
  await queryClient.cancelQueries(queryKey);
  const cachedData = queryClient.getQueryData<GetOneOnOneMeetingsDto>(queryKey);

  if (cachedData) {
    const optimisticallyUpdatedData: GetOneOnOneMeetingsDto = {
      ...cachedData,
      meetings: [newOneOnOneMeeting, ...cachedData.meetings],
    };

    queryClient.setQueryData(queryKey, optimisticallyUpdatedData);
    return cachedData;
  }
}

export { useCreateOneOnOneMeeting };
