import { $generateNodesFromDOM } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable as LexicalContentEditable } from "@lexical/react/LexicalContentEditable";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HeadingNode } from "@lexical/rich-text";
import { Box, css, styled, SxProps, Typography } from "@mui/material";
import { $getRoot, $insertNodes, $setSelection, LexicalEditor } from "lexical";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { HtmlOnBlurPlugin } from "./plugins/HtmlOnBlurPlugin";
import { HtmlOnChangePlugin } from "./plugins/HtmlOnChangePlugin";
import { ReadonlyPlugin } from "./plugins/ReadonlyPlugin";
import { ToolBarOption, ToolbarPlugin } from "./plugins/ToolbarPlugin";
import { theme } from "./theme";

const TextEditorContainer = styled(Box)<{ $readonly: boolean }>`
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  padding: 1px;
  width: 100%;

  ${({ $readonly }) =>
    !$readonly &&
    css`
      &:hover {
        border-width: 1px;
        border-color: #000;
      }

      &:focus-within {
        border-width: 2px;
        padding: 0px;
        border-color: ${({ theme }) => theme.palette.turquoise.main};
      }
    `}

  .editor-link {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .editor-heading-h1 {
    ${({ theme }) => theme.typography.h4};
  }

  .editor-heading-h2 {
    ${({ theme }) => theme.typography.h5};
  }

  .editor-heading-h3 {
    ${({ theme }) => theme.typography.h6};
  }

  .editor-list-ul,
  .editor-list-ol {
    margin: 0;
    padding-left: 20px;
  }

  .editor-list-ol2 {
    list-style-type: lower-alpha;
  }

  .editor-list-ol3 {
    list-style-type: lower-roman;
  }

  .editor-list-ol4 {
    list-style-type: disc;
  }

  .editor-list-ol5 {
    list-style-type: circle;
  }

  .editor-checklist {
    padding-left: 0;
  }

  .editor-listitem-checked,
  .editor-listitem-unchecked {
    position: relative;
    padding-left: 24px;
    list-style-type: none;
    outline: none;
  }

  .editor-listitem-checked {
    text-decoration: line-through;
  }

  .editor-listitem-checked:before,
  .editor-listitem-unchecked:before {
    content: "";
    width: 16px;
    height: 16px;
    top: 5px;
    left: 0;
    cursor: pointer;
    display: block;
    background-size: cover;
    position: absolute;
  }

  .editor-listitem-unchecked:focus:before,
  .editor-listitem-checked:focus:before {
    box-shadow: 0 0 0 2px #a6cdfe;
    border-radius: 2px;
  }

  .editor-listitem-unchecked:before {
    border: 1px solid #999;
    border-radius: 2px;
  }

  .editor-listitem-checked:before {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.palette.turquoise.main};
    background-repeat: no-repeat;
  }

  .editor-listitem-checked:after {
    content: "";
    cursor: pointer;
    border-color: #fff;
    border-style: solid;
    position: absolute;
    display: block;
    top: 8px;
    width: 4px;
    left: 6px;
    right: 7px;
    height: 8px;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
  }

  .editor-nested-listitem {
    list-style-type: none;
  }

  .editor-nested-listitem:before,
  .editor-nested-listitem:after {
    display: none;
  }

  .editor-text-bold {
    font-weight: bold;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-underline {
    text-decoration: underline;
  }

  .editor-text-strikethrough {
    text-decoration: line-through;
  }

  .editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
  }

  .editor-paragraph {
    margin: 0;
  }
`;

const TextEditorContentContainer = styled(Box)`
  position: relative;
  min-height: 45px;
`;

const TextEditorContentEditable = styled(LexicalContentEditable)`
  min-height: 45px;
  outline: 0px solid transparent;
  padding: 10px;
`;

const TextEditorPlaceholder = styled(Typography)`
  position: absolute;
  top: 10px;
  left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  pointer-events: none;
`;

function isWhitespace(value: string) {
  if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0 && !value.includes("<img")) {
    return true;
  }

  return false;
}

function htmlToNodes(editor: LexicalEditor, html: string) {
  if (html == null) {
    return [];
  }

  const parser = new DOMParser();
  const dom = parser.parseFromString(html, "text/html");

  return $generateNodesFromDOM(editor, dom);
}

interface TextEditorProps {
  initialValue: string;
  placeholder?: string;
  readonly?: boolean;
  toolbarOptions?: {
    [key in ToolBarOption]: boolean;
  };
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  ContentEditableProps?: TextEditorContentEditableProps;
}

interface TextEditorContentEditableProps {
  sx?: SxProps;
}

interface TextEditorRef {
  setContent(value: string): void;
  focus(): void;
}

const TextEditor = forwardRef<TextEditorRef, TextEditorProps>((props, ref) => {
  const editor = useRef<LexicalEditor>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        setContent,
        focus,
      };
    },
    []
  );

  function setContent(value: string) {
    if (editor.current == null) {
      return;
    }

    editor.current.update(() => {
      const root = $getRoot();
      root.clear();
      root.append(...htmlToNodes(editor.current!, value));
      $setSelection(null); // Prevent editor from gaining focus
    });
  }

  function focus() {
    editor.current?.focus();
  }

  return (
    // This Box prevents the EditorContainer's negative margin on focus from affecting the TextEditor's position
    // within Stacks (which apply a margin for spacing).
    <Box sx={{ width: "100%" }}>
      <TextEditorContainer $readonly={props.readonly ?? false}>
        <LexicalComposer
          initialConfig={{
            namespace: "TextEditor",
            onError: (e) => console.error(e),
            theme: theme,
            editable: props.readonly,
            editorState: (editor) => {
              editor.update(() => {
                $insertNodes(htmlToNodes(editor, props.initialValue));
                $setSelection(null); // Prevent editor from gaining focus
              });
            },
            nodes: [AutoLinkNode, LinkNode, ListNode, ListItemNode, HeadingNode],
          }}
        >
          <ToolbarPlugin options={props.toolbarOptions} />
          <TextEditorContentContainer>
            <EditorRefPlugin editorRef={editor} />
            <RichTextPlugin
              contentEditable={<TextEditorContentEditable sx={{ ...props.ContentEditableProps?.sx }} />}
              placeholder={
                props.placeholder ? (
                  <TextEditorPlaceholder variant="placeholder">{props.placeholder}</TextEditorPlaceholder>
                ) : null
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <TabIndentationPlugin />
            <ListPlugin />
            <CheckListPlugin />
            <LinkPlugin />
            <ReadonlyPlugin readonly={props.readonly} />
            <HtmlOnChangePlugin onChange={(value) => props.onChange?.(value)} />
            <HtmlOnBlurPlugin onBlur={(value) => props.onBlur?.(value)} />
          </TextEditorContentContainer>
        </LexicalComposer>
      </TextEditorContainer>
    </Box>
  );
});

export {
  htmlToNodes,
  isWhitespace,
  TextEditor,
  TextEditorContainer,
  TextEditorContentContainer,
  TextEditorPlaceholder,
};

export type { TextEditorRef };
