import { SxProps, Typography } from "@mui/material";
import { CompletedStatusAnimation } from "../../assets/animations";
import { ACTION_ITEM_STATUSES, ActionItemStatus, CommentSubject } from "../../constants";
import { StatusChangedDialogContent } from "../StatusChangedDialogContent";
import { Cancel, Circle, PauseCircle } from "@mui/icons-material";

interface IProps {
  actionItemGuid: string;
  status: ActionItemStatus;
  onDone: () => void;
  onCancel: () => void;
}

const ActionItemStatusChangedDialogContent = (props: IProps) => {
  function getIcon() {
    const iconSxProps: SxProps = {
      color: ACTION_ITEM_STATUSES.find((x) => x.id === props.status)!.color,
      width: 100,
      height: 100,
    };

    switch (props.status) {
      case ActionItemStatus.Completed:
        return <CompletedStatusAnimation />;
      case ActionItemStatus.Paused:
        return <PauseCircle sx={iconSxProps} />;
      case ActionItemStatus.Cancelled:
        return <Cancel sx={iconSxProps} />;
      default:
        return <Circle sx={iconSxProps} />;
    }
  }

  function getContentBody() {
    switch (props.status) {
      case ActionItemStatus.Completed:
        return `Congratulations! You've marked this card ${ACTION_ITEM_STATUSES.find(
          (x) => x.id === props.status
        )!.name.toUpperCase()}.`;
      default:
        return `You've marked this card ${ACTION_ITEM_STATUSES.find(
          (x) => x.id === props.status
        )!.name.toUpperCase()}.`;
    }
  }

  return (
    <StatusChangedDialogContent
      icon={getIcon()}
      title={ACTION_ITEM_STATUSES.find((x) => x.id === props.status)!.name.toUpperCase()}
      content={
        <Typography>
          {getContentBody()}
          <br />
          Please enter a comment with reasons for the change of status.
        </Typography>
      }
      commentSubjectType={CommentSubject.ActionItem}
      commentSubjectGuid={props.actionItemGuid}
      onDone={props.onDone}
      onCancel={props.onCancel}
    />
  );
};

export { ActionItemStatusChangedDialogContent };
