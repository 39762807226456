import { LinkRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { v4 as newGuid } from "uuid";
import {
  Autocomplete,
  ContextPicker,
  SmallInputLabel,
  SnackbarContent,
  SurveyResponseAnswerView,
  Typography,
} from "..";
import { Links } from "../../constants";
import { useSession } from "../../hooks";
import { useCreateIssue, useGetTeams } from "../../http";
import { ISurveyResponseAnswer, ITeamSummary } from "../../models";

interface UrlParams {
  slug: string;
}

interface ICreateIssueFormState {
  team: ITeamSummary | null;
  description: string;
  context?: {
    actionItemGuid: string;
    actionItemHistoryId: number;
  };
}

interface IProps {
  visible: boolean;
  context?: {
    teamName: string;
    teamSlug: string;
    actionItemGuid: string;
    actionItemDescription: string;
  };
  createdFromSurveyResponseAnswer?: ISurveyResponseAnswer;
  onCreated: () => void;
  onCancelled: () => void;
}

const CreateIssueModal = (props: IProps) => {
  const { slug } = useParams<keyof UrlParams>() as UrlParams;
  const { userId, displayName, profilePhotoSmallUrl } = useSession();
  const { mutate: createIssue, isLoading: isCreatingIssue } = useCreateIssue();
  const { isLoading: isLoadingTeams, data: teams } = useGetTeams(undefined, props.visible);
  const { enqueueSnackbar } = useSnackbar();

  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm<ICreateIssueFormState>({
    defaultValues: {
      team: null,
      description: "",
      context: undefined,
    },
  });

  useEffect(() => {
    if (teams && props.visible) {
      setValue("team", teams.find((x) => x.slug === slug) ?? null);
    }
  }, [props.visible, setValue, slug, teams]);

  async function handleAddClicked() {
    const newIssueGuid = newGuid();

    handleSubmit((formState) => {
      createIssue(
        {
          guid: newIssueGuid,
          description: formState.description,
          teamSlug: formState.team!.slug,
          teamName: formState.team!.name,
          contextActionItemGuid: formState.context?.actionItemGuid,
          contextActionItemHistoryId: formState.context?.actionItemHistoryId,
          createdFromSurveyResponseAnswerGuid: props.createdFromSurveyResponseAnswer?.answerGuid,
          createdByUser: { userId, displayName, profilePhotoSmallUrl, enabled: true },
        },
        {
          onSuccess: () => {
            reset(undefined, { keepDefaultValues: true });
            enqueueSnackbar(
              <SnackbarContent
                title="Issue Created"
                content={
                  <>
                    Issue added to{" "}
                    <Link href={Links.TeamImplementation(formState.team!.slug)}>{formState.team!.name}</Link>
                  </>
                }
              />,
              {
                variant: "success",
                action: <Button href={Links.IssueDetail(newIssueGuid)}>View Issue</Button>,
              }
            );
            props.onCreated();
          },
        }
      );
    })();
  }

  function handleClose(_: object, reason: string) {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    handleCancelClicked();
  }

  function handleCancelClicked() {
    reset(undefined, { keepDefaultValues: true });
    props.onCancelled();
  }

  return (
    <Dialog open={props.visible} onClose={handleClose}>
      <DialogTitle>Add Issue</DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <Stack spacing={1}>
          {props.context && (
            <Stack sx={{ px: 1 }}>
              <SmallInputLabel>Context</SmallInputLabel>
              <Stack direction="row" spacing={0.25} sx={{ alignItems: "start" }}>
                <LinkRounded sx={{ position: "relative", top: 4 }} />
                <Typography>
                  <Box component="span" sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
                    {props.context?.teamName}
                  </Box>
                  {` : ${props.context?.actionItemDescription}`}
                </Typography>
              </Stack>
            </Stack>
          )}

          <Stack spacing={0.25} sx={{ px: 1 }}>
            <SmallInputLabel>Description *</SmallInputLabel>
            <Controller
              name="description"
              control={control}
              rules={{ required: { message: "Issue description is required", value: true } }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors["description"] !== undefined}
                  helperText={errors["description"]?.message}
                  placeholder="Describe the issue..."
                  autoFocus
                  multiline
                />
              )}
            />
          </Stack>
          <Stack spacing={0.25} sx={{ px: 1 }}>
            <SmallInputLabel>Team To Process *</SmallInputLabel>
            <Controller
              name="team"
              control={control}
              rules={{
                required: {
                  message: "Team is required",
                  value: true,
                },
              }}
              render={({ field }) => (
                <Autocomplete
                  isLoading={isLoadingTeams}
                  disabled={isLoadingTeams}
                  options={teams ?? []}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <MenuItem key={option.slug} component="li" {...props}>
                      <Typography>{option.name}</Typography>
                    </MenuItem>
                  )}
                  onChange={(_, option) => field.onChange(option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select a team..."
                      inputProps={{ ...params.inputProps }}
                      error={errors["team"] !== undefined}
                      helperText={errors["team"]?.message}
                    />
                  )}
                  value={field.value}
                  isOptionEqualToValue={(option, value) => option.slug === value.slug}
                />
              )}
            />
          </Stack>
          {props.createdFromSurveyResponseAnswer && (
            <Stack spacing={1} sx={{ px: 1 }}>
              <Typography>Created issue will reference the survey response: </Typography>
              <SurveyResponseAnswerView surveyResponseAnswer={props.createdFromSurveyResponseAnswer} />
            </Stack>
          )}

          <Stack spacing={0.25} sx={{ display: props.context ? "none" : undefined, px: 1 }}>
            <SmallInputLabel>Connect To Context</SmallInputLabel>
            <Controller
              name="context"
              control={control}
              render={({ field }) => (
                <ContextPicker
                  disabled={props.context !== undefined}
                  defaultContext={props.context}
                  onSelect={(connectionPoint) =>
                    field.onChange({
                      actionItemGuid: connectionPoint?.guid,
                      actionItemHistoryId: connectionPoint?.historyId,
                    })
                  }
                />
              )}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isCreatingIssue} onClick={handleCancelClicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isCreatingIssue}
          endIcon={isCreatingIssue ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleAddClicked}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { CreateIssueModal };
