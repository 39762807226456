import { useQuery } from "@tanstack/react-query";
import { configuration } from "../../../configuration";
import { FetchError, GetUsersDto } from "../../types";
import { useAccessToken } from "../../use-access-token";
import { queryKeys } from "../queryKeys";

const useNewGetUsersRequest = () => {
  const { getAccessToken } = useAccessToken();

  async function getUsersRequest(): Promise<GetUsersDto> {
    const accessToken = await getAccessToken();
    const response = await fetch(`${configuration.apiRootUrl}/users`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new FetchError(response);
    }

    return response.json();
  }

  return { getUsersRequest };
};

const useNewGetUsers = (enabled: boolean = true) => {
  const { getUsersRequest } = useNewGetUsersRequest();

  return useQuery({
    queryKey: queryKeys.users.getUsers(),
    queryFn: getUsersRequest,
    enabled: enabled,
  });
};

export { useNewGetUsers };
