import { AddCircle, AddTask, ExpandMoreRounded, FlagOutlined } from "@mui/icons-material";
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { ReactNode, useState } from "react";
import { CreateTaskModal } from "./actionItems";
import { CreateIssueModal } from "./issues";
import { ISurveyResponseAnswer } from "../models";

interface IProps {
  startIcon?: ReactNode;
  text?: string;
  variant?: "tertiary" | "contained";
  context?: {
    teamName: string;
    teamSlug: string;
    actionItemGuid: string;
    actionItemDescription: string;
  };
  createdFromSurveyResponseAnswer?: ISurveyResponseAnswer;
  onItemAdded?: () => {};
}

const AddIssueOrTaskButton = (props: IProps) => {
  const [addMenuAnchor, setAddMenuAnchor] = useState<HTMLElement | null>(null);
  const [isIssueModalVisible, setIsIssueModalVisible] = useState(false);
  const [isTaskModalVisible, setIsTaskModalVisible] = useState(false);

  function handleAddIssueClicked() {
    setAddMenuAnchor(null);
    setIsIssueModalVisible(true);
  }

  function handleAddTaskClicked() {
    setAddMenuAnchor(null);
    setIsTaskModalVisible(true);
  }

  function handleCreateIssueSettled() {
    setIsIssueModalVisible(false);
    props.onItemAdded?.();
  }

  function handleCreateTaskSettled() {
    setIsTaskModalVisible(false);
    props.onItemAdded?.();
  }

  return (
    <>
      <Button
        variant={props.variant ?? "tertiary"}
        startIcon={props.startIcon ?? <AddCircle />}
        endIcon={<ExpandMoreRounded />}
        sx={{ alignSelf: "start", whiteSpace: "nowrap" }}
        onClick={(e) => setAddMenuAnchor(e.currentTarget)}
      >
        {props.text ?? "Add"}
      </Button>

      <Menu
        anchorEl={addMenuAnchor}
        open={Boolean(addMenuAnchor)}
        onClose={() => setAddMenuAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={handleAddTaskClicked}>
          <ListItemIcon>
            <AddTask />
          </ListItemIcon>
          Add Task
        </MenuItem>
        <MenuItem onClick={handleAddIssueClicked}>
          <ListItemIcon>
            <FlagOutlined />
          </ListItemIcon>
          Add Issue
        </MenuItem>
      </Menu>

      <CreateIssueModal
        visible={isIssueModalVisible}
        context={props.context}
        createdFromSurveyResponseAnswer={props.createdFromSurveyResponseAnswer}
        onCreated={handleCreateIssueSettled}
        onCancelled={handleCreateIssueSettled}
      />

      <CreateTaskModal
        visible={isTaskModalVisible}
        context={props.context}
        createdFromSurveyResponseAnswer={props.createdFromSurveyResponseAnswer}
        onCreated={handleCreateTaskSettled}
        onCancelled={handleCreateTaskSettled}
      />
    </>
  );
};

export { AddIssueOrTaskButton };
