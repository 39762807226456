import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { OneOnOneMeetingsList } from "./components";

const UserMeetingsPage = () => {
  return (
    <Stack spacing={{ xs: 0.75, md: 1 }}>
      <Card>
        <CardContent>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="h5">Meetings</Typography>
          </Stack>
        </CardContent>
      </Card>

      <Grid container sx={{ justifyContent: "center" }}>
        <Grid item sm={12} md={8}>
          <OneOnOneMeetingsList />
        </Grid>
      </Grid>
    </Stack>
  );
};

export { UserMeetingsPage };
