import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { UserMultiPicker } from "../../../components";
import { GetWikiPageDto, useEditWikiPage, UserDto } from "../../../http";

interface IEditWikiFormState {
  editors: UserDto[];
}

interface IProps {
  open: boolean;
  page: GetWikiPageDto;
  onSaved: () => void;
  onCancelled: () => void;
}

const WikiPageSettingsModal = (props: IProps) => {
  const { mutate: editWikiPage, isLoading: isSaving } = useEditWikiPage();
  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = useForm<IEditWikiFormState>({
    defaultValues: {
      editors: props.page.editors,
    },
  });

  function handleSaveClicked() {
    handleSubmit((formState) => {
      editWikiPage(
        {
          slug: props.page.slug,
          content: props.page.content,
          editors: formState.editors,
          title: props.page.title,
        },
        {
          onSuccess: () => {
            reset(undefined, { keepDefaultValues: true });
            props.onSaved();
          },
        }
      );
    })();
  }

  function handleCancelCLicked() {
    reset(undefined, { keepDefaultValues: true });
    props.onCancelled?.();
  }

  return (
    <Dialog open={props.open} onClose={handleCancelCLicked}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Typography variant="label">Editors *</Typography>
        <Controller
          name="editors"
          control={control}
          rules={{
            required: {
              message: "An editor is required",
              value: true,
            },
          }}
          render={({ field }) => (
            <UserMultiPicker
              {...field}
              error={errors["editors"] !== undefined}
              helperText={errors["editors"]?.message}
              placeholder="Select a person..."
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={isSaving} onClick={handleCancelCLicked}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isSaving}
          endIcon={isSaving ? <CircularProgress size={20} sx={{ color: "grey.400" }} /> : null}
          onClick={handleSaveClicked}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { WikiPageSettingsModal };
