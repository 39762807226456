import { FolderCopyOutlined, IosShareRounded } from "@mui/icons-material";
import { Button, Skeleton, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";
import { Plan, Typography } from "../../../components";
import { useTeamPageLayoutContext } from "../TeamPageLayout";
import { TeamStrategicMapPlaceholder } from "./components";

const TeamStrategicMapPage = () => {
  const { team, isLoadingTeam } = useTeamPageLayoutContext();
  const navigate = useNavigate();

  const hasEditPermission = (team?.isAdministrator || team?.isMember) ?? false;

  if (isLoadingTeam) {
    return null;
  }

  if (team && team.planGuid !== null) {
    return (
      <Plan
        planGuid={team.planGuid}
        headerContent={({
          isLoading,
          isPastView,
          handleVersionsClicked,
          handleExportClicked,
          plan,
          dateTimeLocal,
          dateTimeLocalUtcOffsetInMinutes,
        }) => {
          return isLoading || !plan ? (
            <Stack spacing={0.25} width={"100%"}>
              <Skeleton variant="rectangular" height={25} width={"25%"} />
              <Skeleton variant="rectangular" height={25} width={"100%"} />
            </Stack>
          ) : (
            <Stack spacing={0.25}>
              <Typography variant="h5">Strategic Map</Typography>
              {!isPastView
                ? plan && (
                    <Typography
                      variant="body1"
                      title={`Last changed by ${plan.lastModifiedByDisplayName} on ${DateTime.fromISO(
                        plan.lastModifiedUtc
                      )
                        .toLocal()
                        .toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}`}
                    >
                      {`Last changed by ${plan.lastModifiedByDisplayName} on ${DateTime.fromISO(plan.lastModifiedUtc)
                        .toLocal()
                        .toLocaleString(DateTime.DATE_FULL)}`}
                    </Typography>
                  )
                : dateTimeLocal && (
                    <Typography>{`Version on ${DateTime.fromISO(dateTimeLocal).toLocaleString(
                      DateTime.DATE_FULL
                    )}`}</Typography>
                  )}
              <Stack direction="row" spacing={0.5} sx={{ pt: 0.5 }}>
                <Button variant="tertiary" startIcon={<FolderCopyOutlined />} onClick={handleVersionsClicked}>
                  Versions
                </Button>

                {!isPastView ? (
                  <Button
                    variant="tertiary"
                    startIcon={<IosShareRounded />}
                    onClick={() => handleExportClicked(`${team.slug}-strategic-map-${DateTime.now().toISODate()}`)}
                  >
                    Export
                  </Button>
                ) : (
                  <Button variant="contained" size="small" onClick={() => navigate(".")}>
                    Back to Present
                  </Button>
                )}
              </Stack>
            </Stack>
          );
        }}
        canEdit={hasEditPermission}
      />
    );
  } else {
    return <TeamStrategicMapPlaceholder />;
  }
};

export { TeamStrategicMapPage };
